import parameters from '../parameters';
import { auth } from "../firebase";

export const checkRegistrationCode = async(isTaxi, airportCode, registrationCode) => {
    return await parameters.post("/checkRegistrationCode", {
        isTaxi: isTaxi,
        airportCode: airportCode,
        registrationCode: registrationCode
    })
}

export const checkPhoneNumber = async(phoneNumber) => {
    return await parameters.get("/checkPhoneNumber", { params: {
        phoneNumber: phoneNumber
    }});
}

export const signup = async(fields) => {
    return await parameters.post("/signup", fields)
}

export const signin = async() => {
    return await parameters.get("/signin", { params: {
        uid: auth.currentUser?.uid
    }});
}