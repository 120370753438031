import parameters from "../parameters";
import store from 'store/store';

export const saveGateChangeSuggestion = async(gateSelected, gateSuggested, gateChangeReason, url, flightNumber) => {
    return await parameters.post("/saveGateChangeSuggestion", {
        firstAndLastName: store.getState().userReducer.user.firstAndLastName,
        airportCode: store.getState().userReducer.user.airportCode,
        gateSelected: gateSelected,
        gateSuggested: gateSuggested,
        gateChangeReason: gateChangeReason,
        url: url,
        flightNumber: flightNumber
    })
}

export const getGateChangeSuggestions = async() => {
    return await parameters.get("/getGateChangeSuggestions", { params: {
        airportCode: store.getState().userReducer.user.airportCode
    }});
}