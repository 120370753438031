import { makeStyles } from '@material-ui/core/styles';
import Grid from '@mui/material/Grid';
import ShuttleTrackingTable from 'components/ShuttleTrackingTable/ShuttleTrackingTable';
import ShuttleTrackingBarGraph from 'components/ShuttleTrackingBarGraph/ShuttleTrackingBarGraph';
import React, { useEffect } from 'react';
import ReactGA from 'react-ga';

const useStyles = makeStyles({
  paper:{
    margin: 15
  },
  gridContainer:{
    marginTop: 20
  }
});

 const ShuttleTrackingTab = () => {
  const classes = useStyles();

  useEffect(() => {
    ReactGA.pageview('/rfid-shuttle');
  }, [])

  return (
    <div className={classes.paper}>
      <Grid container spacing = {2} className={classes.container}>
        <Grid item xs = {12}>
          <ShuttleTrackingTable/>
        </Grid>
        <Grid item xs = {12}>
          <ShuttleTrackingBarGraph/>
        </Grid>
      </Grid>
    </div>
  );
}

export default ShuttleTrackingTab;