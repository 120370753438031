import { makeStyles } from '@material-ui/core/styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import Paper from '@mui/material/Paper';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import { connect } from 'react-redux';
import {
    toggleAdjacentGateConflicts, 
    toggleGateChangeSuggestionsOnly, 
    toggleGateTimeConflicts, 
    toggleOverlappingGateConflicts, 
    toggleIsMisassignedGateNames,
    toggleOnlyArrivals,
    toggleOnlyDepartures,
} from 'store/gateManagement/actions';
import React from 'react'

const useStyles = makeStyles({
    paper:{
        marginBottom: 10,
        padding: 10,
        display: "flex",
        overflow: "auto"
    },
    statusContainer:{
        display: "flex",
        alignItems: "center",
        marginRight: 10
    }
});

const GateManagementSwitches = (props) => {
    const classes = useStyles();
    const { 
        toggleAdjacentGateConflicts, 
        toggleOverlappingGateConflicts,
        toggleGateTimeConflicts,
        toggleIsMisassignedGateNames,
        toggleGateChangeSuggestionsOnly,
        adjacentGateConflicts,
        overlappingGateConflicts,
        gateTimeConflicts,
        isMisassignedGateNames,
        isGateChangeSuggestionsOnly,
        toggleOnlyArrivals,
        toggleOnlyDepartures,
        isOnlyArrivals,
        isOnlyDepartures
    } = props;

    return (
        <Paper className={classes.paper}>
            <FormControlLabel control={<Switch checked = {isOnlyArrivals} onChange={toggleOnlyArrivals}/>}
                label={<Typography variant="body2">Arrivals</Typography>}
            />
            <FormControlLabel control={<Switch checked = {isOnlyDepartures} onChange={toggleOnlyDepartures}/>}
                label={<Typography variant="body2">Departures</Typography>}
            />
            <FormControlLabel control={<Switch checked = {isMisassignedGateNames} onChange={toggleIsMisassignedGateNames}/>}
                label={<Typography variant="body2">Misassigned Gates</Typography>}
            />
            <FormControlLabel control={<Switch checked = {isGateChangeSuggestionsOnly} onChange={toggleGateChangeSuggestionsOnly}/>}
                label={<Typography variant="body2">Flights within the same hour</Typography>}
            />
            {/* <FormControlLabel control={<Switch checked = {adjacentGateConflicts} onChange={toggleAdjacentGateConflicts}/>}
                label={<Typography variant="body2">Adjacent gate conflicts</Typography>}
            />
            <FormControlLabel control={<Switch checked = {overlappingGateConflicts} onChange={toggleOverlappingGateConflicts}/>}
                label={<Typography variant="body2">Overlapping gate conflicts</Typography>}
            />
            <FormControlLabel control={<Switch checked = {gateTimeConflicts} onChange={toggleGateTimeConflicts}/>}
                label={<Typography variant="body2">Gate time conflicts</Typography>}
            />
            <FormControlLabel control={<Switch checked = {isMisassignedGateNames} onChange={toggleIsMisassignedGateNames}/>}
                label={<Typography variant="body2">Wrong gate mentions</Typography>}
            /> */}
        </Paper>
    );
}

const mapDispatchToProps = {
    toggleAdjacentGateConflicts, 
    toggleOverlappingGateConflicts,
    toggleGateTimeConflicts,
    toggleIsMisassignedGateNames,
    toggleGateChangeSuggestionsOnly,
    toggleOnlyArrivals,
    toggleOnlyDepartures,
}
  
const mapStateToProps = (state) =>{
    return{
        adjacentGateConflicts: state.gateManagementReducer.adjacentGateConflicts,
        overlappingGateConflicts: state.gateManagementReducer.overlappingGateConflicts,
        gateTimeConflicts: state.gateManagementReducer.gateTimeConflicts,
        isMisassignedGateNames: state.gateManagementReducer.isMisassignedGateNames,
        isGateChangeSuggestionsOnly: state.gateManagementReducer.isGateChangeSuggestionsOnly,
        isOnlyArrivals: state.gateManagementReducer.isOnlyArrivals,
        isOnlyDepartures: state.gateManagementReducer.isOnlyDepartures,
    }
}
  
export default connect(mapStateToProps, mapDispatchToProps)(GateManagementSwitches)