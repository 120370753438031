import TextField from '@material-ui/core/TextField';
import debounce from "lodash/debounce";
import React from 'react';
import ReactGA from 'react-ga';
import { connect } from 'react-redux';
import { updateFlightNumber } from 'store/filterFieldsBar/actions';

const inputProps = {
  style: {
    padding: 10
  }
};

const FlightNumberSelector = (props) => {
  const { updateFlightNumber, flightNumber, disableDelay } = props;

  const handleChange = event => {
    const { value } = event.target;
    if(value.length < 1){
      updateFlightNumber(null);
      return
    }
    updateFlightNumber(value);
    ReactGA.event({
        category: 'Flight number Selection',
        action: `Flight number: ${value}`
    });
  };

  const delayedChange = debounce((event) => handleChange(event), 200)

  return (
    <TextField
      id="standard-number"
      label="Search flight number"
      type="number"
      onInput = {(e) =>{
        e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,4)
      }}
      defaultValue = {flightNumber ? flightNumber : ""}
      inputProps={inputProps}
      onChange={(e) => disableDelay ? handleChange(e) : delayedChange(e)}
      InputLabelProps={{style: {fontSize: 15}}}
    />
  );
}

const mapStateToProps = (state) =>{
  return{
    airline: state.filterFieldsBarReducer.airline,
    flightNumber: state.filterFieldsBarReducer.flightNumber
  }
}

const mapDispatchToProps = {
  updateFlightNumber,
}

export default connect(mapStateToProps, mapDispatchToProps)(FlightNumberSelector)
