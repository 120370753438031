import IconButton from "@material-ui/core/IconButton";
import Button from '@mui/material/Button';
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import CircleIcon from '@mui/icons-material/Circle';
import Fade from '@mui/material/Fade';
import Snackbar from '@mui/material/Snackbar';
import React, { useState, useMemo } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import Slider from '@mui/material/Slider';
import { connect } from 'react-redux';
import { saveGateChangeSuggestion } from 'api/endpoints/gateManagement';

const useStyles = makeStyles({
    legendContainer:{
        display: "flex",
        alignItems: "center",
        marginRight: 10
    },
    legendText:{
        margin: 0,
        marginLeft: 3
    },
    submissionContainer:{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    }
});

const GateChangeSuggestionSnackBar = (props) => {
    const { 
        isGateSuggestionSnackBarOpen,
        gateChangeSuggestion, 
        handleCloseOfGateSuggestionSnackBar,
        airport
    } = props;
    const classes = useStyles();
    const [gateIndex, setGateIndex] = useState(0)
    const [isSliderChanged, setIsSliderChanged] = useState(false)
    const [gateChangeReason, setGateChangeReason] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const airportGates = useMemo(() => {
        if(gateChangeSuggestion.originalGate === "No Gate Assigned"){
            let preferredAirlineGates = airport.gates.filter((gate) => gate?.prefentialAirline === gateChangeSuggestion?.prefentialAirline);
            let otherGates = airport.gates.filter((gate) => gate?.prefentialAirline !== gateChangeSuggestion?.prefentialAirline);
            return preferredAirlineGates.concat(otherGates)
        }
        let gateInfo = airport.gates.filter((gate) => gate.name === gateChangeSuggestion.originalGate)[0];
        if(gateInfo?.airlinePreferenceGates && gateInfo?.closestGatesWithoutAirlinePreference){
            return gateInfo?.airlinePreferenceGates.concat(gateInfo?.closestGatesWithoutAirlinePreference)
        }
        if(gateInfo?.closestGatesWithoutAirlinePreference){
            return gateInfo?.closestGatesWithoutAirlinePreference
        }
    }, [airport, gateChangeSuggestion])

    const handleSliderChange = (event, newValue) => {
        setIsSliderChanged(true)
        setGateIndex(newValue);
    };

    const saveGateChange = async() => {
        setIsLoading(true)
        try{
            await saveGateChangeSuggestion(
                gateChangeSuggestion.originalGate, 
                !isSliderChanged ? gateChangeSuggestion.name : airportGates[gateIndex].name, 
                gateChangeReason.length ? gateChangeReason : "",
                !isSliderChanged ? gateChangeSuggestion.url : airportGates[gateIndex].url,
                gateChangeSuggestion.flightNumber
            )
            setIsLoading(false)
            handleCloseOfGateSuggestionSnackBar()
        }catch(e){
            setIsLoading(false)
            handleCloseOfGateSuggestionSnackBar()
            console.log(e);
        }
    }

    return (
        <Snackbar
            open={isGateSuggestionSnackBarOpen}
            anchorOrigin = {{horizontal: "right", vertical: "bottom"}}
            onClose={handleCloseOfGateSuggestionSnackBar}
            TransitionComponent={Fade}
            message={
                <span className={classes.message}>
                    <div className={classes.legendContainer}>
                        <CircleIcon fontSize='medium' style={{ color: "#bdc5de" }}/>
                        <p className={classes.legendText}>Gate Selected: {gateChangeSuggestion.originalGate}, {gateChangeSuggestion.flightNumber}</p>
                    </div>
                    <div className={classes.legendContainer}>
                        <CircleIcon fontSize='medium' style={{ color: "green" }}/>
                        <p className={classes.legendText}>Gate Change Suggestion: {!isSliderChanged ? gateChangeSuggestion.name : airportGates[gateIndex].name}</p>
                    </div>
                    <img alt = "gate-allocation" style = {{marginTop: 10}} src = {!isSliderChanged ? gateChangeSuggestion.url : airportGates[gateIndex].url} width = "500px"/>
                    <div style = {{padding: 10}}>
                        <Slider
                            onChange={handleSliderChange}
                            max={airportGates && (airportGates.length - 1)}
                        />
                    </div>
                    <div className={classes.submissionContainer}>
                        <TextField
                            sx={{textAlign: 'left', background:"white", color: "black", width: "70%"}}
                            placeholder="Gate change reason..."
                            multiline
                            rows={2}
                            inputProps={{style: {fontSize: "0.875rem"}}}
                            InputLabelProps={{style: {fontSize: "0.875rem"}}}
                            onChange={(val) => setGateChangeReason(val.target.value)}
                        />
                        {!isLoading  ? <Button onClick={saveGateChange} variant="contained" color="success">Submit</Button> : <CircularProgress/>}
                    </div>
                </span>
                }
            key={Fade.name}
            action={[
                <IconButton
                    key="close"
                    aria-label="Close"
                    color="inherit"
                    onClick={handleCloseOfGateSuggestionSnackBar}
                >
                    <CloseIcon className={classes.icon} />
                </IconButton>
            ]}
            sx={{
                '& .MuiSnackbarContent-action': { 
                    display: "flex",
                    alignSelf: "flex-start",
                    position: "absolute",
                    right: 10
                }
            }}
        />
    );
}

const mapStateToProps = (state) => {
    return{
      airport: state.airportReducer.airport
    }
}

export default connect(mapStateToProps)(GateChangeSuggestionSnackBar)