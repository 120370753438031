import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Avatar,
    Card, Typography
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import { purple, darkPurple, lightPurple } from 'styles/colors';

const useStyles = makeStyles(theme => ({
    card:{
        padding: 5,
        color: '#fff',
        overflow: 'hidden',
        position: 'relative',
        '&:after': {
            content: '""',
            position: 'absolute',
            width: 210,
            height: 210,
            background: darkPurple,
            borderRadius: '50%',
            top: -85,
            right: -95,
            [theme.breakpoints.down('sm')]: {
                top: -105,
                right: -140
            }
        },
        '&:before': {
            content: '""',
            position: 'absolute',
            width: 210,
            height: 210,
            background: lightPurple,
            borderRadius: '50%',
            top: -125,
            right: -15,
            opacity: 0.5,
            [theme.breakpoints.down('sm')]: {
                top: -155,
                right: -70
            }
        },
        display: "flex",
        justifyContent: "space-between"
    },
    avatarContainer:{
        display: "flex",
        justifyContent: "flex-end"
    },
    textContainer:{
        padding: 10
    }
}));

const TotalLandingRevenueCard = () => {
    const theme = useTheme();
    const classes = useStyles();

    return (
        <Card className={classes.card} style = {{backgroundColor: purple}}>
            <div className={classes.textContainer}>
                <Typography sx={{ color: "white", fontSize: '2.125rem', fontWeight: 500, mr: 1, mt: 1.75, mb: 0.75 }}>
                    $28M
                </Typography>
                <Typography
                    sx={{
                        fontSize: '1rem',
                        fontWeight: 500,
                        color: "white"
                    }}
                >
                    Total Landing Revenue
                </Typography>
            </div>
            <Avatar
                variant="rounded"
                sx={{
                    ...theme.typography.commonAvatar,
                    ...theme.typography.largeAvatar,
                    backgroundColor: lightPurple,
                    mt: 1,
                    position: "relative",
                    zIndex: 1,
                    margin: "5px"
                }}
            >
                <PointOfSaleIcon/>
            </Avatar>
        </Card>
    );
};

export default TotalLandingRevenueCard;