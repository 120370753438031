import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import axios from "axios";
import parameters from 'api/parameters';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { updateFlightDisruption } from 'store/flightDisruption/actions';
import { convertToLocal } from 'utils/timeConversions';
import { green } from '@material-ui/core/colors';
import clsx from  'clsx';

const useStyles = makeStyles({
  loadingContainer:{
    justifyContent: "center",
    display: "flex",
    padding: 10,
    alignSelf: "center",
    margin: "0 auto"
  },
  paper:{
    padding: 20
  },
  gridContainer:{
    padding: 20,
    overflow: "auto",
    height: window.innerHeight > 800 ? 700 : 500,
    display: "flex",
    alignSelf: "flex-start",
    background: "white"
  },
  inlineElements:{
    display: "inline-block"
  },
  messageContainer:{
    height: 300,
    overflow: "auto",
    marginBottom: 2
  },
  columnTitle:{
    textAlign: "center",
    padding: 5
  },
  columnTitlesContainer:{
    borderBottom: "1px solid #9c9c9c94"
  },
  noMessageResults:{
    fontSize: 12,
    padding: 10
  },
  noResultsContainer:{
    display: "flex",
    alignItem: "center",
    flexDirection: "column"
  },
  keyword:{
    display: "inline",
    fontSize: 12
  },
  flightNumberAndPhraseMatchesContainer:{
    padding: 5,
    marginBottom: 15,
    height: "fit-content"
  },
  keywordContainer: {
    paddingTop: 10,
    paddingBottom: 10
  },
  flightNumberBtns:{
    margin: 2
  },
  greenButton: {
    color: green[700],
    borderColor: green[700],
    margin: 2
  },
  disabledButton:{
    cursor: "inherit",
    '&:hover': {
      backgroundColor: 'transparent',
      borderTop: 0,
      borderLeft: 0,
      borderRight: 0,
      borderRadius: 0
    },
    borderTop: 0,
    borderLeft: 0,
    borderRight: 0,
    borderRadius: 0
  }
});

export const FlightOperationPhrases = (props) => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [flightNumberAndPhraseMatches, setFlightNumberAndPhraseMatches] = useState([])
  const [endOfFlightDetails, setEndOfFlightDetails] = useState(null)
  const [lastFlightDetailsTimestamp, setLastFlightDetailsTimestamp] = useState(null)
  const containerRef = useRef()
  const flightDisruptionRef = useRef()
  const axiosSource = axios.CancelToken.source();
  const { 
    aircraftName,
    isBoeing, 
    updateFlightDisruption, 
    airline, 
    flightNumber, 
    airport, 
    flightDate,
    showDisruptionPhrases, 
    showGroundOperations,
    showAirportLocations,
    showWeatherTerms,
    showCargoCarriers,
    setWeatherTimestamp,
    flightRowAirline,
  } = props;

  let aircraft = null;
  if (aircraftName && isBoeing){
    aircraft = "Boeing"
  }else if(aircraftName && !isBoeing){
    aircraft = "Airbus"
  }

  const listenScrollEvent = (() => {
    if(!flightRowAirline && !isLoading && (containerRef?.current.scrollHeight - containerRef?.current.scrollTop - 50) < containerRef?.current.offsetHeight && !endOfFlightDetails){
      setIsLoading(true)
      getOperationsByFlightNumber(lastFlightDetailsTimestamp, "append")
    }
  })

  const getOperationsByFlightNumber = async(timestamp, arrayPos) => {
    let airlineSelected;
    if (aircraft){
      airlineSelected = aircraft
    }else if (flightRowAirline){
      airlineSelected = flightRowAirline
    }else{
      airlineSelected = airline;
    }
    try {
      let operations = await parameters.post("/getOperationsByFlightNumber", { 
        timestamp: timestamp,
        airport: airport.code,
        airline: airlineSelected,
        flightNumber: flightNumber,
        showDisruptionPhrases: showDisruptionPhrases,
        showGroundOperations: showGroundOperations,
        showAirportLocations: showAirportLocations,
        showWeatherTerms: showWeatherTerms,
        showCargoCarriers: showCargoCarriers
      }, {cancelToken: axiosSource.token});

      const results = flightRowAirline ? operations.data.data.slice(0,4) : operations.data.data;
      if(results.length < 1 || operations.data.lastTranscriptTimestamp === lastFlightDetailsTimestamp){
        setEndOfFlightDetails(true)
        setIsLoading(false)
        return
      }
      if(arrayPos === "append"){
        setFlightNumberAndPhraseMatches(flightNumberAndPhraseMatches.concat(results));
      }else if (arrayPos === "reset"){
        setFlightNumberAndPhraseMatches(results)
      }
      setLastFlightDetailsTimestamp(operations.data.lastTranscriptTimestamp - 1)
      setIsLoading(false)
    } catch (e) {
      console.log("error", e)
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if((flightNumberAndPhraseMatches?.length * flightDisruptionRef?.current?.offsetHeight < containerRef?.current?.offsetHeight) && !endOfFlightDetails && !flightRowAirline){
      setIsLoading(true)
      getOperationsByFlightNumber(lastFlightDetailsTimestamp - 1, "append")
    }
  }, [lastFlightDetailsTimestamp]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setIsLoading(true)
    setEndOfFlightDetails(false)
    setFlightNumberAndPhraseMatches([])
    getOperationsByFlightNumber(flightDate.getTime()/1000, "reset")
  }, [isBoeing, airline, flightNumber, airport, flightDate]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    return () => {
      axiosSource.cancel()
    };
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Grid 
      ref = {containerRef} 
      container 
      className={isLoading ? classes.gridContainer : clsx(classes.gridContainer, classes.inlineElements)}
      onScroll={listenScrollEvent} 
      variant="outlined" 
      elevation={10}
    >
      {flightNumberAndPhraseMatches.length > 0 ? 
        <>
          {flightNumberAndPhraseMatches.map((item, i) => {
            return(
              <Grid ref = {flightDisruptionRef} component={Paper} elevation = {2} className={classes.flightNumberAndPhraseMatchesContainer} item xs={12} key={i}>
                <Button
                  variant="outlined"
                  color="primary"
                  className={clsx(classes.flightNumberBtns, classes.disabledButton)}
                >
                  {item.flightNumber}
                </Button>
                {item?.flightDisruptionPhrases?.map((disruption, i) => {
                  disruption["flightNumber"] = item.flightNumber;
                  return(
                    <Button
                      key={i}
                      variant="outlined"
                      color="secondary"
                      className={classes.flightNumberBtns}
                      onClick = {() => updateFlightDisruption(disruption)}
                    >
                      {disruption.phrase}
                    </Button>
                  )
                })}
                {item?.groundOperations?.map((operation, i) => {
                  return(
                    <Button
                      key={i}
                      variant="outlined"
                      className={clsx(classes.flightNumberBtns, classes.disabledButton)}
                    >
                      {operation}
                    </Button>
                  )
                })}
                {item?.airportLocations?.map((location, i) => {
                  return(
                    <Button
                      key={i}
                      variant="outlined"
                      className={clsx(classes.greenButton, classes.disabledButton)}
                    >
                      {location}
                    </Button>
                  )
                })}
                {item?.weatherTerms?.map((term, i) => {
                  return(
                    <Button
                      key={i}
                      variant="outlined"
                      className={classes.greenButton}
                      onClick = {() => {
                        if(showWeatherTerms){
                          setWeatherTimestamp(item?.operations[0].timestamp)
                        }
                      }}
                    >
                      {term}
                    </Button>
                  )
                })}
                <br></br>
                <div style = {{overflow: flightRowAirline ? "hidden" : ""}} className={classes.keywordContainer}>
                  {item.operations.map((operationItem, j) => {
                    return(
                      <Tooltip
                        key={j} 
                        title={
                          <>
                            {operationItem?.definition ? 
                            <>
                              {`Definition: ${operationItem?.definition}`}
                              <br/>
                            </>
                            : null}
                            {convertToLocal(operationItem.timestamp)}
                          </>
                        }
                      >
                        <p className = {classes.keyword}>{operationItem.text} </p>
                      </Tooltip>
                    )
                  })}
                </div>
              </Grid>
            )
          })}
        </>
      : !isLoading && flightNumberAndPhraseMatches.length < 1 ?
        <div className = {classes.noResultsContainer}>
          <p className = {classes.noMessageResults}>No results are shown for this selection.  Please select another date, airline, flight number or airport.</p>
        </div> 
      : null }
      {isLoading ? <div className={classes.loadingContainer}> <CircularProgress/> </div> : null}
    </Grid>
  );
}

const mapStateToProps = (state) => {
  return{
    airport: state.airportReducer.airport,
    flightDate: state.filterFieldsBarReducer.flightDate,
    airline: state.filterFieldsBarReducer.airline,
    flightNumber: state.filterFieldsBarReducer.flightNumber,
  }
}

const mapDispatchToProps = {
  updateFlightDisruption
}

export default connect(mapStateToProps, mapDispatchToProps)(FlightOperationPhrases)