import { makeStyles } from '@material-ui/core/styles';
import FilterFieldsBar from 'components/FilterFieldsBar/FilterFieldsBar';
import FlightOperationPhrases from "components/FlightOperationPhrases/FlightOperationPhrases";
import React, { useEffect } from 'react';
import ReactGA from 'react-ga';

const useStyles = makeStyles({
  paper:{
    margin: 15
  },
  gridItem:{
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 20
  },
  gridItems:{
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 20,
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "center"
  },
  searchContainer:{
    display: "flex",
    flexDirection: "row"
  },
  flightOpsContainer:{
    paddingTop: 5
  },
});

const AllTranscriptsTab = () => {
  const classes = useStyles();

  useEffect(() => {
    ReactGA.pageview('/all-transcripts');
  }, [])

  return (
    <div className={classes.paper}>
      <FilterFieldsBar/>
      <div className={classes.flightOpsContainer}>
        <FlightOperationPhrases/>
      </div>
    </div>
  );
}

export default AllTranscriptsTab;