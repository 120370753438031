import { makeStyles } from '@material-ui/core/styles';
import Grid from '@mui/material/Grid';
import ChartContainer from 'components/ChartContainer/ChartContainer';
import FilterFieldsBar from 'components/FilterFieldsBar/FilterFieldsBar';
import FlightDisruptionDescCard from 'components/FlightDisruptionDescCard/FlightDisruptionDescCard';
import FlightOperationPhrases from 'components/FlightOperationPhrases/FlightOperationPhrases';
import React, { useEffect } from 'react';
import ReactGA from 'react-ga';

const useStyles = makeStyles({
  paper:{
    margin: 15
  },
  gridItems:{
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 20,
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "center"
  },
  flightDisruptionPhrases:{
    display: "flex",
    alignItems: "flex-end"
  },
  searchContainer:{
    display: "flex",
    flexDirection: "row"
  },
  cardContainer:{
    justifyContent: "center",
    display: "flex",
    alignSelf: "flex-start"
  },
  container: {
    justifyContent: "center"
  }
});

const FlightDisruptionsTab = () => {
  const classes = useStyles();

  useEffect(() => {
    ReactGA.pageview('/flight-disruptions');
  }, [])

  return (
    <div className={classes.paper}>
      <FilterFieldsBar/>
      <Grid container className={classes.container} spacing={1}>
        <Grid className={classes.flightDisruptionPhrases} item xs = {12} sm = {7} md = {8} order = {{ xs: 2, sm: 1, md: 1 }}>
          <FlightOperationPhrases showDisruptionPhrases />
        </Grid>
        <Grid className={classes.cardContainer} item xs = {12} sm = {5} md = {4} order = {{ xs: 1, sm: 2, md: 2 }}>
          <FlightDisruptionDescCard/>
        </Grid>
      </Grid>
      <ChartContainer/>
    </div>
  );
}

export default FlightDisruptionsTab;