import { makeStyles } from '@material-ui/core/styles';
import Grid from '@mui/material/Grid';
import ADSBModeSTable from 'components/ADSBModeSTable/ADSBModeSTable';
import FilterFieldsBar from 'components/FilterFieldsBar/FilterFieldsBar';
import React, { useEffect } from 'react';
import ReactGA from 'react-ga';

const useStyles = makeStyles({
  paper:{
    margin: 15
  },
  container: {
    justifyContent: "center",
    background: "white"
  }
});

 const ADSBModeSTab = () => {
  const classes = useStyles();

  useEffect(() => {
    ReactGA.pageview('/ads-b-mode-s');
  }, [])

  return (
    <div className={classes.paper}>
      <FilterFieldsBar/>
      <Grid container className={classes.container}>
        <ADSBModeSTable/>
      </Grid>
    </div>
  );
}

export default ADSBModeSTab;