import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import axios from "axios";
import parameters from 'api/parameters';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { convertToLocal } from 'utils/timeConversions';

const useStyles = makeStyles({
  loadingContainer:{
    justifyContent: "center",
    display: "flex",
    padding: 10,
    alignSelf: "center",
    margin: "0 auto"
  },
  container:{
    overflow: "auto",
    height: window.innerHeight > 800 ? 700 : 500,
    display: "flex",
    alignSelf: "center",
    width: "100%"
  },
  noResultsContainer:{
    display: "flex",
    alignItem: "center",
    flexDirection: "column"
  },
  noMessageResults:{
    fontSize: 12,
    padding: 10
  },
});

const FlightStatsTable = (props) => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [flightStatsRows, setFlightStatsRows] = useState([])
  const [originalFlightStats, setOriginalFlightStats] = useState([])
  const tableRef = useRef()
  const axiosSource = axios.CancelToken.source();
  const { airline, flightNumber, airport } = props;

  const getFlightStats = async() => {
    try {
      const flightStatsQuery = await parameters.post("/getFlightStats", {
        timestamp: new Date().getTime()/1000,
        airport: airport.code
      }, {cancelToken: axiosSource.token});

      let flightStats = flightStatsQuery.data.data;
      setOriginalFlightStats(flightStats)
      if(airline){
        flightStats = flightStats.data.data.filter((stat) => stat.airline === airline);
      }
      if(flightNumber){
        flightStats = flightStats.data.data.filter((stat) => stat.flightNumber.includes(flightNumber));
      }
      
      setFlightStatsRows(flightStats)
      setIsLoading(false)
    } catch (error) {
      console.log("error", error)
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if(flightNumber && airline){
      setFlightStatsRows(originalFlightStats.filter((stat) => stat.airline === airline && stat.flightNumber.includes(flightNumber)))
    }
    else if(airline){
      setFlightStatsRows(originalFlightStats.filter((stat) => stat.airline === airline))
    }
    else if(!airline && flightNumber){
      setFlightStatsRows(originalFlightStats.filter((stat) => stat.flightNumber.includes(flightNumber)))
    }
    else{
      setFlightStatsRows(originalFlightStats)
    }
  }, [airline]) 

  useEffect(() => {
    if(flightNumber && airline){
      setFlightStatsRows(originalFlightStats.filter((stat) => stat.airline === airline && stat.flightNumber.includes(flightNumber)))
    }
    else if(flightNumber){
      setFlightStatsRows(originalFlightStats.filter((stat) => stat.flightNumber.includes(flightNumber)))
    }
    else if(!flightNumber && airline){
      setFlightStatsRows(originalFlightStats.filter((stat) => stat.airline === airline))
    }
    else{
      setFlightStatsRows(originalFlightStats)
    }
  }, [flightNumber])

  useEffect(() => {
    getFlightStats();
    let isMounted = true;

    const refreshFlightStats = setInterval(() => {
      if(isMounted){
        getFlightStats()
      }
    }, 300000);

    return () => {
      clearInterval(refreshFlightStats);
      isMounted = false;
    }
  }, [])

  useEffect(() => {
    return () => {
      axiosSource.cancel()
    };
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={classes.container}>
      {flightStatsRows.length > 0 ? 
        <TableContainer>
          <Table style = {{"whiteSpace": "nowrap"}} stickyHeader aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell>Local Arr/Dep Time</TableCell>
                <TableCell align="right">Airline</TableCell>
                <TableCell align="right">Flight Number</TableCell>
                <TableCell align="right">Arrival/Departure</TableCell>
                <TableCell align="right">City</TableCell>
                <TableCell align="right">Gate</TableCell>
                <TableCell align="right">Status</TableCell>
                <TableCell align="right">Date Retrieved</TableCell>
              </TableRow>
            </TableHead>
            <TableBody ref = {tableRef}>
              {flightStatsRows.map((row, i) => (
                <TableRow>
                  <TableCell component="th" scope="row">
                      {row.localTime}M
                  </TableCell>
                  <TableCell align="right">{row.airline}</TableCell>
                  <TableCell align="right">{row.flightNumber}</TableCell>
                  <TableCell align="right">{row.arrDep}</TableCell>
                  <TableCell align="right">{row.city}</TableCell>
                  <TableCell align="right">{row.gate}</TableCell>
                  <TableCell align="right">{row.status}</TableCell>
                  <TableCell align="right">{convertToLocal(row.timestamp)}</TableCell>
              </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer> : isLoading ?

        <div className={classes.loadingContainer}> 
          <CircularProgress/> 
        </div> : 
        
        <div className = {classes.noResultsContainer}>
          <p className = {classes.noMessageResults}>No results are shown for this selection.  Please select another airline or flight number.</p>
        </div>
      }
    </div>
  );
}

const mapStateToProps = (state) => {
  return{
    airport: state.airportReducer.airport,
    airline: state.filterFieldsBarReducer.airline,
    flightNumber: state.filterFieldsBarReducer.flightNumber,
  }
}

export default connect(mapStateToProps)(FlightStatsTable)