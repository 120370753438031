import { Paper } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import CircleIcon from '@mui/icons-material/Circle';
import LocalTaxiIcon from '@mui/icons-material/LocalTaxi';
import Typography from '@mui/material/Typography';
import { GoogleMap, Polygon, DirectionsService, DirectionsRenderer, useJsApiLoader } from '@react-google-maps/api';
import { darkerBlue } from "styles/colors";
import React, { useEffect, useState, useRef, useMemo } from 'react';
import { Marker } from '@react-google-maps/api';
import { connect } from 'react-redux';
import { useQuery } from 'react-query';
import { signin } from 'api/endpoints/auth';
import { updateUser } from 'store/user/actions';
import { updateAccountBalance, savePaymentLog } from 'api/endpoints/payments'
import { savePosition, getTaxiLocations, enterParkingSpotZone, leaveParkingSpot, leaveParkingSpotZone, saveParkingQueueEvent } from 'api/endpoints/parkingQueue';

//Google Map Package Docs
//https://react-google-maps-api-docs.netlify.app/

const containerStyle = {
    width: '100%',
    height: '500px'
};

const isInZone = (latitude, longitude, polygon) => {
    if (typeof latitude !== 'number' || typeof longitude !== 'number') {
      console.log('Invalid latitude or longitude. Numbers are expected')
    } else if (!polygon || !Array.isArray(polygon)) {
      console.log('Invalid polygon. Array with locations expected')
    } else if (polygon.length === 0) {
      console.log('Invalid polygon. Non-empty Array expected')
    }
  
    let inside = false
    for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
      const xi = polygon[i]["lat"]; const yi = polygon[i]["lng"]
      const xj = polygon[j]["lat"]; const yj = polygon[j]["lng"]
  
      const intersect = ((yi > longitude) !== (yj > longitude)) &&
              (latitude < (xj - xi) * (longitude - yi) / (yj - yi) + xi)
      if (intersect) inside = !inside
    }
  
    return inside
};

const transparentLightBlue = "#a0d3fa9c"

const taxiParkingMarkerOptions = {
    fillColor: transparentLightBlue,
    fillOpacity: 1,
    strokeColor: darkerBlue,
    strokeOpacity: 1,
    strokeWeight: 2,
    clickable: false,
    draggable: false,
    editable: false,
    geodesic: false,
    zIndex: 1
}

const outsideTaxiZoneOptions = {
    fillColor: "#ff00005c",
    fillOpacity: 1,
    strokeColor: "red",
    strokeOpacity: 1,
    strokeWeight: 2,
    clickable: false,
    draggable: false,
    editable: false,
    geodesic: false,
    zIndex: 1
}

const stagingAreaMarkerOptions = {
    fillColor: "#d600ff5c",
    fillOpacity: 1,
    strokeColor: "purple",
    strokeOpacity: 1,
    strokeWeight: 2,
    clickable: false,
    draggable: false,
    editable: false,
    geodesic: false,
    zIndex: 1
}

const useStyles = makeStyles({
    paper: {
        marginBottom: 15,
        padding: 10,
        display: "flex"
    }
});

const geolocationOptions = {
    enableHighAccuracy: true,
    timeout: 50000,
    maximumAge: 0
};

let userHasPaidForTrip = false;
  
const ParkingQueueMap = (props) => {
    const { user, updateUser, airport } = props;
    const classes = useStyles();
    const [markerPosition, setMarkerPosition] = useState(null)
    const [selectedCompanyName, setSelectedCompanyName] = useState('')
    const [selectedTaxiNumber, setSelectedTaxiNumber] = useState('')
    const [parkingZoneEntered, setParkingZoneEntered] = useState(false)
    const [locationError, setLocationError] = useState(null)
    // const [googleDirectionResponses, setGoogleDirectionResponses] = useState([])
    // const [selectedTaxiETA, setSelectedTaxiETA] = useState("")
    const [isMapShowing, setIsMapShowing] = useState(false)
    const [mapTypeId, setMapTypeId] = useState(airport?.satelliteView ? 'satellite' : 'roadmap')

    const mapRef = useRef()

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: process.env.NODE_ENV === "development"
            ? process.env.REACT_APP_DEV_GOOGLE_API_KEY
            : process.env.REACT_APP_PROD_GOOGLE_API_KEY,
    })

    const successGeolocation = async(pos) => {
        setLocationError(null)
        const {latitude, longitude} = pos.coords;
        setMarkerPosition({
            lat: latitude,
            lng: longitude
        })
        if(user?.taxiNumber){
            await savePosition(latitude, longitude)
            const getUserInfo = await signin();
            if(isInZone(latitude, longitude, airport?.taxiParkingZone)){
                setParkingZoneEntered(true)
            }else{
                setParkingZoneEntered(false)
            }
            if(!userHasPaidForTrip && isInZone(latitude, longitude, airport?.taxiParkingZone) && !getUserInfo.data.data[0].inParkingZone && getUserInfo.data.data[0].inParkingSpot){
                userHasPaidForTrip = true
                await enterParkingSpotZone()
                if(airport?.hidePayment !== true){
                    updateUser({ ...user, accountBalance: user.accountBalance - airport?.taxiFee})
                    await updateAccountBalance("-", airport?.taxiFee)
                    await savePaymentLog(user?.airportCode, airport?.taxiFee, "subtracted")
                }
                await saveParkingQueueEvent({
                    uid: user.uid,
                    airportCode: user.airportCode,
                    firstAndLastName: user.firstAndLastName,
                    companyName: user.companyName,
                    taxiNumber: user.taxiNumber,
                    event: "Entered Parking Zone",
                })
                // wait for the sign in call to complete just incase the watchGeo method calls successGeolocation twice and the code is executing between lines 165 and 171
                // also to make sure that the server has time to update to not recall the function twice
                await signin(); 
                userHasPaidForTrip = false
            }
            if(isInZone(latitude, longitude, airport?.outsideTaxiParkingZone) && getUserInfo.data.data[0].inParkingZone && getUserInfo.data.data[0].inParkingSpot){
                await Promise.all([
                    leaveParkingSpot(user.uid),
                    leaveParkingSpotZone(),
                    saveParkingQueueEvent({
                        uid: user.uid,
                        airportCode: user.airportCode,
                        firstAndLastName: user.firstAndLastName,
                        companyName: user.companyName,
                        taxiNumber: user.taxiNumber,
                        event: "Exited From Red Zone"
                    })
                ]);
            }
        }
    }

    // eslint-disable-next-line no-unused-vars
    const { data: taxiLocations } = useQuery(
        ['taxiLocations'],
        async () => {
            const response = await getTaxiLocations()
            return response.data.data
        },
        {
          refetchInterval: 3000,
          refetchIntervalInBackground: true,
          cacheTime: 0
        },
    ) 

    const showError = (error) => {
        switch(error.code) {
            case error.PERMISSION_DENIED:
                setLocationError("Please enable your location in browser settings.")
                break;
            case error.POSITION_UNAVAILABLE:
                setLocationError("Location information is unavailable.")
                break;
            case error.TIMEOUT:
                setLocationError("The request to get user location timed out.")
                break;
            case error.UNKNOWN_ERROR:
                setLocationError("Location information is unavailable.")
                break;
            default:
                setLocationError("Location information is unavailable.")
                break;
        }
    }

    useEffect(() => {
        let watchID;
        if (navigator.geolocation) {
            watchID = navigator?.geolocation.watchPosition(successGeolocation, showError, geolocationOptions);
        }

        return (() => {
            navigator.geolocation.clearWatch(watchID);
        })
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    // let [directionsCallbackCount, setDirectionsCallbackCount] = useState(0);

    // useEffect(() => {
    //     setDirectionsCallbackCount(0)
    // }, [taxiLocations]);

    // const directionsCallback = (response, uid) => {
    //     if (response !== null && directionsCallbackCount < airport.parkingSpots + 1) {
    //         if (response.status === 'OK') {
    //             setDirectionsCallbackCount(count => count + 1)
    //             response.uid = uid;
    //             const filteredArray = googleDirectionResponses.filter((item) => {
    //                 return item.uid !== uid
    //             });
    //             filteredArray.push(response)
    //             setGoogleDirectionResponses(filteredArray)
    //         }
    //     }
    // }

    //hiding directions right now because it is too expensive
    // const setETAOfTaxiArrival = (uid) => {
    //     googleDirectionResponses.map((item) => {
    //         if(item.uid === uid){
    //             setSelectedTaxiETA(`, ETA: ${item.routes[0].legs[0].duration.text}`) 
    //         }else{
    //             setSelectedTaxiETA(", ETA: 0 mins")
    //         }
    //         return item
    //     });
    // }

    // const memoizedDirectionsService = useMemo(() => {
    //     if (!user?.taxiNumber && taxiLocations?.length) {
    //         return (
    //             <>
    //                 {taxiLocations.map((data, i) => {
    //                     if (!data.inParkingZone && !googleDirectionResponses.find(res => res.uid === data.uid)) {
    //                         return (
    //                             <DirectionsService
    //                                 key={i}
    //                                 options={{
    //                                     destination: airport?.taxiParkingZone[2],
    //                                     origin: {
    //                                         lat: Number(data.lat),
    //                                         lng: Number(data.lng),
    //                                     },
    //                                     travelMode: "DRIVING",
    //                                 }}
    //                                 callback={(e) => directionsCallback(e, data.uid)}
    //                             />
    //                         );
    //                     }
    //                 })}
    //             </>
    //         );
    //     }
    // }, [taxiLocations, googleDirectionResponses, airport?.taxiParkingZone, user?.taxiNumber]);

    return isLoaded ? (
        <>
            <Paper className={classes.paper}>
                <CircleIcon fontSize='small' style={{ color: transparentLightBlue }} />
                {!locationError ? 
                    <Typography variant="p">Taxi Parking Zone {parkingZoneEntered && (" | You are currently in the zone")}</Typography>
                    :
                    <Typography variant="p">{locationError}</Typography>
                }
                {selectedCompanyName.length ?
                    <>
                        <LocalTaxiIcon fontSize='small' style={{ color: "yellow", paddingLeft: 8 }} />
                        <Typography variant="p">{`${selectedCompanyName}: ${selectedTaxiNumber}`}</Typography>
                        {/* <Typography variant="p">{`${selectedTaxiETA}`}</Typography>  */}
                    </>
                : null}
            </Paper>
            <GoogleMap
                ref={mapRef}
                onLoad={map => {
                    if(airport?.mapHeading){
                        setTimeout(() => {
                            map.setHeading(airport?.mapHeading)
                            setIsMapShowing(true)
                        }, 1000)
                    }
                }}
                mapContainerStyle={{...containerStyle, display: isMapShowing || !airport?.mapHeading ? "block" : "none"}}
                center={markerPosition && user?.taxiNumber ? markerPosition : airport?.center}
                zoom={airport.mapZoom}
                options={
                    {
                        mapId: "7d9ca58e3e072486",
                        mapTypeId: mapTypeId
                    }
                }
                onMapTypeIdChanged={() => {
                    if(mapRef?.current?.state?.map?.mapTypeId === "roadmap"){
                        setMapTypeId("roadmap")
                    }else if(mapRef?.current?.state?.map?.mapTypeId === "satellite"){
                        setMapTypeId("satellite")
                    }else if(mapRef?.current?.state?.map?.mapTypeId === "hybrid"){
                        setMapTypeId("hybrid")
                    }else if(mapRef?.current?.state?.map?.mapTypeId === "terrain"){
                        setMapTypeId("terrain")
                    }
                }}
            >
                <Polygon
                    paths={airport?.taxiParkingZone}
                    options={taxiParkingMarkerOptions}
                />
                {(!user?.taxiNumber && 
                    <Polygon
                        paths={airport?.outsideTaxiParkingZone}
                        options={outsideTaxiZoneOptions}
                    />
                )}
                {airport?.stagingArea && 
                    <Polygon
                        paths={airport?.stagingArea}
                        options={stagingAreaMarkerOptions}
                    />
                }
                {/* 
                {(!user?.taxiNumber && taxiLocations?.length && 
                    <>
                        {taxiLocations.map((data, i) => { 
                            if(!data.inParkingZone){
                                return (
                                    <DirectionsService
                                        key = {i}
                                        options={{
                                            destination: airport?.taxiParkingZone[2],
                                            origin: {
                                                lat: Number(data.lat),
                                                lng: Number(data.lng)
                                            },
                                            travelMode: "DRIVING"
                                        }}
                                        callback={(e) => directionsCallback(e, data.uid)}
                                    />
                                )
                            }
                        })}
                    </>
                )}
                {memoizedDirectionsService} */}
                {!user?.taxiNumber && taxiLocations?.length ? taxiLocations.map((data, i) => {
                    return (
                        <Marker
                            key = {i}
                            icon="https://firebasestorage.googleapis.com/v0/b/undelay-aaeb6.appspot.com/o/icons%2Ftaxi.png?alt=media&token=ff20abbb-eac4-42f8-bac0-bea99d8c94c8"
                            position={{
                                lat: Number(data.lat),
                                lng: Number(data.lng)
                            }}
                            onClick={() => {
                                setSelectedCompanyName(data.companyName)
                                setSelectedTaxiNumber(data.taxiNumber)
                                // setETAOfTaxiArrival(data.uid)
                            }}
                        />
                    )
                }) : user?.taxiNumber && markerPosition ? <Marker position={markerPosition} /> : null}
            </GoogleMap>
        </>
    ) : null
}

const mapDispatchToProps = {
    updateUser
}

const mapStateToProps = (state) => {
    return {
        user: state.userReducer.user,
        airport: state.airportReducer.airport
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ParkingQueueMap)



// Lot C for Airport
// "taxiParkingZone": [
//     {
//         "lat": 32.84501753860735,
//         "lng": -96.8468737906685
//     },
//     {
//         "lat": 32.84380568376722,
//         "lng": -96.84838864696422
//     },
//     {
//         "lat": 32.8424873291667,
//         "lng": -96.84696431992917
//     },
//     {
//         "lat": 32.843643425796195,
//         "lng": -96.8451839111354
//     }
// ],
// "outsideTaxiParkingZone": [
//     {
//         "lat": 32.842361778748625,
//         "lng": -96.84684722307081
//     },
//     {
//         "lat": 32.84284158981143, 
//         "lng": -96.84612261115132
//     },
//     {
//         "lat": 32.84207801829739,
//         "lng": -96.84571731973868
//     },
//     {
//         "lat": 32.84072627424024, 
//         "lng": -96.8456006449381
//     }
// ],