import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuIcon from '@material-ui/icons/Menu';
import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import React, { useState } from 'react';
import { darkerBlue } from "styles/colors";
import { signOut } from "firebase/auth";
import { auth } from "api/firebase";
import { connect } from 'react-redux';
import { updateUser } from 'store/user/actions';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { createPaymentIntent } from 'api/endpoints/payments';
import PaymentModal from 'components/PaymentModal/PaymentModal';
import TaxiAccessModal from 'components/modals/TaxiAccessModal/TaxiAccessModal';

const stripePromise = loadStripe(
  process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_STRIPE_DEV_KEY
      : process.env.REACT_APP_STRIPE_PROD_KEY
);

const drawerWidth = 240;

const useStyles = makeStyles({
  breakpoints: {
    values: {
      sm: 900,
    },
  },
  profileIcon: {
    display: 'flex'
  },
  profileAndAirportSearchSection:{
    display: "flex",
    flexDirection: "row"
  },
  toolbar:{
    justifyContent: "flex-end",
    ['@media (max-width: 900px)']: { // eslint-disable-line no-useless-computed-key
      justifyContent: "space-between"
    }
  }
});

const styles = {
  appBar:{
    width: { md: `calc(100% - ${drawerWidth}px)` },
    ml: { md: `${drawerWidth}px` },
    backgroundColor: darkerBlue,
    alignItems: "stretch"
  }
};

const Header = (props) => {
  const { user, handleDrawerToggle, updateUser, airport } = props;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [clientSecret, setClientSecret] = useState(null);
  const [paymentIntentId, setPaymentIntentId] = useState(null);
  const [paymentModalOpen, setPaymentModalOpen] = useState(false);
  const [taxiAccessModalOpen, setTaxiAccessModalOpen] = useState(false);
  const isMenuOpen = Boolean(anchorEl);

  const appearance = {
    theme: 'stripe',
  };

  const options = {
    clientSecret,
    appearance
  };

  const startPayment = async() => {
    try{
      const paymentIntentResponse = await createPaymentIntent(user?.firstAndLastName)
      setPaymentIntentId(paymentIntentResponse.data.data.id)
      setClientSecret(paymentIntentResponse.data.data.clientSecret)
      setPaymentModalOpen(true)
    }catch(e){
      console.log(e)
    }
  }

  const handleProfileMenuOpen = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleOpenTaxiBlockMenu = () => {
    setTaxiAccessModalOpen(true)
    setAnchorEl(null);
  }

  const logout = async() => {
    try{
      await signOut(auth);
      updateUser({})
    }catch(e){
      console.log(e);
      alert(e?.message);
    }
  };

  return (
    <>
      {clientSecret && (
        <Elements options={options} stripe={stripePromise}>
          <PaymentModal
            paymentModalOpen = {paymentModalOpen}
            setPaymentModalOpen = {setPaymentModalOpen}
            paymentIntentId = {paymentIntentId}
          />
        </Elements>
      )}

      {taxiAccessModalOpen && (
        <TaxiAccessModal
          taxiModalOpen={taxiAccessModalOpen}
          setTaxiModalOpen={setTaxiAccessModalOpen}
        />
      )}

      <AppBar position="fixed" sx={styles.appBar}>
        <Toolbar className={classes.toolbar}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerToggle}
            edge="start"
            sx={{ mr: 2, display: { md: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <div className={classes.profileAndAirportSearchSection}>
            <div className={classes.profileIcon}>
              <IconButton
                edge="end"
                onClick={handleProfileMenuOpen}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
            </div>
          </div>
        </Toolbar>
      </AppBar>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        keepMounted
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isMenuOpen}
        onClose={handleMenuClose}
      >
        <MenuItem>Hi, {user?.firstAndLastName}</MenuItem>
        {airport?.hidePayment !== true && user?.taxiNumber && (
          <>
            <MenuItem onClick={() => { handleMenuClose(); startPayment(); }}>
              {user.accountBalance ? user.accountBalance : 0} balance
            </MenuItem>
            <MenuItem onClick={() => { handleMenuClose(); startPayment(); }}>
              {~~((user.accountBalance || 0) / (airport.taxiFee || 1))} pick ups left
            </MenuItem>
          </>
        )}
        {!user?.taxiNumber && (
          <MenuItem onClick={handleOpenTaxiBlockMenu}>
            Manage Taxi Access
          </MenuItem>
        )}
        <MenuItem onClick={logout}>Logout?</MenuItem>
      </Menu>
    </>
  );
}

const mapStateToProps = (state) => {
  return{
    user: state.userReducer.user,
    airport: state.airportReducer.airport
  }
}

const mapDispatchToProps = {
  updateUser
}

export default connect(mapStateToProps, mapDispatchToProps)(Header)