import { makeStyles } from '@material-ui/core/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { convertToLocal } from 'utils/timeConversions';
import Paper from '@mui/material/Paper';
import React from 'react';

const useStyles = makeStyles({
    topContainer:{
        display: "flex",
        justifyContent: "flex-end",
        padding: 10
    },
    tableContainer:{
        height: 600
    },
    loadingContainer:{
        justifyContent: "center",
        display: "flex",
        margin: 20
    },
    taxiLoadingContainer:{
        justifyContent: "center",
        display: "flex"
    },
});

const shuttleData = [
    {
        timestamp: 1682468372,
        tagId: "550c29d1-1170-4bbd-8102-0ad3cbc81a42",
        shuttleCompany: 'Hyatt Hotel',
        driverNames: "Sam Lewis, John Doe"
    },
    {
        timestamp: 1682468559,
        tagId: "4793904c-8722-481e-99ef-f175ffb9ad59",
        shuttleCompany: 'Mariott Hotel',
        driverNames: "Brian Shaw, Bailey Hanson"
    },
    {
        timestamp: 1682468380,
        tagId: "89306ceb-2bf7-4cbc-b9fc-8eb6293b81ea",
        shuttleCompany: 'Tulsa Airport Hotel',
        driverNames: "Bing Gene, Sane Gowl"
    },
    {
        timestamp: 1682468385,
        tagId: "3rf4rhui3nr2f-03f2-4b47-a743-760c1c7c42cd",
        shuttleCompany: 'LaQuinta',
        driverNames: "Kyle Gold, Mariam White"
    },
    {
        timestamp: 1682468395,
        tagId: "frvhuibfh34rv-3f5a-4c01-93a4-64a8fb721805",
        shuttleCompany: 'Hyatt Hotel',
        driverNames: "Bo Silver, Heart Mann"
    },
    {
        timestamp: 1682468411,
        tagId: "rfgrhubif4-1170-4bbd-8102-t8giuneir43ug",
        shuttleCompany: 'Double Tree',
        driverNames: "Jean Johnson, Kane Washington"
    },
    {
        timestamp: 1682468423,
        tagId: "34fr89uiyb-8722-481e-99ef-r45gtbjhuif4g",
        shuttleCompany: 'Courtyard By Mariott',
        driverNames: "Bean Green, Jason Toller"
    },
    {
        timestamp: 1682468445,
        tagId: "324rt8huibhjf-2bf7-4cbc-b9fc-bjnhfi4uf4r4f",
        shuttleCompany: 'Hyatt Hotel',
        driverNames: "Juice Wilder, Long Jo"
    },
    {
        timestamp: 1682468475,
        tagId: "frvjhubifh-03f2-4b47-a743-gthur4fijrunr",
        shuttleCompany: 'Mariott Hotel',
        driverNames: "Michael Shine, Y Signing"
    },
    {
        timestamp: 1682468489,
        tagId: "frgthiybh3er4f-3f5a-4c01-93a4-gtjbhyibr4fo",
        shuttleCompany: 'Hyatt Hotel',
        driverNames: "Doe Lewis, X Moore"
    }
]

const RFIDShuttleTable = () => {
    const classes = useStyles();

    return (
        <Paper>
            <TableContainer className={classes.tableContainer}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Time Detected</TableCell>
                            <TableCell align="right">Shuttle Company</TableCell>
                            <TableCell align="right">Driver Name(s)</TableCell>
                            <TableCell align="right">Tag ID(s)</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {shuttleData.map((shuttle, i) => {
                            return(
                                <TableRow key = {i}>
                                    <TableCell scope="row">{convertToLocal(shuttle.timestamp)}</TableCell>
                                    <TableCell align = "right">{shuttle.shuttleCompany}</TableCell>
                                    <TableCell align = "right">{shuttle.driverNames}</TableCell>
                                    <TableCell align = "right">{shuttle.tagId}</TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
}

export default RFIDShuttleTable