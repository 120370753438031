import { makeStyles } from '@material-ui/core/styles';
import Grid from '@mui/material/Grid';
import ChartContainer from 'components/ChartContainer/ChartContainer';
import FilterFieldsBar from 'components/FilterFieldsBar/FilterFieldsBar';
import FlightOperationPhrases from 'components/FlightOperationPhrases/FlightOperationPhrases';
import WeatherCard from 'components/WeatherCard/WeatherCard';
import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga';

const useStyles = makeStyles({
  paper:{
    margin: 15
  },
  flightDisruptionPhrases:{
    display: "flex",
    alignItems: "flex-end"
  },
  cardContainer:{
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start"
  },
  container: {
    justifyContent: "center"
  }
});

const WeatherTab = () => {
  const classes = useStyles();
  const [weatherTimestamp, setWeatherTimestamp] = useState(new Date().getTime()/1000)

  useEffect(() => {
    ReactGA.pageview('/weather');
  }, [])
  
  return (
    <div className={classes.paper}>
      <FilterFieldsBar/>
      <Grid container className={classes.container} spacing = {1}>
        <Grid className={classes.flightDisruptionPhrases} item xs = {12} sm = {12} md = {9}>
          <FlightOperationPhrases setWeatherTimestamp = {setWeatherTimestamp} showWeatherTerms />
        </Grid>
        <Grid className={classes.cardContainer} item xs = {12} sm = {12} md = {3}>
          <WeatherCard timestamp = {weatherTimestamp}/>
        </Grid>
      </Grid>
      <ChartContainer/>
    </div>
  );
}

export default WeatherTab;