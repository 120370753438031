import { makeStyles } from '@material-ui/core/styles';
import Grid from '@mui/material/Grid';
import CargoLandingRev from 'components/CargoLandingRev/CargoLandingRev';
import RealTimeChartContainer from 'components/RealTimeChartContainer/RealTimeChartContainer';
import AircraftTypes from 'components/AircraftTypes/AircraftTypes';
import CabinClasses from 'components/CabinClasses/CabinClasses';
import PassengerRevenuePerAirline from 'components/PassengerRevenuePerAirline/PassengerRevenuePerAirline';
import TotalLandingRevenueCard from 'components/TotalLandingRevenueCard/TotalLandingRevenueCard';
import TotalPassengerRevenueCard from 'components/TotalPassengerRevenueCard/TotalPassengerRevenueCard';
import RevenueSources from 'components/RevenueSources/RevenueSources';
import TimeFrameSelection from 'components/TimeFrameSelection/TimeFrameSelection';
import React, { useEffect, useState } from 'react';
import Paper from '@material-ui/core/Paper';
import ReactGA from 'react-ga';
import SelectDates from "components/SelectDates/SelectDates";

const useStyles = makeStyles({
  container:{
    margin: 15
  },
  gridContainer:{
    marginBottom: 20
  },
  dateSelectionItemContainer:{
    display: "flex",
    alignItems: "center"
  },
  dateSelectionContainer:{
    justifyContent: "flex-end"
  },
  gridItems:{
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 20,
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "center"
  },
  airport:{
    fontSize: 13
  }
});

const RevenueManagementTab = () => {
  const classes = useStyles();
  const [timeFrame, setTimeFrame] = useState("Last 24 hours")

  useEffect(() => {
    ReactGA.pageview('/revenue-management');
  }, [])

  return (
    <div className={classes.container}>
      <Grid container component = {Paper} className={classes.gridContainer}>
        <Grid className={classes.gridItems} item xs = {12} sm = {6}>
          <SelectDates/>
        </Grid>
        <Grid className={classes.gridItems} item xs = {12} sm = {6}>
        <TimeFrameSelection 
            timeFrame = {timeFrame}
            setTimeFrame = {setTimeFrame}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2} className={classes.gridContainer}>
        <Grid item xs = {12} sm = {6}>
          <TotalPassengerRevenueCard/>
        </Grid>
        <Grid item xs = {12} sm = {6}>
          <TotalLandingRevenueCard/>
        </Grid>
      </Grid>

      <Grid container spacing={2} className={classes.gridContainer}>
        <Grid item xs = {12} sm = {6} md = {8}>
          <PassengerRevenuePerAirline/>
        </Grid>
        <Grid item xs = {12} sm = {6} md = {4}>
          <CargoLandingRev/>
        </Grid>
      </Grid>

      <Grid container spacing={2} className={classes.gridContainer}>
        <Grid item xs = {12} sm = {5} md = {4}>
          <RevenueSources/>
        </Grid>
        <Grid item xs = {12} sm = {7} md = {8}>
          <RealTimeChartContainer/>
        </Grid>
      </Grid>
      
      <Grid container spacing={2} className={classes.gridContainer}>
        <Grid item xs = {12} lg = {6}>
          <AircraftTypes/>
        </Grid>
        <Grid item xs = {12} lg = {6}>
          <CabinClasses/>
        </Grid>
      </Grid>
    </div>
  );
}

export default RevenueManagementTab