import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@mui/material/Grid';
import CargoSelector from 'components/CargoSelector/CargoSelector';
import SelectDates from "components/SelectDates/SelectDates";
import CargoADSBTable from "components/CargoADSBTable/CargoADSBTable";
import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import ChartContainer from 'components/ChartContainer/ChartContainer';
import { connect } from 'react-redux';
import FlightOperationPhrases from "components/FlightOperationPhrases/FlightOperationPhrases";

const useStyles = makeStyles({
  paper:{
    margin: 15
  },
  gridItems:{
    paddingLeft: 60,
    paddingRight: 60,
    paddingTop: 20,
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "center"
  },
  flightDisruptionPhrases:{
    display: "flex",
    alignItems: "flex-end"
  },
  searchContainer:{
    display: "flex",
    flexDirection: "row",
    marginBottom: 20
  },
  cardContainer:{
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 10,
    width: "inherit"
  },
  container: {
    justifyContent: "center"
  },
  airport:{
    fontSize: 13
  }
});

const CargoTab = (props) => {
  const classes = useStyles();
  const { airport } = props;

  useEffect(() => {
    ReactGA.pageview('/cargo');
  }, [])

  return (
    <div className={classes.paper}>
      <Paper className={classes.searchContainer}>
        <Grid container>
          <Grid className={classes.gridItems} item xs = {12} sm = {12} md = {4}>
            <p className={classes.airport}>{airport.name} - {airport.country === "United States" ? `${airport.city}, ${airport.state}` : `${airport.city}, ${airport.country}`}</p>
          </Grid>
          <Grid className={classes.gridItems} item xs = {12} sm = {6} md = {4}>
            <SelectDates/>
          </Grid>
          <Grid className={classes.gridItems} item xs = {12} sm = {6} md = {4}>
            <CargoSelector/>
          </Grid>
        </Grid>
      </Paper>
      <CargoADSBTable/>
      <ChartContainer/>
      <FlightOperationPhrases showCargoCarriers />
    </div>
  );
}

const mapStateToProps = (state) =>{
  return{
    airport: state.airportReducer.airport,
  }
}

export default connect(mapStateToProps)(CargoTab)