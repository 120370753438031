import { makeStyles } from '@material-ui/core/styles';
import Grid from '@mui/material/Grid';
import AirportMapMenu from 'components/AirportMapMenu/AirportMapMenu';
import ChartContainer from 'components/ChartContainer/ChartContainer';
import FilterFieldsBar from 'components/FilterFieldsBar/FilterFieldsBar';
import FlightOperationPhrases from 'components/FlightOperationPhrases/FlightOperationPhrases';
import React, { useEffect } from 'react';
import ReactGA from 'react-ga';

const useStyles = makeStyles({
  paper:{
    margin: 15
  },
  gridItem:{
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 20
  },
  gridItems:{
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 20,
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "center"
  },
  flightDisruptionPhrases:{
    display: "flex",
    alignItems: "flex-end"
  },
  searchContainer:{
    display: "flex",
    flexDirection: "row"
  },
  cardContainer:{
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center"
  },
  container: {
    justifyContent: "center"
  }
});

const AirportMapTab = () => {
  const classes = useStyles();

  useEffect(() => {
    ReactGA.pageview('/airport-map');
  }, [])

  return (
    <div className={classes.paper}>
      <FilterFieldsBar/>
      <Grid container className={classes.container} spacing = {1}>
        <Grid className={classes.flightDisruptionPhrases} item xs = {12} sm = {12} md = {8} order = {{ xs: 2, sm: 2, md: 1 }}>
          <FlightOperationPhrases showAirportLocations />
        </Grid>
        <Grid className={classes.cardContainer} item xs = {12} sm = {12} md = {4} order = {{ xs: 1, sm: 1, md: 2 }}>
          <AirportMapMenu/>
        </Grid>
      </Grid>
      <ChartContainer/>
    </div>
  );
}

export default AirportMapTab;