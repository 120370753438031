import React, { useRef, useEffect } from 'react';
import {
  Chart, 
  LineController, 
  LineElement, 
  Filler, 
  PointElement, 
  LinearScale, 
  TimeScale, 
  Tooltip
} from 'chart.js';
import 'chartjs-adapter-moment';
import { Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  paper: {
    padding: 10
  }
});

const formatValue = (value) => Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  maximumSignificantDigits: 3,
  notation: 'compact',
}).format(value);

Chart.register(LineController, LineElement, Filler, PointElement, LinearScale, TimeScale, Tooltip);

const chartData = {
  labels: [
    '12-01-2020', '01-01-2021', '02-01-2021',
    '03-01-2021', '04-01-2021', '05-01-2021',
    '06-01-2021', '07-01-2021', '08-01-2021',
    '09-01-2021', '10-01-2021', '11-01-2021',
    '12-01-2021', '01-01-2022', '02-01-2022',
    '03-01-2022', '04-01-2022', '05-01-2022',
    '06-01-2022', '07-01-2022', '08-01-2022',
    '09-01-2022', '10-01-2022', '11-01-2022',
    '12-01-2022', '01-01-2023',
  ],
  datasets: [
    {
      label: 'High Class Seats',
      data: [
        73, 64, 73, 69, 104, 104, 164,
        164, 120, 120, 120, 148, 142, 104,
        122, 110, 104, 152, 166, 233, 268,
        252, 284, 284, 333, 323,
      ],
      borderColor: "purple",
      fill: false,
      backgroundColor: "purple",
      borderWidth: 2,
      tension: 0,
      pointRadius: 0,
      pointHoverRadius: 3,
      pointBackgroundColor: "purple",
    },
    {
      label: 'Middle Class Seats',
      data: [
        184, 86, 42, 378, 42, 243, 38,
        120, 0, 0, 42, 0, 84, 0,
        276, 0, 124, 42, 124, 88, 88,
        215, 156, 88, 124, 64,
      ],
      borderColor: "green",
      fill: false,
      backgroundColor: "green",
      borderWidth: 2,
      tension: 0,
      pointRadius: 0,
      pointHoverRadius: 3,
      pointBackgroundColor: "green",
    },
    {
      label: 'Regular Seats',
      data: [
        122, 170, 192, 86, 102, 124, 115,
        115, 56, 104, 0, 72, 208, 186,
        223, 188, 114, 162, 200, 150, 118,
        118, 76, 122, 230, 268,
      ],
      borderColor: "orange",
      backgroundColor: "orange",
      fill: false,
      borderWidth: 2,
      tension: 0,
      pointRadius: 0,
      pointHoverRadius: 3,
      pointBackgroundColor: "orange",
    },
  ],
};

const CabinClasses = () => {
  const classes = useStyles();
  const data = chartData;
  const canvas = useRef(null);

  useEffect(() => {
    const ctx = canvas.current;
    const chart = new Chart(ctx, {
      type: 'line',
      data: data,
      options: {
        layout: {
          padding: 20,
        },
        scales: {
          y: {
            grid: {
              drawBorder: false,
              beginAtZero: true,
            },
            ticks: {
              maxTicksLimit: 5,
              callback: (value) => formatValue(value),
            },
          },
          x: {
            type: 'time',
            time: {
              parser: 'MM-DD-YYYY',
              unit: 'month',
              displayFormats: {
                month: 'MMM YY',
              },
            },
            grid: {
              display: false,
              drawBorder: false,
            },
            ticks: {
              autoSkipPadding: 48,
              maxRotation: 0,
            },
          },
        },
        plugins: {
          legend: {
            display: true,
          },
          tooltip: {
            callbacks: {
              title: () => false, // Disable tooltip title
              label: (context) => formatValue(context.parsed.y),
            },
          },
        },
        interaction: {
          intersect: false,
          mode: 'nearest',
        },
        maintainAspectRatio: false,
        resizeDelay: 200,
      },
      plugins: [{
        id: 'htmlLegend'
      }],
    });
    return () => chart.destroy();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Paper className={classes.paper}>
      <Typography variant="h6">Cabin Classes</Typography>
      <div>
        <div>
          <canvas ref={canvas} width={595} height={248}></canvas>
        </div>
      </div>
    </Paper>
  );
}

export default CabinClasses;
