import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import React, { useState, useRef, useEffect } from 'react';
import ADSBModeSTable from "components/ADSBModeSTable/ADSBModeSTable";
import { convertToLocal } from 'utils/timeConversions';

const CargoADSBRow = (props) => {
    const { row } = props;
    const [open, setOpen] = useState(false);
    const [adsbTableWidth, setAdsbTableWidth] = useState(null)
    const tableRowRef = useRef()

    useEffect(() => {
        setAdsbTableWidth(tableRowRef?.current?.clientWidth)
    }, [tableRowRef])

    return (
        <React.Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell>
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">{convertToLocal(row.timestamp)}</TableCell>
                <TableCell align = "left">{row.aircraftIdentification ? row.aircraftIdentification : "---"}</TableCell>
                <TableCell align = "left">{row.icaoAddress ? row.icaoAddress : "---"}</TableCell>
            </TableRow>
            <TableRow ref = {tableRowRef}>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <div style = {{width: adsbTableWidth}}>
                            <ADSBModeSTable flightRowTimestamp  = {row.timestamp} icaoAddress = {row.icaoAddress}/>
                        </div>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

export default CargoADSBRow;