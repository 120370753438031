import Typography from '@material-ui/core/Typography';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import MenuIcon from '@mui/icons-material/Menu';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { styled, useTheme } from '@mui/material/styles';
import React, { useState } from 'react';
import { darkerBlue } from 'styles/colors';
import "./drawer.css";
import { connect } from 'react-redux';

const drawerWidth = 240;

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

const ConcessionMapDrawer = (props) => {
  const theme = useTheme();
  const [open, setOpen] = useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ display: 'flex', position: "absolute", top: 0, left: 0, zIndex: 1 }}>
      <CssBaseline />
      <IconButton
        color="inherit"
        aria-label="open drawer"
        onClick={handleDrawerOpen}
        edge="start"
        sx={{ 
          ...(open && { display: 'none' }),
          height: 30,
          marginLeft: 0,
          borderRadius: 0,
          background: darkerBlue
        }}
      >
          <MenuIcon style={{ color: 'white' }}/>
      </IconButton>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
              width: drawerWidth,
              boxSizing: 'border-box',
              position: "relative"
          },
          position: "relative"
        }}
        variant="persistent"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider/>
        <List>
          <ListItem><Typography variant="body2">5,000 total seats</Typography></ListItem>
          <ListItem><Typography variant="body2">40 high class seats, 32 middle class seats, 110 lower class seats</Typography></ListItem>
          <ListItem><Typography variant="body2">Projected Revenue: $56,435</Typography></ListItem>
          <ListItem><Typography variant="body2">6 hours of total flight delays</Typography></ListItem>
          <ListItem><Typography variant="body2">First Scheduled Flight Time: 8:00am</Typography></ListItem>
          <ListItem><Typography variant="body2">Last Scheduled Flight Time: 8:44pm</Typography></ListItem>
          <ListItem><Typography variant="body2">Last Actual Flight Time: 9:53pm</Typography></ListItem>
          <ListItem><Typography variant="body2">Extra Revenue Earned: $3,459</Typography></ListItem>
        </List>
      </Drawer>
    </Box>
  );
}

const mapStateToProps = (state) =>{
  return{
    showAircrafts: state.concessionsReducer.showAircrafts
  }
}

export default connect(mapStateToProps)(ConcessionMapDrawer)