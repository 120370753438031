import { makeStyles } from '@material-ui/core/styles';
import Grid from '@mui/material/Grid';
import FlightStatsTable from 'components/FlightStatsTable/FlightStatsTable';
import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import Paper from '@material-ui/core/Paper';
import AirlineSelector from 'components/AirlineSelector/AirlineSelector';
import FlightNumberSelector from 'components/FlightNumberSelector/FlightNumberSelector';

const useStyles = makeStyles({
  paper:{
    margin: 15
  },
  container: {
    justifyContent: "center",
    background: "white"
  },
  gridItems:{
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 20,
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "center"
  },
  filterBar: {
    marginBottom: 20
  }
});

const FlightStatsTab = () => {
  const classes = useStyles();

  useEffect(() => {
    ReactGA.pageview('/flight-stats');
  }, [])

  return (
    <div className={classes.paper}>
      <Grid container className={classes.filterBar} component={Paper}>
        <Grid className={classes.gridItems} item xs = {12} sm = {6}>
          <AirlineSelector/>
        </Grid>
        <Grid className={classes.gridItems} item xs = {12} sm = {6}>
          <FlightNumberSelector disableDelay/>
        </Grid>
      </Grid>
      <Grid container className={classes.container}>
        <FlightStatsTable/>
      </Grid>
    </div>
  );
}

export default FlightStatsTab;