import { alpha, makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React from 'react';
import ReactGA from 'react-ga';

const useStyles = makeStyles(theme => ({
  search: {
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
  }
}));

const getAircraftNames = (webpackContext) => {
  const imageSrcs = webpackContext.keys().map(webpackContext);
  return imageSrcs.map((imgSrc) => {
    return imgSrc.substring(imgSrc.lastIndexOf('/') + 1, imgSrc.indexOf('.'));
  })
}

const aircraftNames = getAircraftNames(require.context('../../assets/img/planes', false, /\.(png|jpe?g|svg)$/));

export const AircraftSelector = (props) => {
  const classes = useStyles();
  const { aircraftName, setAircraftName } = props;

  const handleChange = (event, value) => {
    if (value){
      setAircraftName(value)
    }
    ReactGA.event({
      category: 'Aircraft Selection',
      action: value
    });
  };

  return (
    <Autocomplete
      freeSolo
      options={aircraftNames}
      value={aircraftName}
      onChange={handleChange} 
      renderInput={params => (
        <TextField
          {...params}
          label="Select aircraft"
          variant="filled"
          InputProps={{ ...params.InputProps, className: classes.search }}
        />
      )}
    />
  );
}

export default AircraftSelector