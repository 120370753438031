import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@mui/material/Grid';
import AircraftDiagram from 'components/AircraftDiagram/AircraftDiagram';
import AircraftSelector from 'components/AircraftSelector/AircraftSelector';
import ChartContainer from 'components/ChartContainer/ChartContainer';
import FlightOperationPhrases from "components/FlightOperationPhrases/FlightOperationPhrases";
import SelectDates from "components/SelectDates/SelectDates";
import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga';
import { connect } from 'react-redux';

const useStyles = makeStyles({
  paper:{
    margin: 15
  },
  gridItems:{
    paddingLeft: 60,
    paddingRight: 60,
    paddingTop: 20,
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "center"
  },
  flightDisruptionPhrases:{
    display: "flex",
    alignItems: "flex-end"
  },
  searchContainer:{
    display: "flex",
    flexDirection: "row",
    marginBottom: 20
  },
  cardContainer:{
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    width: "inherit"
  },
  container: {
    justifyContent: "center"
  },
  airport:{
    fontSize: 13
  }
});

const AircraftDiagramTab = (props) => {
  const classes = useStyles();
  const { airport } = props;
  const [aircraftName, setAircraftName] = useState("Boeing-737-100")
  const [isBoeing, setIsBoeing] = useState(true)

  useEffect(() => {
    if (aircraftName?.indexOf("Boeing") > -1){
      setIsBoeing(true)
    }else{
      setIsBoeing(false)
    }
  }, [aircraftName])

  useEffect(() => {
    ReactGA.pageview('/aircaft-diagram');
  }, [])

  return (
    <div className={classes.paper}>
      <Grid component = {Paper} container className={classes.searchContainer}>
        <Grid className={classes.gridItems} item xs = {12} sm = {12} md = {4}>
          <p className={classes.airport}>{airport.name} - {airport.country === "United States" ? `${airport.city}, ${airport.state}` : `${airport.city}, ${airport.country}`}</p>
        </Grid>
        <Grid className={classes.gridItems} item xs = {12} sm = {6} md = {4}>
          <SelectDates/>
        </Grid>
        <Grid className={classes.gridItems} item xs = {12} sm = {6} md = {4}>
          <AircraftSelector aircraftName = {aircraftName} setAircraftName = {setAircraftName}/>
        </Grid>
      </Grid>
      <Grid container spacing={1} className={classes.container}>
        <Grid className={classes.flightDisruptionPhrases} item xs = {12} sm = {12} md = {5} order = {{ xs: 2, sm: 2, md: 1 }}>
          <FlightOperationPhrases aircraftName = {aircraftName} isBoeing={isBoeing}/>
        </Grid>
        <Grid className={classes.cardContainer} item xs = {12} sm = {12} md = {7} order = {{ xs: 1, sm: 1, md: 2 }}>
          <AircraftDiagram aircraftName = {aircraftName}/>
        </Grid>
      </Grid>
      <ChartContainer/>
    </div>
  );
}

const mapStateToProps = (state) =>{
  return{
    airport: state.airportReducer.airport
  }
}

export default connect(mapStateToProps)(AircraftDiagramTab)