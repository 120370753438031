import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { connect } from 'react-redux';

const useStyles = makeStyles({
  root: {
    width: "100%"
  },
  media: {
    height: 140,
  },
  cardActions: {
    justifyContent: "center"
  }
});

const FlightDisruptionDescCard = (props) => {
  const classes = useStyles();
  const { disruption } = props;

  if(typeof disruption.phrase !== "undefined"){
    return (
      <Card className={classes.root}>
        <CardMedia
          className = {classes.media}
          image = {disruption.imgUrl}
          title = {disruption.phrase}
        />
        <CardContent>
          <Typography gutterBottom variant="h6">
            {disruption?.flightNumber} {disruption.phrase} 
          </Typography>
          <Typography variant="body2">
            Delay Time Range: {disruption.avgDelayTime} mins
          </Typography>
        </CardContent>
      </Card>
    );
  }else{
    return <></>
  }
}

const mapStateToProps = (state) => {
  return{
    disruption: state.flightDisruptionReducer.disruption
  }
}

export default connect(mapStateToProps, null)(FlightDisruptionDescCard)