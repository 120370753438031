import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import React, { useState, useEffect } from "react";
import { List, ListItem, ListItemText } from '@mui/material';
import { searchTaxis, toggleBlockTaxi } from 'api/endpoints/parkingQueue';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

const styles = {
    button: {
        minWidth: '100px',
        minHeight: '40px',
        whiteSpace: 'nowrap',
        padding: '5px 15px',
        fontSize: '14px'
    },
    loadingSpinnerContainer:{
        display: "flex",
        justifyContent: "center",
        margin: 10
    }
}

const BlockTaxiButton = ({ taxiIsBlocked, uid }) => {
    const [isBlocked, setIsBlocked] = useState(taxiIsBlocked);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsBlocked(taxiIsBlocked);
    }, [taxiIsBlocked]);

    const handleTaxiAccessToggle = async (uuid) => {
        setIsLoading(true);

        try {
            await toggleBlockTaxi(uuid);
            setIsBlocked((prev) => !prev);
        } catch (e) {
            console.error(e);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        isLoading ? (
            <CircularProgress size={24} />
        ) : (
            <Button
                variant="outlined"
                color={isBlocked ? 'error' : 'primary'}
                sx={styles.button}
                onClick={() => handleTaxiAccessToggle(uid)}
            >
                {isBlocked ? 'Blocked' : 'Allowed'}
            </Button>
        )
    );
};

const TaxiAccessModal = (props) => {
    const { taxiModalOpen, setTaxiModalOpen } = props;
    const [taxis, setTaxis] = useState([]);
    const [searchText, setSearchText] = useState("");
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        let mounted = true;

        const fetchResults = async () => {
            setIsLoading(true);
            try {
                const taxisSearched = await searchTaxis(searchText);
                if (!mounted) return;
                setTaxis(taxisSearched.data.taxis);
            } catch (error) {
                console.error("Failed to fetch taxis:", error);
            } finally {
                if (mounted) {
                    setIsLoading(false);
                }
            }
        };

        fetchResults()
        return () => {
            mounted = false
        }
    }, [searchText])

    const handleClose = () => {
        setTaxiModalOpen(false);
    };

    const handleTaxiSearch = (event) => {
        const val = event.target.value;
        setSearchText(val);
    };

    return (
        <Dialog
            disableEnforceFocus
            open={taxiModalOpen}
            onClose={handleClose}
        >
            <DialogTitle>Taxi Access</DialogTitle>

            <DialogContent sx={{ width: { xs: 300, sm: 350 }}}>
                <TextField
                    label="Search name or taxi company"
                    onChange={handleTaxiSearch}
                    fullWidth
                    variant="standard"
                />
                {isLoading ? <Box sx={styles.loadingSpinnerContainer}><CircularProgress/></Box> : 
                    <List>
                        {taxis.map((taxi, i) => (
                            <ListItem key={i}>
                                <ListItemText 
                                    primary={taxi.firstAndLastName} 
                                    secondary={`${taxi.companyName} - ${taxi.taxiNumber}`} 
                                />
                                <BlockTaxiButton 
                                    taxiIsBlocked={taxi.isBlocked}
                                    uid={taxi.uid}
                                />
                            </ListItem>
                        ))}
                    </List>
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Done</Button>
            </DialogActions>
        </Dialog>
    );
}

export default TaxiAccessModal;