export const convertToLocal = (timestamp) => {
  return new Date(timestamp * 1000).toLocaleString([], {
    month: '2-digit', 
    year: '2-digit', 
    day: '2-digit', 
    hour: '2-digit', 
    minute: '2-digit'
  })
}

export const convertToTime = (timestamp) => {
  return new Date(timestamp * 1000).toLocaleString([], {
    month: '2-digit', 
    day: '2-digit', 
    hour: '2-digit', 
    minute: '2-digit'
  })
}

export const parseTime = (s) => {
  let part = s.match(/(\d+):(\d+)(?: )?(am|pm)?/i);
  let hh = parseInt(part[1], 10);
  let mm = parseInt(part[2], 10);
  let ap = part[3] ? part[3].toUpperCase() : null;
  if (ap === "AM") {
    if (hh === 12) {
      hh = 0;
    }
  }
  if (ap === "PM") {
    if (hh !== 12) {
      hh += 12;
    }
  }
  return { 
    hh: hh, 
    mm: mm
  };
}

export const toHoursAndMinutes = (totalMinutes) => {
  const minutes = totalMinutes % 60;
  const hours = Math.floor(totalMinutes / 60);
  if(hours){
    return `${hours} hours ${minutes} minutes`;
  }
  return `${minutes} minutes`; 
}