import axios from "axios";

const parameters = axios.create({
  baseURL: process.env.NODE_ENV === "development"
  ? process.env.REACT_APP_DEV_API_URL
  : process.env.REACT_APP_PROD_API_URL,
  responseType: "json",
  headers: {
    "x-access-token": "bfb4b6ca-e22e-47e8-95ed-c5bead63435d",
    "Content-Type": "application/json"
  }
});

export default parameters;