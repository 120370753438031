import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import "firebase/auth";

const firebaseConfig = {
  apiKey: process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_DEV_GOOGLE_API_KEY
    : process.env.REACT_APP_PROD_GOOGLE_API_KEY,
  authDomain: "undelay-aaeb6.firebaseapp.com",
  databaseURL: "https://undelay-aaeb6.firebaseio.com",
  projectId: "undelay-aaeb6",
  storageBucket: "undelay-aaeb6.appspot.com",
  messagingSenderId: "178724604938",
  appId: "1:178724604938:web:8d2b2c41b719e297a223f3"
}

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export default app;