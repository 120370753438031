import React from 'react';
import { GoogleMap, Marker, Polygon, useJsApiLoader } from '@react-google-maps/api';
import { connect } from 'react-redux';
import { useQuery } from 'react-query';
import { getAircraftLocations } from "api/endpoints/adsb";
import { 
    runwayZoneOne, 
    runwayZoneTwo, 
    runwayZoneThree,
    runwayZoneFour,
    runwayZoneFive,
    runwayZoneSix,
    runwayZoneSeven,
    runwayZoneEight,
    runwayZoneNine,
    runwayZoneTen,
    runwayZoneEleven,
    runwayZoneTwelve,
    runwayZoneThirteen,
    runwayZoneFourteen,
    runwayZoneFifteen,
    runwayZoneSixteen
} from './runwayZones';
import { 
    gateZoneA1,
    gateZoneA2,
    gateZoneA3A,
    gateZoneA3B,
    gateZoneA4A,
    gateZoneA4B,
    gateZoneA5A,
    gateZoneA5B,
    gateZoneC1,
    gateZoneC2,
    gateZoneC3BA,
    gateZoneC3B,
    gateZoneC4A,
    gateZoneC4B,
    gateZoneC5A,
    gateZoneC5B,
    gateZoneC6A,
    gateZoneC6B,
    gateZoneC7A,
    gateZoneC7B,
} from './gateZones';
import { isInZone } from 'utils/zoning';
import { darkerBlue, transparentLightBlue, transparentRed } from 'styles/colors';

//Google Map Package Docs
//https://react-google-maps-api-docs.netlify.app/

const containerStyle = {
    width: '100%',
    height: '600px'
};

const zoneOptions = {
    fillColor: transparentRed,
    fillOpacity: 1,
    strokeOpacity: 1,
    strokeWeight: 2,
    clickable: false,
    draggable: false,
    editable: false,
    geodesic: false,
    zIndex: 1
}

const runwayZoneOptions = {
    ...zoneOptions,
    strokeColor: "red",
    fillColor: transparentRed,
}

const gateZoneOptions = {
    ...zoneOptions,
    strokeColor: darkerBlue,
    fillColor: transparentLightBlue,
}


const SurfaceManagementMap = (props) => {
    const { airport, setAircraftDetails } = props;

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: process.env.NODE_ENV === "development"
            ? process.env.REACT_APP_DEV_GOOGLE_API_KEY
            : process.env.REACT_APP_PROD_GOOGLE_API_KEY,
    })

    const { data: aircraftLocations } = useQuery(
        ['aircraftLocations'],
        async () => {
            const response = await getAircraftLocations()
            const aircraftLocation = response.data.data;
            const lat = Number(aircraftLocation[0].latDecoded);
            const long = Number(aircraftLocation[0].longDecoded);
            if(
                isInZone(lat, long, runwayZoneOne) ||
                isInZone(lat, long, runwayZoneTwo) ||
                isInZone(lat, long, runwayZoneThree) ||
                isInZone(lat, long, runwayZoneFour) ||
                isInZone(lat, long, runwayZoneFive) ||
                isInZone(lat, long, runwayZoneSix) ||
                isInZone(lat, long, runwayZoneSeven) ||
                isInZone(lat, long, runwayZoneEight) ||
                isInZone(lat, long, runwayZoneNine) ||
                isInZone(lat, long, runwayZoneTen) ||
                isInZone(lat, long, runwayZoneEleven) ||
                isInZone(lat, long, runwayZoneTwelve) ||
                isInZone(lat, long, runwayZoneThirteen) ||
                isInZone(lat, long, runwayZoneFourteen) ||
                isInZone(lat, long, runwayZoneFifteen) ||
                isInZone(lat, long, runwayZoneSixteen) 
            ){
                setAircraftDetails({
                    ...aircraftLocation[0],
                    timeEntered: new Date().toLocaleTimeString()
                })
                return aircraftLocation
            }
            return null
        },
        {
          refetchInterval: 3000,
          refetchIntervalInBackground: true,
          cacheTime: 0
        },
    ) 

    return isLoaded ? (
        <GoogleMap
            mapTypeId = "satellite"
            mapContainerStyle={containerStyle}
            center={airport.center}
            zoom={airport.mapZoom}
        >
            <Polygon
                paths={runwayZoneOne}
                options={runwayZoneOptions}
            />
            <Polygon
                paths={runwayZoneTwo}
                options={runwayZoneOptions}
            />
            <Polygon
                paths={runwayZoneThree}
                options={runwayZoneOptions}
            />
            <Polygon
                paths={runwayZoneFour}
                options={runwayZoneOptions}
            />
            <Polygon
                paths={runwayZoneFive}
                options={runwayZoneOptions}
            />
            <Polygon
                paths={runwayZoneSix}
                options={runwayZoneOptions}
            />
            <Polygon
                paths={runwayZoneSeven}
                options={runwayZoneOptions}
            />
            <Polygon
                paths={runwayZoneEight}
                options={runwayZoneOptions}
            />
            <Polygon
                paths={runwayZoneNine}
                options={runwayZoneOptions}
            />
            <Polygon
                paths={runwayZoneTen}
                options={runwayZoneOptions}
            />
            <Polygon
                paths={runwayZoneEleven}
                options={runwayZoneOptions}
            />
            <Polygon
                paths={runwayZoneTwelve}
                options={runwayZoneOptions}
            />
            <Polygon
                paths={runwayZoneThirteen}
                options={runwayZoneOptions}
            />
            <Polygon
                paths={runwayZoneFourteen}
                options={runwayZoneOptions}
            />
            <Polygon
                paths={runwayZoneFifteen}
                options={runwayZoneOptions}
            />
            <Polygon
                paths={runwayZoneSixteen}
                options={runwayZoneOptions}
            />
            <Polygon
                paths={gateZoneA1}
                options={gateZoneOptions}
            />
            <Polygon
                paths={gateZoneA2}
                options={gateZoneOptions}
            />
            <Polygon
                paths={gateZoneA3A}
                options={gateZoneOptions}
            />
            <Polygon
                paths={gateZoneA3B}
                options={gateZoneOptions}
            />
            <Polygon
                paths={gateZoneA4A}
                options={gateZoneOptions}
            />
            <Polygon
                paths={gateZoneA4B}
                options={gateZoneOptions}
            />
            <Polygon
                paths={gateZoneA5A}
                options={gateZoneOptions}
            />
            <Polygon
                paths={gateZoneA5B}
                options={gateZoneOptions}
            />
            <Polygon
                paths={gateZoneA5B}
                options={gateZoneOptions}
            />
            <Polygon
                paths={gateZoneC1}
                options={gateZoneOptions}
            />
            <Polygon
                paths={gateZoneC2}
                options={gateZoneOptions}
            />
            <Polygon
                paths={gateZoneC3B}
                options={gateZoneOptions}
            />
            <Polygon
                paths={gateZoneC3BA}
                options={gateZoneOptions}
            />
            <Polygon
                paths={gateZoneC4A}
                options={gateZoneOptions}
            />
            <Polygon
                paths={gateZoneC4B}
                options={gateZoneOptions}
            />
            <Polygon
                paths={gateZoneC5A}
                options={gateZoneOptions}
            />
            <Polygon
                paths={gateZoneC5B}
                options={gateZoneOptions}
            />
            <Polygon
                paths={gateZoneC6A}
                options={gateZoneOptions}
            />
            <Polygon
                paths={gateZoneC6B}
                options={gateZoneOptions}
            />
            <Polygon
                paths={gateZoneC7A}
                options={gateZoneOptions}
            />
            <Polygon
                paths={gateZoneC7B}
                options={gateZoneOptions}
            />
            {aircraftLocations?.length && (aircraftLocations.map((data, i) => {
                return(
                    <Marker
                        key = {i}
                        icon="https://firebasestorage.googleapis.com/v0/b/undelay-aaeb6.appspot.com/o/icons%2Fplane-flight-icon.png?alt=media&token=12c27053-d6d9-455a-92ba-2e96f8c94764"
                        position={{
                            lat: Number(data.latDecoded),
                            lng: Number(data.latDecoded)
                        }}
                    />
                )
            }))}
        </GoogleMap>
    ) : null
}

const mapStateToProps = (state) => {
    return{
        airport: state.airportReducer.airport
    }
}

export default connect(mapStateToProps)(SurfaceManagementMap);