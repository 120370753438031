import { makeStyles } from '@material-ui/core/styles';
import Grid from '@mui/material/Grid';
import ChartDateSelectionButton from 'components/ChartDateSelectionButton/ChartDateSelectionButton';
import WordCountBarGraph from 'components/WordCountBarGraph/WordCountBarGraph';
import WordCountPieChart from 'components/WordCountPieChart/WordCountPieChart';
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom'
import parameters from 'api/parameters';
import axios from "axios";
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles({
  chartContainer:{
    marginTop: 20,
    marginBottom: 20
  },
  chartButtonContainer:{
    display: "flex",
    justifyContent: "space-between",
    padding: 10,
    alignItems: "center",
    marginBottom: 20,
    background: "white"
  },
  chartContainers:{
    height: 600,
  },
  loadingContainer:{
    justifyContent: "center",
    display: "flex",
    alignSelf: "center",
    margin: "0 auto",
    height: 600,
    alignItems: "center",
    background: "white",
    width: "100%"
  },
  noResultsContainer:{
    display: "flex",
    alignItem: "center",
    flexDirection: "column",
    height: 600,
    background: "white"
  },
  noMessageResults:{
    fontSize: 12,
    padding: 10
  },
  wordCountTitle:{
    fontSize: 15,
    paddingLeft: 20
  }
});

const ChartContainer = (props) => {
  const classes = useStyles();
  const [timeFrame, setTimeFrame] = useState(undefined)
  const [wordCount, setWordCount] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const location = useLocation();
  const axiosSource = axios.CancelToken.source();
  const { airline, airport, flightDate, title } = props;

  const getUrl = () => {
    let url = "/count"
    switch (true) {
      case location.pathname.indexOf("disruption") > 0:
        url += "Disruptions"
        break;
      case location.pathname.indexOf("weather") > 0:
        url += "WeatherTerms"
        break;
      case location.pathname.indexOf("aircraft") > 0:
        url += "Aircrafts"
        break;
      case location.pathname.indexOf("ground") > 0:
        url += "GroundOps"
        break;
      case location.pathname.indexOf("map") > 0:
        url += "AirportLocations"
        break;
      case location.pathname.indexOf("cargo") > 0:
        url += "CargoAircraftCarriers"
        break;
      default:
        url += "Disruptions"
        break;
    }
    return url
  }

  const getWordCount = async() => {
    try {
      let wordCountRes = await parameters.post(getUrl(), { 
        startTimestamp: timeFrame ? flightDate.getTime()/1000 - timeFrame : 0,
        endTimestamp: flightDate.getTime()/1000,
        airport: airport.code,
        airline: airline
      }, {cancelToken: axiosSource.token});
      return wordCountRes.data.data;
    } catch (error) {
      console.log("error", error)
    }
  }

  useEffect(() => {
    let active = true  
    const loadWordCount = async() => {
      setIsLoading(true)
      const result = await getWordCount()
      if (!active) { return }
      const filteredResult = result.filter((item) => {
        return item.count > 0
      });
      console.log(filteredResult)
      setWordCount(filteredResult)
      setIsLoading(false)
    }
    loadWordCount()
    return () => { active = false }
    // eslint-disable-next-line
  }, [location, flightDate, airport, airline, timeFrame])
  
  return (
    <div className={classes.chartContainer}>
      <div className={classes.chartButtonContainer}>
        <p className={classes.wordCountTitle}>{title}</p>
        <ChartDateSelectionButton setTimeFrame = {setTimeFrame}/>
      </div>
      {
        isLoading ? 
          <div className={classes.loadingContainer}> <CircularProgress/> </div> 
        :
        !isLoading && wordCount.length < 1 ?
          <div className = {classes.noResultsContainer}>
            <p className = {classes.noMessageResults}>No results are shown for this selection.  Please select another time frame, airline, or airport.</p>
          </div> 
        :
        <Grid container justify="space-around" spacing={1}>
          <Grid item xs = {12} sm = {12} md = {6} className={classes.chartContainers}>
            <WordCountBarGraph data = {wordCount}/>
          </Grid>
          <Grid item xs = {12} sm = {12} md = {6} className={classes.chartContainers}>
            <WordCountPieChart data = {wordCount}/>
          </Grid>
        </Grid>
      }
    </div>
  );
}

const mapStateToProps = (state) => {
  return{
    airport: state.airportReducer.airport,
    flightDate: state.filterFieldsBarReducer.flightDate,
    airline: state.filterFieldsBarReducer.airline
  }
}

export default connect(mapStateToProps)(ChartContainer)