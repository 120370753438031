import { createStore, combineReducers } from 'redux';
import airportReducer from "./airport/reducer";
import filterFieldsBarReducer from "./filterFieldsBar/reducer";
import flightDisruptionReducer from "./flightDisruption/reducer";
import concessionsReducer from "./concessions/reducer";
import userReducer from "./user/reducer"
import gateManagementReducer from "./gateManagement/reducer"

export default createStore(combineReducers({
    airportReducer,
    filterFieldsBarReducer,
    flightDisruptionReducer,
    concessionsReducer,
    userReducer,
    gateManagementReducer
}), {});