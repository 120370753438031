import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@mui/material/Grid';
import AirlineSelector from 'components/AirlineSelector/AirlineSelector';
import FlightNumberSelector from 'components/FlightNumberSelector/FlightNumberSelector';
import SelectDates from "components/SelectDates/SelectDates";
import React from 'react';

const useStyles = makeStyles({
  paper:{
    margin: 15
  },
  gridItems:{
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 20,
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "center"
  },
  flightDisruptionPhrases:{
    display: "flex",
    alignItems: "flex-end"
  },
  searchContainer:{
    display: "flex",
    flexDirection: "row",
    marginBottom: 20
  },
  cardContainer:{
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 10
  },
  airport:{
    fontSize: 13
  }
});

const FilterFieldsBar = () => {
  const classes = useStyles();
  return (
    <Grid className={classes.searchContainer} container component={Paper}>
      <Grid className={classes.gridItems} item xs = {12} sm = {4}>
        <SelectDates/>
      </Grid>
      <Grid className={classes.gridItems} item xs = {12} sm = {4}>
        <AirlineSelector/>
      </Grid>
      <Grid className={classes.gridItems} item xs = {12} sm = {4}>
        <FlightNumberSelector/>
      </Grid>
    </Grid>
  );
}

export default FilterFieldsBar