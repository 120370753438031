import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { getGateChangeSuggestions } from 'api/endpoints/gateManagement';
import { useQuery } from 'react-query';
import { convertToLocal } from 'utils/timeConversions';
import React from 'react';

const useStyles = makeStyles({
    topContainer:{
        display: "flex",
        justifyContent: "flex-end",
        padding: 10
    },
    tableContainer:{
        height: 500
    },
    loadingContainer:{
        justifyContent: "center",
        display: "flex",
        margin: 20
    },
    taxiLoadingContainer:{
        justifyContent: "center",
        display: "flex"
    },
});


const GateChangeSuggestionsTable = () => {
    const classes = useStyles();

    const { data: gateChangeSuggestions, isFetching} = useQuery(
        ['getGateChangeSuggestions'],
        async () => {
            const response = await getGateChangeSuggestions()
            return response.data.data
        },
        {
          refetchInterval: 3000,
          refetchIntervalInBackground: true,
          cacheTime: 0
        },
    ) 

    if(isFetching && !gateChangeSuggestions?.length){
        return <div className={classes.loadingContainer}> <CircularProgress/> </div> 
    }

    return (
        <TableContainer className={classes.tableContainer}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell align="left">Ground Operator</TableCell>
                        <TableCell align="left">Flight Number</TableCell>
                        <TableCell align="left">Gate Selected</TableCell>
                        <TableCell align="left">Gate Suggested</TableCell>
                        <TableCell align="left">Gate Change Reason</TableCell>
                        <TableCell align="left">Time</TableCell>
                        <TableCell align="center">Map</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {gateChangeSuggestions.map((gateChangeSuggestion, i) => {
                        return(
                            <TableRow key = {i}>
                                <TableCell align = "left">{gateChangeSuggestion.firstAndLastName}</TableCell>
                                <TableCell align = "left">{gateChangeSuggestion.flightNumber}</TableCell>
                                <TableCell align = "left">{gateChangeSuggestion.gateSelected}</TableCell>
                                <TableCell align = "left">{gateChangeSuggestion.gateSuggested}</TableCell>
                                <TableCell align = "left">{gateChangeSuggestion.gateChangeReason}</TableCell>
                                <TableCell align = "left">{convertToLocal(gateChangeSuggestion.timestamp)}</TableCell>
                                <TableCell align = "center"><img style = {{width: 200}} src = {gateChangeSuggestion.url}/></TableCell>
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default GateChangeSuggestionsTable