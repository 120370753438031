import React from 'react';
import { 
  Pie, 
  PieChart, 
  Legend, 
  Tooltip, 
  Cell, 
  ResponsiveContainer 
} from 'recharts';

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884d8', '#de4444'];

const WordCountPieChart = (props) => {
  let { data } = props;
  let count = 0;
  for(let dataFiled in data){
    data[dataFiled]["color"] = COLORS[count]
    count ++;
    if (count > COLORS.length - 1){
      count = 0;
    }
  }

  return (
    <ResponsiveContainer width="100%" height="100%">
      <PieChart 
        width={370} 
        height={data.length < 20 ? 400 : 600} 
        margin={{
          top: 30,
          right: 20,
          left: 20,
        }}
      >
        <Pie
          dataKey="count"
          data={data}
          cx="50%"
          cy={
            data.length < 20 ? "50%" : 
            window.innerWidth < 830 && data.length > 20 ? "80%" : 
            "65%"}
          outerRadius={80}
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.color} />
          ))}
        </Pie>
        <Tooltip />
        <Legend verticalAlign="top" height={10}/>
      </PieChart>
    </ResponsiveContainer>
  );
}

export default WordCountPieChart;