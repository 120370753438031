import { alpha, makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React from 'react';
import { updateCargoCarrier } from 'store/filterFieldsBar/actions';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';

const useStyles = makeStyles(theme => ({
  search: {
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
  }
}));

const cargoCarriers = ["Amazon", "UPS", "FedEx"]

const CargoSelector = (props) => {
  const { cargoCarrier, updateCargoCarrier } = props;
  const classes = useStyles();

  const handleChange = (event, value) => {
    if (value){
      updateCargoCarrier(value)
      ReactGA.event({
        category: 'Cargo Carrier Selection',
        action: value
      });
    }
  };

  return (
    <Autocomplete
      freeSolo
      options={cargoCarriers}
      value={cargoCarrier}
      onChange={handleChange} 
      renderInput={params => (
        <TextField
          {...params}
          label="Select cargo carrier"
          variant="filled"
          InputProps={{ ...params.InputProps, className: classes.search }}
        />
      )}
    />
  );
}

const mapStateToProps = (state) =>{
  return{
    cargoCarrier: state.filterFieldsBarReducer.cargoCarrier
  }
}

const mapDispatchToProps = {
  updateCargoCarrier,
}

export default connect(mapStateToProps, mapDispatchToProps)(CargoSelector)