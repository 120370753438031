import React from 'react';
import { connect } from 'react-redux';
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.min.css';
import InnerImageZoom from 'react-inner-image-zoom';
import Paper from '@material-ui/core/Paper';


export const AirportMapMenu = (props) => {
  const { airport } = props;

  return (
    <Paper style = {{height: window.innerHeight > 800 ? 700 : 500, overflow: "auto"}}>
      <InnerImageZoom src={require(`../../../src/assets/img/airportMaps/${airport.code}/detailedMap.jpg`)}/>
    </Paper>
  );
}

const mapStateToProps = (state) =>{
  return{
    airport: state.airportReducer.airport
  }
}

export default connect(mapStateToProps, null)(AirportMapMenu)