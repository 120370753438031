import { makeStyles } from '@material-ui/core/styles';
import Grid from '@mui/material/Grid';
import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga';
import Paper from '@material-ui/core/Paper';
import ConcessionMap from 'components/ConcessionMap/ConcessionMap';
import SelectDates from "components/SelectDates/SelectDates";
import { connect } from 'react-redux';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import { darkerBlue, green, purple, red, UnDelayLogoBlue } from 'styles/colors';

const useStyles = makeStyles({
    container:{
        margin: 15
    },
    paper:{
        marginBottom: 15,
        display: "flex",
        overflow: "auto"
    },
    searchContainer:{
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        marginBottom: 15,
        padding: 10
    },
    gridItems:{
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    airlines:{
        padding: 13,
        fontWeight: "bold"
    }
});

const ConcessionsTab = () => {
    const classes = useStyles();
    const [checked, setChecked] = useState(true)
    const [flightStatusChecked, setFlightStatusChecked] = useState(true)

    useEffect(() => {
        ReactGA.pageview('/concessions');
    }, [])

    const handeTimeFrame = () => {
        setChecked(!checked)
    }

    const handleFlightStatus = () => {
        setFlightStatusChecked(!flightStatusChecked)
    }

    return (
        <div className={classes.container}>
            <Grid component = {Paper} container className={classes.searchContainer}>
                <Grid className={classes.gridItems} item xs = {12} sm = {3}>
                    <Typography variant="body2">Departures</Typography>
                    <Switch checked={flightStatusChecked} onChange = {handleFlightStatus}/>
                    <Typography variant="body2">Arrivals</Typography>
                </Grid>
                <Grid className={classes.gridItems} item xs = {12} sm = {3}>
                    <Typography variant="body2">Real Time</Typography>
                    <Switch checked={checked} onChange = {handeTimeFrame}/>
                    <Typography variant="body2">Time Span</Typography>
                </Grid>
                <Grid className={classes.gridItems} item xs = {12} sm = {6}>
                    <SelectDates label = "From" hideRefresh/>
                    <SelectDates label = "To" hideRefresh/>
                </Grid>
            </Grid>
            <Paper className={classes.paper}>
                <Typography className={classes.airlines} variant="body2">Amount of Seats: </Typography>
                <Typography className={classes.airlines} variant="body2" sx = {{fontWeight: 'bold', color: purple}}>Allegiant: 343</Typography>
                <Typography className={classes.airlines} variant="body2" sx = {{fontWeight: 'bold', color: green}}>Frontier: 234</Typography>
                <Typography className={classes.airlines} variant="body2" sx = {{fontWeight: 'bold', color: UnDelayLogoBlue}}>American: 645</Typography>
                <Typography className={classes.airlines} variant="body2" sx = {{fontWeight: 'bold', color: red}}>Delta: 687</Typography>
                <Typography className={classes.airlines} variant="body2" sx = {{fontWeight: 'bold', color: darkerBlue}}>United: 527</Typography>
                <Typography className={classes.airlines} variant="body2" sx = {{fontWeight: 'bold', color: "orange"}}>Southwest: 476</Typography>
            </Paper>
            <ConcessionMap/>
        </div>
    );
}

const mapStateToProps = (state) =>{
    return{
      airport: state.airportReducer.airport
    }
  }
  
export default connect(mapStateToProps)(ConcessionsTab)