import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Button from '@material-ui/core/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { alpha, styled } from '@mui/material/styles';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { convertToLocal } from 'utils/timeConversions';

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      fontSize: 13,
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

const timeFrames = [
  {
    title: "Last 24 hours",
    timeFrame: 86400
  },
  {
    title: "Last Week",
    timeFrame: 86400 * 7
  },
  {
    title: "Last Month",
    timeFrame: 86400 * 30
  },
  {
    title: "Last Year",
    timeFrame: 86400 * 365
  },
  {
    title: "All Time"
  }
]

const ChartDateSelectionButton = (props) => {
  const { flightDate, setTimeFrame } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [timeFrameName, setTimeFrameName] = useState("All Time");
  const open = Boolean(anchorEl);
  
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        id="demo-customized-button"
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="outlined"
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
        color="primary"
      >
        {timeFrameName}
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {timeFrames.map((frame, i) => {
          return (
            <MenuItem 
              key = {i} 
              disableRipple
              onClick={() => { 
                handleClose(); 
                setTimeFrameName(frame.title);
                setTimeFrame(frame?.timeFrame)
              }}
            >
              {frame.title}
              <br/>
              {frame?.timeFrame ? `${convertToLocal(flightDate.getTime()/1000 - frame.timeFrame)} - ${convertToLocal(flightDate.getTime()/1000)}`: null}
            </MenuItem>
          )
        })}
      </StyledMenu>
    </div>
  );
}

const mapStateToProps = (state) => {
  return{
    flightDate: state.filterFieldsBarReducer.flightDate
  }
}

export default connect(mapStateToProps)(ChartDateSelectionButton)