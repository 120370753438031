import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { countParkingEvents } from "api/endpoints/parkingQueue";
import SelectDates from "components/SelectDates/SelectDates";
import TimeFrameSelection from 'components/TimeFrameSelection/TimeFrameSelection';
import WordCountBarGraph from 'components/WordCountBarGraph/WordCountBarGraph';
import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { connect } from 'react-redux';

const useStyles = makeStyles({
    container:{
        padding: 15,
        height: 600
    },
    gridContainer:{
        marginBottom: 20
    },
    gridItems:{
        paddingLeft: 20,
        paddingRight: 20,
        paddingTop: 20,
        display: "flex",
        alignItems: "flex-end",
        justifyContent: "center"
    },
    airport:{
        fontSize: 13
    }
});

const sampleData = [
    {
        "count": 48,
        "name": "Hyatt Hotel"
    },
    {
        "count": 73,
        "name": "Mariott Hotel"
    },
    {
        "count": 4,
        "name": "Tulsa Airport Hotel"
    },
    {
        "count": 54,
        "name": "Holiday Inn"
    },
    {
        "count": 11,
        "name": "Double Tree"
    },
    {
        "count": 3,
        "name": "Courtyard By Mariott"
    }
]


const ShuttleTrackingBarGraph = (props) => {
    const { flightDate } = props;
    const classes = useStyles();
    const [timeFrame, setTimeFrame] = useState("Last 24 hours")
    const [eventCounts, setEventCounts] = useState([])

    // useEffect(() => {
    //     let active = true;
    //     const loadEvents = async() => {
    //         const count = await countParkingEvents(flightDate.getTime()/1000, timeFrame);
    //         if (!active) { return }
    //         console.log(count.data.data)
    //         setEventCounts(count.data.data)
    //     }
    //     loadEvents()
    //     return () => { active = false }
    // }, [timeFrame])

    return (
        <Paper className={classes.container}>
            <Grid container className={classes.timeFrameContainer}> 
                <Grid item xs = {12} sm = {6}>
                    <Typography variant="h6">Shuttle Count</Typography>
                </Grid>
                <Grid container className={classes.selectionContainer}>
                    <Grid item xs = {12} sm = {6} 
                        sx={{
                            justifyContent: { xs: 'center', sm: 'flex-end'},
                            display: "flex",
                            marginBottom: {xs: 3, sm: 0},
                            marginTop: {xs: 3, sm: 0}
                        }}
                    >
                        <SelectDates/>
                    </Grid>
                    <Grid item  xs = {12} sm = {6}
                        sx={{
                            justifyContent: { xs: 'center', sm: 'flex-end'},
                            display: "flex"
                        }}
                    >
                        <TimeFrameSelection 
                            timeFrame = {timeFrame}
                            setTimeFrame = {setTimeFrame}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Box sx={{height: { xs: 450, sm: 500}}}>
                <WordCountBarGraph data = {sampleData}/>
            </Box>
        </Paper>
    );
}

const mapStateToProps = (state) => {
    return{
        flightDate: state.filterFieldsBarReducer.flightDate,
    }
}
  
export default connect(mapStateToProps)(ShuttleTrackingBarGraph)