import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@mui/material/LinearProgress';
import Icon from 'assets/img/transparent.png';
import React from 'react';

const useStyles = makeStyles(theme => ({
  spinner: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: "center",
    flexDirection: "column",
    height: "100%"
  },
  avatar:{
    height: 100,
    width: 100
  },
  linearProgress: {
    width: 200
  }
}));

const LoadingScreen = () => {
  const classes = useStyles();

  return (
    <div className={classes.spinner}>
        <Avatar className={classes.avatar} src={Icon}/>
        <LinearProgress className={classes.linearProgress}/>
    </div>
  );
}
export default LoadingScreen;