const airportReducer = (state = {
    airport: {},
    airports: []
}, action) => {
    switch (action.type){
        case "updateAirport":
            return{
                ...state,
                airport: action.airport
            };
        case "updateAirports":
            return{
                ...state,
                airports: action.airports
            };
        default:
            return state
        }
}
  
export default airportReducer;