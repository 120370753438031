const flightDisruptionReducer = (state = {
    disruption: {
        "phrase": "engine issues",
        "avgDelayTime": "15-45",
        "description": "",
        "imgUrl": "https://firebasestorage.googleapis.com/v0/b/undelay-aaeb6.appspot.com/o/flightDisruptions%2Fengine%20failure.jpeg?alt=media&token=33f54a12-cf75-4b4a-bd06-38015af47c75"
    }
}, action) => {
    switch (action.type){
        case "updateFlightDisruption":
            return{
                ...state,
                disruption: action.disruption
            };
        default:
            return state
        }
}
  
export default flightDisruptionReducer;