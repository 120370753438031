import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import ConcessionMapDrawer from "../ConcessionMapDrawer/ConcessionMapDrawer";
import ImageMarker from 'react-image-marker';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import React, { useMemo } from 'react';
import { green } from 'styles/colors';
import AirplanemodeActiveIcon from '@mui/icons-material/AirplanemodeActive';

const useStyles = makeStyles({
    img:{
        width: "100%"
    },
    container:{
        position: "relative",
        marginBottom: 20
    },
    icon:{
        color: green, 
        transform: "scale(1.8)"
    },
    planeIcon:{
        color: "grey", 
        transform: "scale(1.2)"
    }
});

export const ConcessionMap = (props) => {
    const classes = useStyles();
    const { airport, showAircrafts } = props;
    const markers = useMemo(() => {
        if(showAircrafts){
            return [
                { top: 22, left: 31 },
                { top: 51, left: 63 },
                { top: 5, left: 63 },
                { top: 10, left: 73 },
                { top: 25, left: 83 },
                { top: 55, left: 78 },
                { top: 60, left: 88 },
            ]
        }else{
            return [
                { top: 6, left: 46 },
                { top: 41, left: 76 },
            ]
        }
    }, [showAircrafts])

    const LocationMarker = () => {
        return (
            <FmdGoodIcon className={classes.icon} />
        );
    };

    const PlaneMarker = () => {
        return (
            <AirplanemodeActiveIcon className={classes.planeIcon} />
        );
    };

    return (
        <div className={classes.container}>
            <ConcessionMapDrawer/>
            <img 
                className={classes.img}
                src={require(`../../../src/assets/img/airportMaps/${airport.code}/terminalMap.jpg`)}
            />
            {/* <ImageMarker
                className = {classes.img}
                src={require(`../../../src/assets/img/airportMaps/${airport.code}/terminalMap.jpg`)}
                markers={markers}
                markerComponent={showAircrafts ? PlaneMarker : LocationMarker}
            /> */}
        </div>
    );
}

const mapStateToProps = (state) =>{
    return{
        airport: state.airportReducer.airport,
        showAircrafts: state.concessionsReducer.showAircrafts
    }
  }
  
export default connect(mapStateToProps)(ConcessionMap)