export const toggleAdjacentGateConflicts = () => {
    return {
        type: "toggleAdjacentGateConflicts"
    }
}

export const toggleOverlappingGateConflicts = () => {
    return {
        type: "toggleOverlappingGateConflicts"
    }
}

export const toggleGateTimeConflicts = () => {
    return {
        type: "toggleGateTimeConflicts"
    }
}

export const toggleIsMisassignedGateNames = () => {
    return {
        type: "toggleIsMisassignedGateNames"
    }
}

export const toggleGateChangeSuggestionsOnly = () => {
    return {
        type: "toggleGateChangeSuggestionsOnly"
    }
}

export const toggleOnlyArrivals = () => {
    return {
        type: "toggleOnlyArrivals"
    }
}

export const toggleOnlyDepartures = () => {
    return {
        type: "toggleOnlyDepartures"
    }
}