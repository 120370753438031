import { makeStyles } from '@material-ui/core/styles';
import TimeFrameContainer from 'components/TimeFrameContainer/TimeFrameContainer';
import Paper from '@mui/material/Paper';
import { countTotalPayments } from "api/endpoints/payments";
import {
  Chart, Filler, LinearScale, LineController,
  LineElement, PointElement, TimeScale,
  Tooltip
} from 'chart.js';
import 'chartjs-adapter-moment';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { UnDelayLogoBlue } from 'styles/colors';
import { formatForUSCurrency } from "utils/numberFormatting";

const useStyles = makeStyles(theme => ({
  paper: {
    padding: 10
  },
  timeFrameContainer:{
    display: "flex", 
    justifyContent: "space-between",
    [theme.breakpoints.down('xs')]: {
      textAlign: "center"
    }
  },
  selectionContainer:{
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
    justifyContent: "flex-end",
    [theme.breakpoints.down('xs')]: {
      justifyContent: "center",
      paddingTop: 10,
      paddingBottom: 10
    }
  }
}));

const convertToMonth = (timestamp) => {
  return new Date(timestamp * 1000).toLocaleString([], {
    month: '2-digit', 
    year: '2-digit', 
    day: '2-digit',
  })
}

const convertToDay = (timestamp) => {
  return new Date(timestamp * 1000).toLocaleString([], {
    month: '2-digit', 
    day: '2-digit', 
  })
}

const convertToHour = (timestamp) => {
  return new Date(timestamp * 1000).toLocaleString([], {
    hour: '2-digit', 
    minute: '2-digit',
    day: '2-digit', 
  })
}

const ParkingReportRevenueGraph = (props) => {
  const { flightDate } = props;
  const classes = useStyles();
  const canvas = useRef(null);
  const [timeFrame, setTimeFrame] = useState("Last 24 hours")
  const [labels, setLabels] = useState(null)
  const [datasetData, setDatasetData] = useState(null)

  useEffect(() => {
    Chart.register(LineController, LineElement, Filler, PointElement, LinearScale, TimeScale, Tooltip);
  }, [])

  const handleTaxiRevenue = async() => {
    try{
      const count = await countTotalPayments(timeFrame, flightDate.getTime()/1000);
      setLabels(Object.keys(count.data.data[0]));
      setDatasetData(Object.values(count.data.data[0]));
    }catch(e){
      console.log(e)
    }
  }

  useEffect(() => {
    handleTaxiRevenue()
  }, [flightDate, timeFrame]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const ctx = canvas.current;
    let chart = new Chart(ctx, {});
    if (labels && datasetData){
      const getDateOfLabels = labels.map((label) => {
        if(timeFrame === "Last 24 hours"){
          return convertToHour(label)
        }else if(timeFrame === "Last Week"){
          return convertToDay(label)
        }else if(timeFrame === "Last Month"){
          return convertToDay(label)
        }else if(timeFrame === "Last Year"){
          return convertToMonth(label)
        }
        return convertToHour(label)
      })
      if (chart !== undefined) {
        chart.destroy();
      }
      chart = new Chart(ctx, {
        type: 'line',
        data: {
          labels: getDateOfLabels,
          datasets: [
            {
              data: datasetData,
              borderColor: UnDelayLogoBlue,
              fill: false,
              backgroundColor: UnDelayLogoBlue,
              borderWidth: 2,
              tension: 0,
              pointRadius: 0,
              pointHoverRadius: 3,
              pointBackgroundColor: UnDelayLogoBlue
            }
          ],
        },
        options: {
          layout: {
            padding: 20,
          },
          scales: {
            y: {
              grid: {
                drawBorder: false,
                beginAtZero: true,
              },
              ticks: {
                maxTicksLimit: 5,
                callback: (value) => formatForUSCurrency(value),
              },
            },
          },
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              callbacks: {
                title: () => false, // Disable tooltip title
                label: (context) => formatForUSCurrency(context.parsed.y),
              },
            },
          },
          interaction: {
            intersect: false,
            mode: 'nearest',
          },
          maintainAspectRatio: false,
          resizeDelay: 200,
        },
        plugins: [{
          id: 'htmlLegend'
        }],
      });
    }
    return () => chart.destroy();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [labels, datasetData]);

  return (
    <Paper className={classes.paper}>
      <TimeFrameContainer title = "Revenue Graph" timeFrame = {timeFrame} setTimeFrame = {setTimeFrame}/>
      {labels && datasetData ?
        <div>
          <canvas ref={canvas} width={595} height={248}></canvas>
        </div>
      : null}
    </Paper>
  );
}

const mapStateToProps = (state) => {
  return{
    flightDate: state.filterFieldsBarReducer.flightDate
  }
}

export default connect(mapStateToProps)(ParkingReportRevenueGraph);
