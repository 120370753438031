import React from 'react';
import { Grid, Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import Chart from 'react-apexcharts';
import { lightPurple, lightBlue, lightPink, UnDelayLogoBlue } from 'styles/colors';

const chartData = {
    height: 480,
    type: 'bar',
    options: {
        chart: {
            id: 'bar-chart',
            type: 'bar',
            stacked: true,
            toolbar: {
                show: true
            },
            zoom: {
                enabled: true
            }
        },
        responsive: [
            {
                breakpoint: 480,
                options: {
                    legend: {
                        position: 'bottom',
                        offsetX: -10,
                        offsetY: 0
                    }
                }
            }
        ],
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '50%'
            }
        },
        xaxis: {
            type: 'category',
            categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
        },
        legend: {
            show: true,
            fontSize: '14px',
            fontFamily: `'Roboto', sans-serif`,
            position: 'bottom',
            offsetX: 20,
            labels: {
                useSeriesColors: false
            },
            markers: {
                width: 16,
                height: 16,
                radius: 5
            },
            itemMargin: {
                horizontal: 15,
                vertical: 8
            }
        },
        fill: {
            type: 'solid'
        },
        dataLabels: {
            enabled: false
        },
        grid: {
            show: true
        }
    },
    series: [
        {
            name: 'Delta',
            data: [35, 125, 35, 35, 35, 80, 35, 20, 35, 45, 15, 75],
            color: lightBlue
        },
        {
            name: 'Southwest',
            data: [35, 15, 15, 35, 65, 40, 80, 25, 15, 85, 25, 75],
            color: UnDelayLogoBlue
        },
        {
            name: 'Breeze',
            data: [35, 145, 35, 35, 20, 105, 100, 10, 65, 45, 30, 10],
            color: lightPurple
        },
        {
            name: 'Allegiant',
            data: [0, 0, 75, 0, 0, 115, 0, 0, 0, 0, 150, 0],
            color: lightPink
        }
    ]
};

const PassengerRevenuePerAirline = () => {
  return (
    <Paper style = {{padding: 10}}>
      <Grid style = {{padding: 10}} container alignItems="center" justifyContent="space-between">
        <div>
          <Typography variant="h6">Passenger Revenue Per Airline</Typography>
        </div>
      </Grid>
      <Chart {...chartData} />
    </Paper>
  );
};

export default PassengerRevenuePerAirline;
