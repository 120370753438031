import { makeStyles } from '@material-ui/core/styles';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { getProductPaymentLogs } from "api/endpoints/payments";
import TimeFrameContainer from 'components/TimeFrameContainer/TimeFrameContainer';
import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { convertToLocal } from 'utils/timeConversions';

const useStyles = makeStyles({
  paper: {
    padding: 10
  }
});

const ProductPaymentLogsTable = ({ flightDate }) => {
  const classes = useStyles();
  const [paymentLogs, setPaymentLogs] = useState([])
  const [timeFrame, setTimeFrame] = useState("Last Month")
  const [timeFrameValue, setTimeFrameValue] = useState(2592000)


  const getLogs = async() => {
    try{
      const response = await getProductPaymentLogs(flightDate.getTime()/1000 - timeFrameValue)
      setPaymentLogs(response.data.data)
    }catch(e){
      console.log(e)
    }
  }

  useEffect(() => {
    getLogs()
  }, [flightDate, timeFrameValue])


  const showDownloadReportButton = useMemo(() => {
    if(timeFrame && timeFrame !== "Last Year"){
      return true
    }
    return false
  }, [timeFrame])

  return (
    <Paper className={classes.paper}>
      <TimeFrameContainer 
        title = "Product Payment Logs" 
        timeFrame = {timeFrame} 
        setTimeFrame = {setTimeFrame} 
        downloadReportsEnabled = {showDownloadReportButton}
        downloadData = {paymentLogs}
        setTimeFrameValue = {setTimeFrameValue}
      />
      <TableContainer style = {{height: 450}}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Time</TableCell>
            <TableCell align="right">Product Name</TableCell>
            <TableCell align="right">Airport Payout</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {paymentLogs.map((row, i) => (
            <TableRow
              key={i}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {convertToLocal(row.timestamp)}
              </TableCell>
              <TableCell align="right">{row.productName}</TableCell>
              <TableCell align="right">${row.airportPayout}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </Paper>
  );
}

const mapStateToProps = (state) => {
  return{
    flightDate: state.filterFieldsBarReducer.flightDate,
  }
}

export default connect(mapStateToProps)(ProductPaymentLogsTable)