import React from 'react';
import {
    Bar,
    BarChart,
    CartesianGrid,
    Legend, Tooltip,
    XAxis,
    YAxis,
    ResponsiveContainer
} from 'recharts';

const WordCountBarGraph = (props) => {
  const { data } = props;

  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        width={350}
        height={window.innerHeight > 800 ? 700 : 500}
        data={data}
        margin={{
          top: 30,
          right: 40,
          left: 30,
          bottom: 30,
        }}
        barSize={20}
      >
        <XAxis dataKey="name" scale="point" padding={{ left: 10, right: 10 }} />
        <YAxis />
        <Tooltip />
        <Legend verticalAlign="top" height={36}/>
        <CartesianGrid strokeDasharray="3 3" />
        <Bar dataKey="count" fill="#3f51b5" background={{ fill: '#eee' }} />
      </BarChart>
    </ResponsiveContainer>
  );
}

export default WordCountBarGraph;