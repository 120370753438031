const filterFieldsBarReducer = (state = {
    flightDate: new Date(),
    airline: null,
    flightNumber: null,
    airlines: [],
    cargoCarrier: "Amazon"
}, action) => {
    switch (action.type){
        case "updateFlightDate":
            return{
                ...state,
                flightDate: action.flightDate
            };
        case "updateAirline":
            return{
                ...state,
                airline: action.airline
            };
        case "updateFlightNumber":
            return{
                ...state,
                flightNumber: action.flightNumber
            };
        case "updateAirlines":
            return{
                ...state,
                airlines: action.airlines
            };
        case "updateCargoCarrier":
            return{
                ...state,
                cargoCarrier: action.cargoCarrier
            };
        default:
            return state
        }
}
  
export default filterFieldsBarReducer;