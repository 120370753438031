import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@mui/material/Box';
import { countParkingEvents, getTaxiDrivers } from "api/endpoints/parkingQueue";
import TimeFrameContainer from 'components/TimeFrameContainer/TimeFrameContainer';
import WordCountBarGraph from 'components/WordCountBarGraph/WordCountBarGraph';
import { connect } from 'react-redux';
import React, { useEffect, useState } from 'react';

const useStyles = makeStyles((theme) => ({
    container:{
        padding: 15,
        height: 600,
        [theme.breakpoints.down('xs')]: {
          height: 650,
        },
    },
    gridContainer:{
        marginBottom: 20
    },
    gridItems:{
        paddingLeft: 20,
        paddingRight: 20,
        paddingTop: 20,
        display: "flex",
        alignItems: "flex-end",
        justifyContent: "center"
    },
    airport:{
        fontSize: 13
    }
}));

const ParkingRerpotsEventsBarGraph = (props) => {
    const { flightDate } = props;
    const classes = useStyles();
    const [timeFrame, setTimeFrame] = useState("Last 24 hours")
    const [eventCounts, setEventCounts] = useState([])
    const [taxiDrivers, setTaxiDrivers] = useState([])

    useEffect(() => {
        let active = true;
        const loadEvents = async() => {
            const count = await countParkingEvents(flightDate.getTime()/1000, timeFrame);
            if (!active) { return }
            setEventCounts(count.data.data)
        }
        loadEvents()
        return () => { active = false }
    }, [timeFrame])

    const getAllTaxiDrivers = async() => {
        try{
            const allTaxiDrivers = true;
            const response = await getTaxiDrivers(allTaxiDrivers)
            setTaxiDrivers(response.data.data)
        }catch(e){
            console.log(e)
        }
    }

    useEffect(() => {
        getAllTaxiDrivers()
    }, [])

    return (
        <Paper className={classes.container}>
            <TimeFrameContainer 
                title = "Parking Events Graph" 
                downloadButtonText = "Download Taxi List"
                timeFrame = {timeFrame} 
                setTimeFrame = {setTimeFrame}
                downloadReportsEnabled = {true}
                downloadData = {taxiDrivers}
            />
            <Box sx={{height: { xs: 450, sm: 500}}}>
                <WordCountBarGraph data = {eventCounts}/>
            </Box>
        </Paper>
    );
}

const mapStateToProps = (state) => {
    return{
        flightDate: state.filterFieldsBarReducer.flightDate,
    }
}
  
export default connect(mapStateToProps)(ParkingRerpotsEventsBarGraph)