import { makeStyles } from '@material-ui/core/styles';
import Paper from '@mui/material/Paper';
import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import Grid from '@mui/material/Grid';
import GateTable from "components/GateTable/GateTable";
import GateManagementSwitches from "components/GateManagementSwitches/GateManagementSwitches";
import GateManagementLegend from "components/GateManagementLegend/GateManagementLegend";
import GateChangeSuggestionsTable from "components/GateChangeSuggestionsTable/GateChangeSuggestionsTable";

const useStyles = makeStyles({
    container:{
        margin: 15
    },
    gridContainer:{
        marginBottom: 20
    }
});

const GateManagementTab = () => {
    const classes = useStyles();

    useEffect(() => {
        ReactGA.pageview('/gate-management');
    }, [])

    return (
        <div className={classes.container}>
            <Grid container className={classes.gridContainer}>
                <Grid item xs = {12}>
                    <GateManagementSwitches/>
                    <GateManagementLegend/>
                    <Paper>
                        <GateTable/>
                    </Paper>
                </Grid>
            </Grid>

            <Grid container className={classes.gridContainer}>
                <Grid item xs = {12}>
                    <Paper>
                        <GateChangeSuggestionsTable/>
                    </Paper>
                </Grid>
            </Grid>

            
        </div>
    );
}

export default GateManagementTab;