import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Link from '@material-ui/core/Link';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { checkPhoneNumber, signin } from 'api/endpoints/auth';
import { auth } from "api/firebase";
import Image from 'assets/img/plane_1_1920.jpg';
import Icon from 'assets/img/transparent.png';
import { RecaptchaVerifier, signInWithEmailAndPassword, signInWithPhoneNumber, signOut } from "firebase/auth";
import MuiPhoneNumber from 'material-ui-phone-number';
import React, { useState } from 'react';
import ReactGA from 'react-ga';
import { connect } from 'react-redux';
import { updateUser } from 'store/user/actions';
import Copyright from './Copyright';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: `url(${Image})`,
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'dark' ? theme.palette.grey[900] : theme.palette.grey[50],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(4, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1)
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  loadingContainer:{
    justifyContent: "center",
    display: "flex",
    margin: 20
  },
  signUpContainer: {
    display: "flex",
    justifyContent: "space-between"
  }
}));

const SignIn = (props) => {
  const classes = useStyles();
  const { changeAuth, setRecapthaResponse, updateUser } = props;

  const [phoneNumber, setPhoneNumber] = useState(null)
  const [values, setValues] = useState({
    email: "",
    password: "",
    showPassword: false,
  });
  const [loading, setLoading] = useState(false);
  const [loginMethod, setLoginMethod] = useState("phoneNumber");

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const setUpRecaptha = async() => {
    const recaptchaVerifier = new RecaptchaVerifier("recaptcha-container", {}, auth);
    recaptchaVerifier.render();
    return signInWithPhoneNumber(auth, phoneNumber.replace(" ", ""), recaptchaVerifier);
  }

  const sendOtp = async() => {
    const response = await setUpRecaptha();
    setRecapthaResponse(response)
  };

  const signIn = async(event) => {
    setLoading(true)
    event.preventDefault();
    try{
      if(phoneNumber){
        const userExists = await checkPhoneNumber(phoneNumber)
        if(userExists.data.data.length > 0){
          await sendOtp()
          changeAuth("VerifyUser")
        }else{
          alert("User does not exist")
          changeAuth("SignUp")
        }
      }else{
        await signInWithEmailAndPassword(auth, values.email, values.password)
        const getUserInfo = await signin();
        updateUser(getUserInfo.data.data[0])
      }
      ReactGA.event({
        category: 'User',
        action: 'Signed In'
      });
      setLoading(false)
    }catch(e){
      await signOut(auth);
      console.log(e);
      setLoading(false)
      ReactGA.exception({
        description: e?.message,
        fatal: true
      });
      alert(e?.message);
    }
  }

  return (
    <div className={classes.paper}>
      <Avatar className={classes.avatar} src={Icon}/>
      <Typography component="h1" variant="h5">
        Sign In
      </Typography>
      <form className={classes.form} onSubmit={signIn}>
        {loginMethod === "email" ? 
          <>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              onChange={handleChange('email')}
            />
            <OutlinedInput
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="password"
              placeholder="Password *"
              type={values.showPassword ? 'text' : 'password'}
              value={values.password}
              onChange={handleChange('password')}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {values.showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </>
          :
          <MuiPhoneNumber 
            variant="outlined" 
            required 
            fullWidth 
            label="Phone Number" 
            margin="normal" 
            defaultCountry={'us'} 
            onChange={(value) => setPhoneNumber(value)}
          />
        }

        <div id="recaptcha-container"></div>  
        
        {!loading ? 
          <Button
            fullWidth
            variant="outlined"
            color="primary"
            className={classes.submit}
            type="submit"
          >
            Sign In
          </Button>
        : 
          <div className={classes.loadingContainer}> <CircularProgress/> </div> 
        }

        <div className={classes.signUpContainer}> 
          <Link href="#" variant="body2" onClick={() => changeAuth("SignUp")}>
            Sign Up?
          </Link>
          <Link href="#" variant="body2" onClick={() => setLoginMethod(loginMethod === "phoneNumber" ? "email" : "phoneNumber")}>
            Sign In With {loginMethod === "phoneNumber" ? "Email" : "Phone Number"}?
          </Link>
          {loginMethod === "email" ?
            <Link href="#" variant="body2" onClick={() => changeAuth("ForgotPassword")}>
              Forgot password?
            </Link>
          : null}
        </div>
        <Box mt={5}>
          <Copyright />
        </Box>
      </form>
    </div>
  );
}

const mapDispatchToProps = {
  updateUser
}

export default connect(null, mapDispatchToProps)(SignIn)