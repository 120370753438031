import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@mui/material/LinearProgress';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import axios from "axios";
import parameters from 'api/parameters';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { convertToLocal } from 'utils/timeConversions';

const useStyles = makeStyles({
  loadingContainer:{
    justifyContent: "center",
    display: "flex",
    padding: 10,
    alignSelf: "center",
    margin: "0 auto"
  },
  container:{
    overflow: "auto",
    height: window.innerHeight > 800 ? 700 : 500,
    display: "flex",
    alignSelf: "center"
  },
  containerWihtinFlightRow:{
    overflow: "auto",
    display: "flex",
    alignSelf: "center"
  },
  noResultsContainer:{
    display: "flex",
    alignItem: "center",
    flexDirection: "column"
  },
  noMessageResults:{
    fontSize: 12,
    padding: 10
  },
  toolTipTitles:{
    paddingTop: 5
  }
});

const ADSBModeSTable = ({ airline, flightNumber, airport, flightDate, flightRowTimestamp, icaoAddress }) => {
  const classes = useStyles();
  const [adsbRows, setAdsbRows] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [endOfFlightDetails, setEndOfFlightDetails] = useState(null)
  const [lastFlightDetailsTimestamp, setLastFlightDetailsTimestamp] = useState(null)
  const [position, setPosition] = useState("reset");
  const tableRef = useRef()
  const tableContainer = useRef()
  const axiosSource = axios.CancelToken.source();
  
  const listenScrollEvent = (() => {
    if(!flightRowTimestamp && !isLoading && (tableContainer?.current.scrollHeight - tableContainer?.current.scrollTop - 100) < tableContainer?.current.offsetHeight && !endOfFlightDetails){
      setIsLoading(true)
      setPosition("append")
      getADSBModeSFeed(lastFlightDetailsTimestamp, "append")
    }
  })

  const getADSBModeSFeed = async(timestamp, position) => {
    try {
      let adsBModeSFeed = await parameters.post("/getADSBFeed", { 
        timestamp: timestamp,
        airport: airport.code,
        airline: airline,
        flightNumber: flightNumber,
        icaoAddress: icaoAddress
      }, {cancelToken: axiosSource.token});

      //retrieve last 5 results under the flight stats tab
      const result = flightRowTimestamp ? adsBModeSFeed.data.data.slice(0,5) : adsBModeSFeed.data.data;
      if(result.length < 1){
        setEndOfFlightDetails(true)
        setIsLoading(false)
        return
      }
      if(position === "append"){
        setAdsbRows(adsbRows.concat(result));
      }else if (position === "reset"){
        setAdsbRows(result)
      }
      setLastFlightDetailsTimestamp(result[result.length - 1].timestamp - 1)
      setIsLoading(false)
    } catch (error) {
      console.log("error", error)
      setIsLoading(false)
    }
  }

  const linearBarWidth = useMemo(() => {
    return tableRef?.current?.clientWidth
  }, [isLoading]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setIsLoading(true)
    setAdsbRows([])
    setEndOfFlightDetails(false)
    setPosition("reset")
    getADSBModeSFeed(flightRowTimestamp ? flightRowTimestamp : flightDate.getTime()/1000, "reset")
  }, [flightDate, airline, airport, flightNumber])  // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    return () => {
      axiosSource.cancel()
    };
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={!flightRowTimestamp ? classes.container : classes.containerWihtinFlightRow}>
      {adsbRows.length > 0 ? 
        <TableContainer ref = {tableContainer} onScroll={listenScrollEvent}>
          <Table style = {{"whiteSpace": "nowrap"}} stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell style={{ whiteSpace: "nowrap" }}>Date Retrieved</TableCell>
                <TableCell align="right">Flight or Tail Number</TableCell>
                <Tooltip title = "unique aircraft identifier">
                  <TableCell align="right">ICAO Address</TableCell>
                </Tooltip>
                <TableCell align="right">Latitude</TableCell>
                <TableCell align="right">Longitude</TableCell>
                <Tooltip
                  title={
                    <>
                      <Typography color="inherit">1. All Call Reply</Typography>
                      identifies which aircrafts are in the vicinity
                      <Typography color="inherit" className={classes.toolTipTitles}>2. ADS-B message</Typography>
                      detects position, altitiude and speed of plane
                      <Typography color="inherit" className={classes.toolTipTitles}>3. Identity Reply</Typography>
                      delivers identity code to determine an aircrafts flight infomration region for idenntification
                      <Typography color="inherit" className={classes.toolTipTitles}>4. Altitude Reply: Surveillance</Typography>
                      a part of the ACAS system that deploys a three step process of detection, surveillance and coordination
                      <Typography color="inherit" className={classes.toolTipTitles}>5. Altitude Reply: Comm-B</Typography>
                      the protocol supports over 250 messages that help identify altitiude, plane identity and meterological information
                      <Typography color="inherit" className={classes.toolTipTitles}>6. Short Air-Air Surveillance</Typography>
                      once an aircraft is withing 10,000ft, ACAS will initiate this interrogation to determine the distance 
                    </>
                  }
                >
                  <TableCell align="right">Message Type</TableCell>
                </Tooltip>
                <Tooltip
                  title={
                    <>
                      <Typography color="inherit">1. Aircraft Identification and Category</Typography>
                        callsign and plane category which includes
                        <br/>
                        - surface emergency vehicle
                        <br/>
                        - surface service vehicle
                        <br/>
                        - ground obstruction
                        <br/>
                        - glider, sailplane
                        <br/>
                        - lighter-than-air
                        <br/>
                        - parachutist, skydiver
                        <br/>
                        - ultralight, hang-glider, paraglider
                        <br/>
                        - unmanned aerial vehicle
                        <br/>
                        - space or transatmospheric vehicle
                        <br/>
                        - light (leess than 7000kg)
                        <br/>
                        - medium 1 (between 7000 kg and 34000 kg)
                        <br/>
                        - medium 2 (between 34000 kg to 136000 kg)
                        <br/>
                        - Heavy (larger than 136000 kg)
                        <br/>
                        - High performance (`{'>'}`5 g acceleration) and high speed (`{'>'}`400 kt)
                        <br/>
                        - Rotorcraft (helicoptor or autogiro)
                      <Typography color="inherit" className={classes.toolTipTitles}>2. Surface Position</Typography>
                        - determines speed of an aircraft when the plane is on the ground
                      <Typography color="inherit" className={classes.toolTipTitles}>3. Extended Squitter Aircraft Status</Typography>
                        - (1090ES TCAS RA) traffic alert and collision avoidance system
                      <Typography color="inherit" className={classes.toolTipTitles}>4. Aircraft Operational Status Message</Typography>
                        - provides ADS-B version, accuracy and integrity indicators
                    </>
                  }
                >
                  <TableCell align="right">Squitter Name</TableCell>
                </Tooltip>
                <TableCell align="right">Altitude</TableCell>
                <Tooltip title = "indicates the type of transponder and if a flight is either airborne or on the ground">
                  <TableCell align="right">Capability</TableCell>
                </Tooltip>
                <TableCell align="right">EW Direction</TableCell>
                <TableCell align="right">EW Velocity</TableCell>
                <TableCell align="right">NS Direction</TableCell>
                <TableCell align="right">NS Velocity</TableCell>
                <TableCell align="right">Flight Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody ref = {tableRef}>
              {adsbRows.map((row, id) => {
                return (
                  <TableRow role="checkbox" tabIndex={-1} key={id}>
                    <TableCell component="th" scope="row">
                      {convertToLocal(row.timestamp)}
                    </TableCell>
                    <TableCell align = "right">{row.aircraftIdentification ? row.aircraftIdentification : "---"}</TableCell>
                    <TableCell align = "right">{row.icaoAddress ? row.icaoAddress : "---"}</TableCell>
                    <TableCell align = "right">{row.latDecoded ? row.latDecoded : "---"}</TableCell>
                    <TableCell align = "right">{row.longDecoded ? row.longDecoded : "---"}</TableCell>
                    <TableCell align = "right">{row.messageType ? row.messageType : "---"}</TableCell>
                    <TableCell align = "right">{row.squitterName ? row.squitterName : "---"}</TableCell>
                    <TableCell align = "right">{row.altitude || row.altitude === 0 ? row.altitude : "---"} {row.altitudeUnits}</TableCell>
                    <TableCell align = "right">{row.capability ? row.capability : "---"}</TableCell>
                    <TableCell align = "right">{row.ewDirection ? (row.ewDirection === 0 ? `East to West` : "West to East") : "---"}</TableCell>
                    <TableCell align = "right">{row.ewVelocity ? row.ewVelocity : "---"}</TableCell>
                    <TableCell align = "right">{row.nsDirection ? (row.nsDirection === 0 ? `South to North` : "North to South") : "---"}</TableCell>
                    <TableCell align = "right">{row.nsVelocity ? row.nsVelocity : "---"}</TableCell>
                    <TableCell align = "right">{row.flightStatus ? row.flightStatus : "---"}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          {!flightRowTimestamp && position === "append" && isLoading ? <LinearProgress sx={{width: linearBarWidth ? linearBarWidth : 0}}/> : null}
        </TableContainer>
      : !isLoading && adsbRows.length < 1 ?
        <div className = {classes.noResultsContainer}>
          <p className = {classes.noMessageResults}>No results are shown for this selection.  Please select another date, airline, flight number or airport.</p>
        </div> 
      : null}
      {position === "reset" && isLoading ? <div className={classes.loadingContainer}> <CircularProgress/> </div> : null}
    </div>
  );
}

const mapStateToProps = (state) => {
  return{
    airport: state.airportReducer.airport,
    flightDate: state.filterFieldsBarReducer.flightDate,
    airline: state.filterFieldsBarReducer.airline,
    flightNumber: state.filterFieldsBarReducer.flightNumber,
  }
}

export default connect(mapStateToProps)(ADSBModeSTable)