import React, { useRef, useEffect } from 'react';
import { Typography } from '@mui/material';
import {
  Chart, 
  BarController, 
  BarElement, 
  LinearScale, 
  CategoryScale, 
  Tooltip, 
  Legend
} from 'chart.js';
import 'chartjs-adapter-moment';
import Paper from '@mui/material/Paper';
import { makeStyles } from '@material-ui/core/styles';

Chart.register(BarController, BarElement, LinearScale, CategoryScale, Tooltip, Legend);

const useStyles = makeStyles({
  paper: {
    padding: 10
  }
});

const chartData = {
  labels: ['Aircrafts Types'],
  datasets: [
    {
      label: 'Boeing',
      data: [131],
      backgroundColor: "#6366f1",
      hoverBackgroundColor: "#6366f1",
      barPercentage: 1,
      categoryPercentage: 1,
    },
    {
      label: 'Airbus',
      data: [100],
      backgroundColor: "#372fa3",
      hoverBackgroundColor: "#372fa3",
      barPercentage: 1,
      categoryPercentage: 1,
    },
    {
      label: 'McDonnell',
      data: [81],
      backgroundColor: "#38bcf7",
      hoverBackgroundColor: "#38bcf7",
      barPercentage: 1,
      categoryPercentage: 1,
    },
    {
      label: 'ATR',
      data: [65],
      backgroundColor: "#49de80",
      hoverBackgroundColor: "#49de80",
      barPercentage: 1,
      categoryPercentage: 1,
    },
    {
      label: 'Cessna',
      data: [72],
      backgroundColor: "rgb(17 175 76)",
      hoverBackgroundColor: "rgb(17 175 76)",
      barPercentage: 1,
      categoryPercentage: 1,
    },
  ],
};

const AircraftTypes = () => {
  const classes = useStyles();
  const canvas = useRef(null);
  const legend = useRef(null);

  useEffect(() => {
    const data = chartData;
    const reducer = (accumulator, currentValue) => accumulator + currentValue;
    const values = data.datasets.map(x => x.data.reduce(reducer));
    const max = values.reduce(reducer);
    const ctx = canvas.current;
    const chart = new Chart(ctx, {
      type: 'bar',
      data: data,
      options: {
        indexAxis: 'y',
        layout: {
          padding: {
            top: 12,
            bottom: 12
          },
        },
        scales: {
          x: {
            stacked: true,
            display: false,
            max: max,
          },
          y: {
            stacked: true,
            display: false,
          },
        },
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            callbacks: {
              title: () => false, // Disable tooltip title
              label: (context) => context.parsed.x,
            },
          },
        },
        interaction: {
          intersect: false,
          mode: 'nearest'
        },
        animation: {
          duration: 500,
        },
        maintainAspectRatio: false,
        resizeDelay: 200,
      },
      plugins: [{
        id: 'htmlLegend',
        afterUpdate(c, args, options) {
          //NEEDS TO Be Refactored ASAP
          const ul = legend.current;
          if (!ul) return;
          // Remove old legend items
          while (ul.firstChild) {
            ul.firstChild.remove();
          }
          // Reuse the built-in legendItems generator
          const items = c.options.plugins.legend.labels.generateLabels(c);
          items.forEach((item) => {
            const li = document.createElement('li');
            li.style.display = 'flex';
            li.style.justifyContent = 'space-between';
            li.style.alignItems = 'center';
            li.style.margin = '5px 0';
            const wrapper = document.createElement('div');
            wrapper.style.display = 'flex';
            wrapper.style.alignItems = 'center';
            const box = document.createElement('div');
            box.style.width = "10px";
            box.style.height =  "10px";
            box.style.marginRight = "5px";
            box.style.backgroundColor = item.fillStyle;
            const label = document.createElement('div');
            const value = document.createElement('div');
            const theValue = c.data.datasets[item.datasetIndex].data.reduce((a, b) => a + b, 0);
            const valueText = document.createTextNode(`${(theValue / max * 100).toFixed(1)}%`);
            const labelText = document.createTextNode(item.text);
            value.appendChild(valueText);
            label.appendChild(labelText);
            ul.appendChild(li);
            li.appendChild(wrapper);
            li.appendChild(value);
            wrapper.appendChild(box);
            wrapper.appendChild(label);
          });
        },
      }],
    });
    return () => chart.destroy();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Paper className={classes.paper}>
      <Typography variant="h6">Aircraft Types</Typography>
      <div style = {{padding: 10}}>
        <div>
          <canvas ref={canvas} width={595} height={48}></canvas>
        </div>
        <ul style = {{padding: 0}} ref={legend}></ul>
      </div>
    </Paper>
  );
}

export default AircraftTypes;
