import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { createBrowserHistory } from "history";
import Auth from "pages/Auth.js";
import Dashboard from "pages/Dashboard.js";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { Redirect, Route, Router, Switch } from "react-router-dom";
import { QueryClient, QueryClientProvider } from 'react-query'
import "./index.css";
import store from './store/store';

import ReactGA from 'react-ga';
ReactGA.initialize('UA-175444085-1', {
  gaOptions: { 
    siteSpeedSampleRate: 100
  }
});

if (window.performance) {
  // Gets the number of milliseconds since page load
  // (and rounds the result since the value must be an integer).
  const timeSincePageLoad = Math.round(performance.now());

  // Sends the timing hit to Google Analytics.
  ReactGA.timing({
      category: 'Page Load Time',
      variable: 'load',
      value: timeSincePageLoad, // in milliseconds
      label: window.location.pathname
  });
}

if (process.env.NODE_ENV === 'production') {
  console.log = () => {}
  console.error = () => {}
  console.debug = () => {}
  console.warn = () => {}
}

const hist = createBrowserHistory();

const queryClient = new QueryClient()

ReactDOM.render(
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <Router history={hist}>
          <Switch>
            <Route path="/auth" component={Auth} />
            <Route path="/dashboard" component={Dashboard} />
            <Redirect from="/" to="/auth" />
          </Switch>
      </Router>
    </QueryClientProvider>
  </Provider>,
  document.getElementById("root")
);