import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from "@mui/material/Typography";
import { getTaxiDrivers, textTaxiDriver } from 'api/endpoints/parkingQueue';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { connect } from 'react-redux';
import { formatForUSCurrency } from "utils/numberFormatting";
import { convertToLocal } from 'utils/timeConversions';

const useStyles = makeStyles({
    topContainer:{
        display: "flex",
        justifyContent: "flex-end",
        padding: 10
    },
    tableContainer:{
        height: 500
    },
    loadingContainer:{
        justifyContent: "center",
        display: "flex",
        margin: 20
    },
    taxiLoadingContainer:{
        justifyContent: "center",
        display: "flex"
    },
});

const TextTaxiButton = (props) => {
    const { phoneNumber, firstAndLastName } = props;
    const [showButton, setShowButton] = useState(true)
    if(showButton){
        return (
            <Button
                fullWidth
                variant="outlined"
                color="primary"
                onClick = {async() => {
                    setShowButton(false)
                    await textTaxiDriver(phoneNumber, firstAndLastName)
                }}
            >
                Request Pickup
            </Button>
        )
    }
    return (
        <div style = {{textAlign: "center"}}>
            <Typography variant="body2" color="red">
                Request Sent!
            </Typography>
        </div>
    )
}

const TaxisTable = ({ user }) => {
    const classes = useStyles();
    const [showTextAllTaxisButton, setShowTextAllTaxisButton] = useState(true)
    const [isTexingAllTaxi, setIsTextingAllTaxi] = useState(false)

    const { data: taxiDrivers, isFetching} = useQuery(
        ['taxiDrivers'],
        async () => {
            const response = await getTaxiDrivers()
            return response.data.data
        },
        {
          refetchInterval: 3000,
          refetchIntervalInBackground: true,
          cacheTime: 0
        },
    ) 

    const textAllTaxis = async() => {
        setIsTextingAllTaxi(true)
        try{
            for(const taxi in taxiDrivers){
                await textTaxiDriver(taxiDrivers[taxi].phoneNumber, taxiDrivers[taxi].firstAndLastName)
            }
            setShowTextAllTaxisButton(false)
            setIsTextingAllTaxi(false)
        }catch(e){
            console.log(e)
            setIsTextingAllTaxi(false)
        }
    }

    if(isFetching && !taxiDrivers?.length){
        return <div className={classes.loadingContainer}> <CircularProgress/> </div> 
    }

    return (
        <TableContainer className={classes.tableContainer}>
            <div className={classes.topContainer}>
                {isTexingAllTaxi ?
                    <div className={classes.taxiLoadingContainer}> <CircularProgress/> </div> 
                : showTextAllTaxisButton ? 
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={textAllTaxis}
                    >
                        Notify All Taxis To Come To Airport
                    </Button>
                : 
                    <div style = {{textAlign: "center"}}>
                        <Typography variant="body2" color="red">
                            Request Sent!
                        </Typography>
                    </div>
                }
            </div>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Taxi #</TableCell>
                        <TableCell align="right">Company Name</TableCell>
                        <TableCell align="right">First And Last Name</TableCell>
                        {!user?.guestServices && (<TableCell align="right">Account Balance</TableCell>)}
                        <TableCell align="right">Last Time Logged In</TableCell>
                        <TableCell align="right">Phone Number</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {taxiDrivers.map((taxi, i) => {
                        return(
                            <TableRow key = {i}>
                                <TableCell component="th" scope="row">{taxi.taxiNumber}</TableCell>
                                <TableCell align = "right">{taxi.companyName}</TableCell>
                                <TableCell align = "right">{taxi.firstAndLastName}</TableCell>
                                {!user?.guestServices && (<TableCell align = "right">{formatForUSCurrency(taxi.accountBalance)}</TableCell>)}
                                <TableCell scope="row">{convertToLocal(taxi.lastTimeLocationWasUpdated)}</TableCell>
                                <TableCell align = "right">{taxi.phoneNumber}</TableCell>
                                <TableCell align = "right">
                                    <TextTaxiButton
                                        phoneNumber = {taxi.phoneNumber}
                                        firstAndLastName = {taxi.firstAndLastName}
                                    />
                                </TableCell>
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

const mapStateToProps = (state) =>{
    return{
        user: state.userReducer.user
    }
}
  
export default connect(mapStateToProps)(TaxisTable)