import { alpha, makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React from 'react';
import ReactGA from 'react-ga';
import { connect } from 'react-redux';
import { updateAirline } from 'store/filterFieldsBar/actions';

const useStyles = makeStyles(theme => ({
  search: {
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    }
  },
  textField:{
    "& .MuiFormLabel-root": {
      transform: "translate(0px, 25px)",
      fontSize: 15
    }
  }
}));

export const AirlineSelector = (props) => {
  const classes = useStyles();
  const [focused, setFocused] = React.useState(false)
  const { updateAirline, airlines, airline } = props;

  const handleChange = (event, value) => {
    updateAirline(value);
    ReactGA.event({
      category: 'Airline Selection',
      action: value
    });
  };

  const onFocus = () => setFocused(true)
  const onBlur = () => setFocused(false)

  return (
    <Autocomplete
      freeSolo
      options={airlines}
      onChange={handleChange} 
      value = {airline ? airline : ""}
      style = {{margin: 0}}
      onFocus={onFocus}
      onBlur={onBlur}
      renderInput={params => (
        <TextField
          className={!focused && !airline?.length ? classes.textField : null}
          {...params}
          label="Select airline"
          variant="filled"
          InputProps={{ ...params.InputProps, className: classes.search }}
        />
      )}
    />
  );
}

const mapDispatchToProps = {
  updateAirline
}

const mapStateToProps = (state) =>{
  return{
    airline: state.filterFieldsBarReducer.airline,
    airlines: state.filterFieldsBarReducer.airlines
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AirlineSelector)
