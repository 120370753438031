import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@mui/material/LinearProgress';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import axios from "axios";
import CargoADSBRow from 'components/CargoADSBRow/CargoADSBRow';
import parameters from 'api/parameters';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { connect } from 'react-redux';

const useStyles = makeStyles({
  loadingContainer:{
    justifyContent: "center",
    display: "flex",
    padding: 10,
    alignSelf: "center",
    margin: "0 auto"
  },
  container:{
    overflow: "auto",
    height: window.innerHeight > 800 ? 700 : 500,
    display: "flex",
    alignSelf: "center",
    background: "white"
  },
  containerWihtinFlightRow:{
    overflow: "auto",
    display: "flex",
    alignSelf: "center",
    background: "white"
  },
  noResultsContainer:{
    display: "flex",
    alignItem: "center",
    flexDirection: "column"
  },
  noMessageResults:{
    fontSize: 12,
    padding: 10
  },
  toolTipTitles:{
    paddingTop: 5
  }
});

const CargoADSBTable = (props) => {
  const classes = useStyles();
  const [adsbRows, setAdsbRows] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [endOfFlightDetails, setEndOfFlightDetails] = useState(null)
  const [lastFlightDetailsTimestamp, setLastFlightDetailsTimestamp] = useState(null)
  const [position, setPosition] = useState("reset");
  const tableRef = useRef()
  const tableContainer = useRef()
  const axiosSource = axios.CancelToken.source();
  const { cargoCarrier, flightNumber, airport, flightDate, flightRowTimestamp } = props;
  
  const listenScrollEvent = (() => {
    if(!flightRowTimestamp && !isLoading && (tableContainer?.current.scrollHeight - tableContainer?.current.scrollTop - 100) < tableContainer?.current.offsetHeight && !endOfFlightDetails){
      setIsLoading(true)
      setPosition("append")
      getADSBModeSFeed(lastFlightDetailsTimestamp, "append")
    }
  })

  const getADSBModeSFeed = async(timestamp, position) => {
    try {
      let adsBModeSFeed = await parameters.post("/getADSBFeed", { 
        timestamp: timestamp,
        airport: airport.code,
        airline: cargoCarrier,
        flightNumber: flightNumber
      }, {cancelToken: axiosSource.token});

      const result = flightRowTimestamp ? adsBModeSFeed.data.data.slice(0,5) : adsBModeSFeed.data.data;
      if(result.length < 1){
        setEndOfFlightDetails(true)
        setIsLoading(false)
        return
      }
      if(position === "append"){
        setAdsbRows(adsbRows.concat(result));
      }else if (position === "reset"){
        setAdsbRows(result)
      }
      setLastFlightDetailsTimestamp(result[result.length - 1].timestamp - 1)
      setIsLoading(false)
    } catch (error) {
      console.log("error", error)
      setIsLoading(false)
    }
  }

  const linearBarWidth = useMemo(() => {
    return tableRef?.current?.clientWidth
  }, [isLoading]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setIsLoading(true)
    setAdsbRows([])
    setEndOfFlightDetails(false)
    setPosition("reset")
    getADSBModeSFeed(flightRowTimestamp ? flightRowTimestamp : flightDate.getTime()/1000, "reset")
  }, [flightDate, cargoCarrier, airport, flightNumber])  // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    return () => {
      axiosSource.cancel()
    };
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={!flightRowTimestamp ? classes.container : classes.containerWihtinFlightRow}>
      {adsbRows.length > 0 ? 
        <TableContainer ref = {tableContainer} onScroll={listenScrollEvent}>
          <Table style = {{"whiteSpace": "nowrap"}} stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell style={{whiteSpace: "nowrap"}}>Date Retrieved</TableCell>
                <Tooltip
                  title={
                    <>
                      <Typography color="inherit">Amazon Prime Air</Typography>
                        Amazon utilizes icao addresses from the following airlines
                        <br/>
                        - Atlas Air: GTI
                        <br/>
                        - Air Transport International: ATN
                        <br/>
                        - Silver Airways: SIL
                        <br/>
                        - Sun Country Airlines: SCX
                        <br/>
                        - Cargojet Airways: CJT
                    </>
                  }
                >
                  <TableCell align="left">Flight or Tail Number</TableCell>
                </Tooltip>
                <Tooltip title = "unique aircraft identifier">
                  <TableCell align="left">ICAO Address</TableCell>
                </Tooltip>
              </TableRow>
            </TableHead>
            <TableBody ref = {tableRef}>
              {adsbRows.map((row, i) => (
                <CargoADSBRow key={i} row={row} />
              ))}
            </TableBody>
          </Table>
          {!flightRowTimestamp && position === "append" && isLoading ? <LinearProgress sx={{width: linearBarWidth ? linearBarWidth : 0}}/> : null}
        </TableContainer>
      : !isLoading && adsbRows.length < 1 ?
        <div className = {classes.noResultsContainer}>
          <p className = {classes.noMessageResults}>No results are shown for this selection.  Please select another date, cargo carrier, flight number or airport.</p>
        </div> 
      : null}
      {position === "reset" && isLoading ? <div className={classes.loadingContainer}> <CircularProgress/> </div> : null}
    </div>
  );
}

const mapStateToProps = (state) => {
  return{
    airport: state.airportReducer.airport,
    flightDate: state.filterFieldsBarReducer.flightDate,
    cargoCarrier: state.filterFieldsBarReducer.cargoCarrier,
    flightNumber: state.filterFieldsBarReducer.flightNumber,
  }
}

export default connect(mapStateToProps)(CargoADSBTable)