const concessionsReducer = (state = {
    showAircrafts: false
}, action) => {
    switch (action.type){
        case "toggleAircrafts":
            return{
                ...state,
                showAircrafts: !state.showAircrafts
            };
        default:
            return state
        }
}
  
export default concessionsReducer;