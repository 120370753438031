import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import SelectDates from 'components/SelectDates/SelectDates';
import TimeFrameSelection from 'components/TimeFrameSelection/TimeFrameSelection';
import React from 'react';
import { convertToLocal } from 'utils/timeConversions';

const useStyles = makeStyles(theme => ({
  timeFrameContainer:{
    display: "flex", 
    justifyContent: "space-between",
    [theme.breakpoints.down('xs')]: {
      textAlign: "center"
    }
  },
  selectionContainer:{
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
    justifyContent: "flex-end",
    [theme.breakpoints.down('xs')]: {
      justifyContent: "center",
      paddingTop: 10,
      paddingBottom: 10
    }
  }
}));

const TimeFrameContainer = ({
  title,
  timeFrame,
  setTimeFrame,
  downloadReportsEnabled,
  downloadData,
  setTimeFrameValue,
  downloadButtonText
}) => {

  const classes = useStyles();

  const getHeaders = () => {
    const defaultHeaders = [...Object.keys(downloadData[0])];

    switch (title) {
      case "Payment Logs":
        return [...defaultHeaders, "Stripe Fee (2.9% + $0.30)", "UnDelay fee (3%)"];
      case "Parking Events Table":
        return [...defaultHeaders, "Reduced"];
      default:
        return defaultHeaders;
    }
  };

  const addPaymentLogDetails = (log) => {
    log["Stripe Fee (2.9% + $0.30)"] = (log.paymentAmount * 0.029 + 0.30).toFixed(2);
    log["UnDelay fee (3%)"] = (log.paymentAmount * 0.03).toFixed(2);
    return log;
  };

  const addParkingEventDetails = (log) => {
    log["Reduced"] = log.event === "Entered Parking Zone" ? 3 : 0;
    return log;
  };

  const processLog = (log) => {
    let updatedLog = { ...log };

    if (updatedLog.timestamp) {
      if (title === "Payment Logs") {
        updatedLog = addPaymentLogDetails(updatedLog);
      } else if (title === "Parking Events Table") {
        updatedLog = addParkingEventDetails(updatedLog);
      }

      updatedLog.timestamp = convertToLocal(updatedLog.timestamp).replace(",", "");
    }

    return Object.values(updatedLog).toString();
  };

  function convertToCSV() {
    const headers = getHeaders();
    const dataLogs = [headers].concat(downloadData);
  
    const csvData = dataLogs.map(processLog).join('\n');
    createCSVDownload(csvData, downloadButtonText ? downloadButtonText : `${timeFrame}_payment_logs.csv`);
  }

  const createCSVDownload = (data, filename) => {
    const blob = new Blob([data], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const handleReportDownload = () => {
    convertToCSV();
  };

  return (
    <Grid container className={classes.timeFrameContainer}> 
      <Grid item xs = {12} sm = {6}>
        <Typography variant="h6">{title}</Typography>
      </Grid>
      <Grid container className={classes.selectionContainer}>
        <Grid item xs = {12} sm = {6} 
          sx={{
            justifyContent: { xs: 'center', sm: 'flex-start'},
            display: "flex",
            marginBottom: {xs: 3, sm: 1},
            marginTop: {xs: 3, sm: 1}
          }}
        >
          <SelectDates/>
        </Grid>
        <Grid item  xs = {12} sm = {6} 
          sx={{
            justifyContent: { xs: 'center', sm: 'flex-end'},
            display: "flex"
          }}
        >
          <TimeFrameSelection 
            timeFrame = {timeFrame}
            setTimeFrame = {setTimeFrame}
            setTimeFrameValue = {setTimeFrameValue}
          />
          {downloadReportsEnabled && (
            <Button
              variant="outlined"
              color="primary"
              onClick={handleReportDownload}
              style={{marginRight: 10, marginLeft: 10}}
            >
              {downloadButtonText ? downloadButtonText : "Download Report"}
            </Button>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default TimeFrameContainer;
