const userReducer = (state = {
    user: {}
}, action) => {
    switch (action.type){
        case "updateUser":
            return{
                ...state,
                user: action.user
            };
        default:
            return state
        }
}
  
export default userReducer;