const gateManagementReducer = (state = {
    adjacentGateConflicts: false,
    overlappingGateConflicts: false,
    gateTimeConflicts: false,
    isMisassignedGateNames: false,
    isGateChangeSuggestionsOnly: false,
    isOnlyArrivals: false,
    isOnlyDepartures: false
}, action) => {
    switch (action.type){
        case "toggleAdjacentGateConflicts":
            return{
                ...state,
                adjacentGateConflicts: !state.adjacentGateConflicts,
                overlappingGateConflicts: false,
                gateTimeConflicts: false,
                isMisassignedGateNames: false,
                isGateChangeSuggestionsOnly: false
            };
        case "toggleOverlappingGateConflicts":
            return{
                ...state,
                adjacentGateConflicts: false,
                overlappingGateConflicts: !state.overlappingGateConflicts,
                gateTimeConflicts: false,
                isMisassignedGateNames: false,
                isGateChangeSuggestionsOnly: false
            };
        case "toggleGateTimeConflicts":
            return{
                ...state,
                adjacentGateConflicts: false,
                overlappingGateConflicts: false,
                gateTimeConflicts: !state.gateTimeConflicts,
                isMisassignedGateNames: false,
                isGateChangeSuggestionsOnly: false
            };
        case "toggleIsMisassignedGateNames":
            return{
                ...state,
                adjacentGateConflicts: false,
                overlappingGateConflicts: false,
                gateTimeConflicts: false,
                isMisassignedGateNames: !state.isMisassignedGateNames,
                isGateChangeSuggestionsOnly: false
            };
        case "toggleGateChangeSuggestionsOnly":
            return{
                ...state,
                adjacentGateConflicts: false,
                overlappingGateConflicts: false,
                gateTimeConflicts: false,
                isMisassignedGateNames: false,
                isGateChangeSuggestionsOnly: !state.isGateChangeSuggestionsOnly
            };
        case "toggleOnlyArrivals":
            return{
                ...state,
                isOnlyArrivals: !state.isOnlyArrivals,
                isOnlyDepartures: false
            };
        case "toggleOnlyDepartures":
            return{
                ...state,
                isOnlyDepartures: !state.isOnlyDepartures,
                isOnlyArrivals: false
            };
        default:
            return state
    }
}
  
export default gateManagementReducer;