import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Icon from 'assets/img/transparent.png';
import React from 'react';

const useStyles = makeStyles(theme => ({
  avatar: {
    margin: theme.spacing(1)
  },
  avatarContainer:{
    display: "flex",
    alignItems: "center",
    color: "inherit",
    textDecoration: "inherit",
    marginRight: 10
  },
  title: {
    color: "white"
  }
}));

const Logo = () => {
  const classes = useStyles();

  return (
    <div className={classes.avatarContainer}>
      <Avatar className={classes.avatar} src={Icon}/>
      <Typography className={classes.title} variant="h6" noWrap> UnDelay </Typography>
    </div>
  );
}

export default Logo