import DateFnsUtils from '@date-io/date-fns';
import { makeStyles } from '@material-ui/core/styles';
import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import 'date-fns';
import React from 'react';
import ReactGA from 'react-ga';
import { connect } from 'react-redux';
import { updateFlightDate } from 'store/filterFieldsBar/actions';
import RefreshIcon from '@mui/icons-material/Refresh';

const useStyles = makeStyles({
  input: {
    fontSize: 14
  },
  muiPickersUtilsProvider: {
    marginBottom: 0
  },
  refreshDate:{
    cursor: "pointer",
    padding: 3
  },
  container:{
    display: "flex",
    alignItems: "flex-end"
  }
});

const SelectDates = (props) => {
  const classes = useStyles();
  const { flightDate, updateFlightDate, label, hideRefresh } = props;

  const handleFlightDateChange = date => {
    updateFlightDate(date);
    ReactGA.event({
      category: 'Date Change',
      action: `Flight time ${date}`
    });
  };

  return(
    <div className={classes.container}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDateTimePicker
          className={classes.muiPickersUtilsProvider}
          variant="inline"
          format="MM/dd/yyyy hh:mm a"
          label={label ? label : "Date"}
          value={flightDate}
          onChange={handleFlightDateChange}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
          InputProps={{ className: classes.input }}
          autoOk={true}
        />
      </MuiPickersUtilsProvider>
      {!hideRefresh && (
        <RefreshIcon 
          onClick = {() => updateFlightDate(new Date())}
          className={classes.refreshDate}
        />
      )}
    </div>
  )
}

const mapStateToProps = (state) =>{
  return{
    flightDate: state.filterFieldsBarReducer.flightDate
  }
}

const mapDispatchToProps = {
  updateFlightDate
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectDates)