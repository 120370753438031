import { makeStyles } from '@material-ui/core/styles';
import Grid from '@mui/material/Grid';
import ChartContainer from 'components/ChartContainer/ChartContainer';
import FilterFieldsBar from 'components/FilterFieldsBar/FilterFieldsBar';
import FlightOperationPhrases from "components/FlightOperationPhrases/FlightOperationPhrases";
import React, { useEffect } from 'react';
import ReactGA from 'react-ga';

const useStyles = makeStyles({
  paper:{
    margin: 15
  },
  gridItems:{
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 20,
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "center"
  },
  searchContainer:{
    display: "flex",
    flexDirection: "row"
  }
});

 const GroundOpsTab = () => {
  const classes = useStyles();

  useEffect(() => {
    ReactGA.pageview('/ground-ops');
  }, [])

  return (
    <div className={classes.paper}>
      <FilterFieldsBar/>
      <Grid className={classes.flightOpsContainer}>
        <FlightOperationPhrases showGroundOperations />
      </Grid>
      <ChartContainer/>
    </div>
  );
}

export default GroundOpsTab;