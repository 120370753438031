import { makeStyles } from '@material-ui/core/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import ParkingSpotsAndQueueSideBar from 'components/ParkingSpotsAndQueueSideBar/ParkingSpotsAndQueueSideBar';
import ParkingQueueMap from "components/ParkingQueueMap/ParkingQueueMap";
import TaxisTable from 'components/TaxisTable/TaxisTable';
import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import { connect } from 'react-redux';

const useStyles = makeStyles({
    container:{
        margin: 15
    },
    gridContainer:{
        marginBottom: 20
    }
});

const ParkingQueueTab = ({ user }) => {
    const classes = useStyles();

    useEffect(() => {
        ReactGA.pageview('/parking-queue');
    }, [])

    return (
        <div className={classes.container}>
            <Grid container spacing={2} className={classes.gridContainer}>
                <Grid className={classes.gridItem} item xs = {12} sm = {12} md = {8}>
                    <ParkingQueueMap/>
                </Grid>
                <Grid className={classes.gridItem} item xs = {12} sm = {12} md = {4}>
                    <ParkingSpotsAndQueueSideBar/>
                </Grid>
            </Grid>
            {user && (!user?.taxiNumber || user?.guestServices) && (
                <Grid container component = {Paper}>
                    <Grid item xs = {12}>
                        <TaxisTable/>
                    </Grid>
                </Grid>
            )}
        </div>
    );
}

const mapStateToProps = (state) => {
    return{
        user: state.userReducer.user
    }
}

export default connect(mapStateToProps)(ParkingQueueTab);