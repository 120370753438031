import { makeStyles } from '@material-ui/core/styles';
import SurfaceManagementMap from "components/SurfaceManagementMap/SurfaceManagementMap";
import SurfaceManagementHeader from "components/SurfaceManagementHeader/SurfaceManagementHeader";
import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga';

const useStyles = makeStyles({
  container:{
    margin: 15
  },
  gridContainer:{
    marginBottom: 20
  }
});

const SurfaceManagementTab = () => {
  const classes = useStyles();
  const [aircraftDetails, setAircraftDetails] = useState({})

  useEffect(() => {
    ReactGA.pageview('/surface-management');
  }, [])

  return (
    <div className={classes.container}>
      <SurfaceManagementHeader aircraftDetails = {aircraftDetails}/>
      <SurfaceManagementMap setAircraftDetails = {setAircraftDetails}/>
    </div>
  );
}

export default SurfaceManagementTab;