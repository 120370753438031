import parameters from "../parameters";
import { auth } from "../firebase";
import store from 'store/store';

export const createPaymentIntent = async(firstAndLastName) => {
    return await parameters.post("/createPaymentIntent", {
        firstAndLastName: firstAndLastName,
        airportCode: store.getState().userReducer.user.airportCode
    })
}

export const updatePaymentIntent = async(paymentIntentId, paymentAmount) => {
    return await parameters.post("/updatePaymentIntent", {
        paymentIntentId: paymentIntentId,
        paymentAmount: paymentAmount
    })
}

export const checkAccountBalance = async() => {
    return await parameters.get("/checkAccountBalance", { params: {
        uid: auth.currentUser?.uid
    }});
}

export const updateAccountBalance = async(mathOperation, paymentAmount) => {
    return await parameters.post("/updateAccountBalance", {
        uid: auth.currentUser?.uid,
        mathOperation: mathOperation,
        paymentAmount: paymentAmount
    })
}

export const savePaymentLog = async(airportCode, paymentAmount, operation) => {
    return await parameters.post("/savePaymentLog", {
        uid: auth.currentUser?.uid,
        airportCode: airportCode,
        paymentAmount: paymentAmount,
        operation: operation
    })
}

export const countTotalPayments = async(timeFrame, timestamp) => {
    return await parameters.post("/countTotalPayments", {
        timeFrame: timeFrame,
        timestamp: timestamp,
        airportCode: store.getState().userReducer.user.airportCode
    })
}

export const getPaymentLogs = async(endTimestamp) => {
    return await parameters.get("/getPaymentLogs", { params: {
        timestamp: store.getState().filterFieldsBarReducer.flightDate.getTime()/1000,
        airportCode: store.getState().userReducer.user.airportCode,
        endTimestamp
    }});
}

export const countProductPayments = async(timeFrame, timestamp) => {
    return await parameters.post("/countProductPayments", {
        timeFrame: timeFrame,
        timestamp: timestamp,
        airportCode: store.getState().userReducer.user.airportCode
    })
}

export const getProductPaymentLogs = async(endTimestamp) => {
    return await parameters.get("/getProductPaymentLogs", { params: {
        timestamp: store.getState().filterFieldsBarReducer.flightDate.getTime()/1000,
        airportCode: store.getState().userReducer.user.airportCode,
        endTimestamp
    }});
}