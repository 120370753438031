import { makeStyles } from '@material-ui/core/styles';
import Paper from '@mui/material/Paper';
import React from 'react';
import { animated, useSpring } from 'react-spring';

const useStyles = makeStyles(theme => ({
  [theme.breakpoints.down('sm')]: {
    img:{
      transform: "scale(0.6, 0.6)"
    }
  },
  [theme.breakpoints.up('md')]: {
    img:{
      transform: "scale(0.6, 0.6)"
    }
  },
  [theme.breakpoints.up('lg')]: {
    img:{
      transform: "scale(0.8, 0.8)"
    }
  },
  paper:{
    height: "100%",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  }
}));

export const AircraftDiagram = (props) => {
  const classes = useStyles();
  const { aircraftName } = props;
  const animatedStyle = useSpring({ 
    to: { opacity: 1, transform: 'translate3d(0px,0,0)' }, 
    from: { opacity: 0, transform: 'translate3d(200px,0,0)' },
    config: { duration: 1000 },
    reset: true
  })

  if (aircraftName?.length){
    return (
      <Paper className = {classes.paper} elevated = {10}>
        <animated.div style={animatedStyle}>
          <img alt = "Airport Aircraft" className = {classes.img} src={require(`../../../src/assets/img/planes/${aircraftName}.png`)} />
        </animated.div>
      </Paper>
    );
  }
  return (
    <Paper className = {classes.paper} elevated = {10}>
      <animated.div style={animatedStyle}>
        <img alt = "Airport Aircraft" className = {classes.img} src={require(`../../../src/assets/img/planes/Boeing-737-100.png`)}  />
      </animated.div>
    </Paper>
  );
}


export default AircraftDiagram