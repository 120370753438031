export const lightGrey = "rgb(66 66 66)"

export const darkGrey = "#212121"

export const UnDelayLogoBlue = "#4983c2"

export const darkBlue = "#2e71b9"

export const darkerBlue = "#1e293c"

export const greyishBlue = "#cbd3dc"

export const fadedDarkerBlue = "rgb(30 41 60 / 84%)"

export const UnDelayLogoDarkRed = "#cc3333"

export const UnDelayLogoLightRed = "#922424"

export const lightWhite = "#ffffff45"

export const backgroundShadow = "0 10px 15px -3px rgba(0, 0, 0, 0.08), 0 4px 6px -2px rgba(0, 0, 0, 0.01)"

export const purple = "rgb(73 79 194)"

export const darkPurple = "#3d2eb9"

export const lightPurple = "#8272ff"

export const lightBlue = "#a0d3fa"

export const lightPink = "#f0ebf7"

export const green = "#34a853"

export const red = "#f15144"

export const transparentLightBlue = "#a0d3fa9c"

export const transparentRed = "#ff00005c"

export const gold = "rgb(172 159 79)"