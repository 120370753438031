export const gateZoneA1 = [
    {
        lat: 41.532856, lng: -93.650318
    },
    {
        lat: 41.532521, lng: -93.650466
    },
    {
        lat: 41.532726, lng: -93.651174
    },
    {
        lat: 41.533036, lng: -93.651008
    }
]

export const gateZoneA2 = [
    {
        lat: 41.532776, lng: -93.649779
    },
    {
        lat: 41.532348, lng: -93.649429
    },
    {
        lat: 41.532469, lng: -93.648999
    },
    {
        lat: 41.532918, lng: -93.649417
    }
]

export const gateZoneA3A = [
    {
        lat: 41.532414, lng: -93.650450
    },
    {
        lat: 41.532115, lng: -93.650705
    },
    {
        lat: 41.532341, lng: -93.651202
    },
    {
        lat: 41.532651, lng: -93.650973
    }
]

export const gateZoneA3B = [
    {
        lat: 41.532188, lng: -93.650420
    },
    {
        lat: 41.531840, lng: -93.650406
    },
    {
        lat: 41.531838, lng: -93.651091
    },
    {
        lat: 41.532186, lng: -93.651094
    }
]

export const gateZoneA4A = [
    {
        lat: 41.532464, lng: -93.649985
    },
    {
        lat: 41.532187, lng: -93.650173
    },
    {
        lat: 41.531972, lng: -93.649407
    },
    {
        lat: 41.532329, lng: -93.649328
    }
]

export const gateZoneA4B = [
    {
        lat: 41.531396, lng: -93.649801
    },
    {
        lat: 41.531487, lng: -93.649287
    },
    {
        lat: 41.531840, lng: -93.649488
    },
    {
        lat: 41.531855, lng: -93.649616
    },
    {
        lat: 41.531913, lng: -93.649645
    },
    {
        lat: 41.531898, lng: -93.649711
    },
    {
        lat: 41.532049, lng: -93.649791
    },
    {
        lat: 41.532014, lng: -93.649918
    },
    {
        lat: 41.531858, lng: -93.649840
    },
    {
        lat: 41.531837, lng: -93.649905
    },
    {
        lat: 41.531781, lng: -93.649878
    },
    {
        lat: 41.531708, lng: -93.649957
    }
]

export const gateZoneA5A = [
    {
        lat: 41.531439, lng: -93.650827
    },
    {
        lat: 41.531465, lng: -93.650555
    },
    {
        lat: 41.531549, lng: -93.650516
    },
    {
        lat: 41.531626, lng: -93.650348
    },
    {
        lat: 41.531694, lng: -93.650357
    },
    {
        lat: 41.531689, lng: -93.650453
    },
    {
        lat: 41.531746, lng: -93.650463
    },
    {
        lat: 41.531743, lng: -93.650541
    },
    {
        lat: 41.531815, lng: -93.650610
    },
    {
        lat: 41.531793, lng: -93.650892
    }
]

export const gateZoneA5B = [
    {
        lat: 41.531347, lng: -93.650412
    },
    {
        lat: 41.531578, lng: -93.650436
    },
    {
        lat: 41.531636, lng: -93.650318
    },
    {
        lat: 41.531686, lng: -93.650321
    },
    {
        lat: 41.531691, lng: -93.650267
    },
    {
        lat: 41.531768, lng: -93.650275
    },
    {
        lat: 41.531776, lng: -93.650156
    },
    {
        lat: 41.531696, lng: -93.650150
    },
    {
        lat: 41.531698, lng: -93.650095
    },
    {
        lat: 41.531651, lng: -93.650090
    },
    {
        lat: 41.531609, lng: -93.649959
    },
    {
        lat: 41.531377, lng: -93.649935
    }
]

export const gateZoneC1 = [
    {
        lat: 41.533399, lng: -93.650979
    },
    {
        lat: 41.533404, lng: -93.650223
    },
    {
        lat: 41.533041, lng: -93.650233
    },
    {
        lat: 41.533036, lng: -93.651006
    }
]

export const gateZoneC2 = [
    {
        lat: 41.533748, lng: -93.649765
    },
    {
        lat: 41.533425, lng: -93.649673
    },
    {
        lat: 41.533576, lng: -93.648973
    },
    {
        lat: 41.533903, lng: -93.649144
    }
]

export const gateZoneC3BA = [
    {
        lat: 41.533406, lng: -93.650961
    },
    {
        lat: 41.533441, lng: -93.650685
    },
    {
        lat: 41.533619, lng: -93.650242
    },
    {
        lat: 41.533839, lng: -93.650392
    },
    {
        lat: 41.533576, lng: -93.651073
    }
]

export const gateZoneC3B = [
    {
        lat: 41.533954, lng: -93.651231
    },
    {
        lat: 41.533693, lng: -93.651231
    },
    {
        lat: 41.533707, lng: -93.650775
    },
    {
        lat: 41.533798, lng: -93.650716
    },
    {
        lat: 41.533803, lng: -93.650572
    },
    {
        lat: 41.533868, lng: -93.650573
    },
    {
        lat: 41.533870, lng: -93.650720
    },
    {
        lat: 41.533960, lng: -93.650786
    }
]

export const gateZoneC4A = [
    {
        lat: 41.534226, lng: -93.649266
    },
    {
        lat: 41.534142, lng: -93.650020
    },
    {
        lat: 41.534503, lng: -93.650080
    },
    {
        lat: 41.534560, lng: -93.649401
    }
]

export const gateZoneC4B = [
    {
        lat: 41.533874, lng: -93.649336
    },
    {
        lat: 41.534118, lng: -93.649187
    },
    {
        lat: 41.534286, lng: -93.649673
    },
    {
        lat: 41.534079, lng: -93.649856
    }
]

export const gateZoneC5A = [
    {
        lat: 41.534336, lng: -93.650590
    },
    {
        lat: 41.534337, lng: -93.651337
    },
    {
        lat: 41.533980, lng: -93.651342
    },
    {
        lat: 41.533986, lng: -93.650558
    }
]

export const gateZoneC5B = [
    {
        lat: 41.534505, lng: -93.650624
    },
    {
        lat: 41.534365, lng: -93.650753
    },
    {
        lat: 41.534339, lng: -93.651128
    },
    {
        lat: 41.534568, lng: -93.651455
    },
    {
        lat: 41.534815, lng: -93.651120
    }
]

export const gateZoneC6A = [
    {
        lat: 41.535068, lng: -93.649462
    },
    {
        lat: 41.534824, lng: -93.649881
    },
    {
        lat: 41.534768, lng: -93.650187
    },
    {
        lat: 41.534866, lng: -93.650256
    },
    {
        lat: 41.535079, lng: -93.650145
    },
    {
        lat: 41.535273, lng: -93.649815
    }
]

export const gateZoneC6B = [
    {
        lat: 41.534560, lng: -93.649401
    },
    {
        lat: 41.534632, lng: -93.650090
    },
    {
        lat: 41.534844, lng: -93.649838
    },
    {
        lat: 41.534932, lng: -93.649684
    },
    {
        lat: 41.534892, lng: -93.649270
    }
]

export const gateZoneC7A = [
    {
        lat: 41.535150, lng: -93.650652
    },
    {
        lat: 41.534660, lng: -93.650560
    },
    {
        lat: 41.534593, lng: -93.651022
    },
    {
        lat: 41.535119, lng: -93.651130
    }
]

export const gateZoneC7B = [
    {
        lat: 41.535235, lng: -93.650092
    },
    {
        lat: 41.534811, lng: -93.650291
    },
    {
        lat: 41.534754, lng: -93.650568
    },
    {
        lat: 41.535030, lng: -93.650628
    },
    {
        lat: 41.535302, lng: -93.650508
    }
]
