import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';
import { getParkingQueueEvents } from "api/endpoints/parkingQueue";
import React, { useEffect, useState, useMemo } from 'react';
import { connect } from 'react-redux';
import { convertToLocal } from 'utils/timeConversions';
import TimeFrameContainer from 'components/TimeFrameContainer/TimeFrameContainer';
import DropDownMenu from "components/DropDownMenu/DropDownMenu";

const ParkingReportTable = ({ flightDate }) => {
  const [parkingEvents, setParkingEvents] = useState([])
  const [timeFrame, setTimeFrame] = useState("Last Month")
  const [timeFrameValue, setTimeFrameValue] = useState(2592000)
  const [event, setEvent] = useState(null)

  const events = [
    "Entered Queue",
    "Exited Queue",
    "Entered Parking Zone",
    "Exited Reserved Parking Spot From",
    "Exited From Red Zone",
    "Exited Parking Spot from Leave Parking Spot Button",
    "Kicked Out of Parking Spot After 7 Hours",
    "No Filter"
  ]; 

  const getEvents = async() => {
    try{
      const response = await getParkingQueueEvents(flightDate.getTime()/1000 - timeFrameValue, event !== "No Filter" ? event : null)
      setParkingEvents(response.data.data)
    }catch(e){
      console.log(e)
    }
  }

  useEffect(() => {
    getEvents()
  }, [flightDate, timeFrameValue, event])

  const showDownloadReportButton = useMemo(() => {
    if(timeFrame && timeFrame !== "Last Year"){
      return true
    }
    return false
  }, [timeFrame])

  return (
    <Paper style = {{padding: 10}}>
      <TimeFrameContainer
        title = "Parking Events Table" 
        timeFrame = {timeFrame} 
        setTimeFrame = {setTimeFrame} 
        downloadReportsEnabled = {showDownloadReportButton}
        downloadData = {parkingEvents}
        setTimeFrameValue = {setTimeFrameValue}
      />
      <Box 
        sx={{
          display: "flex",
          justifyContent: { xs: "center", sm: "flex-end" },
          padding: 1
        }}
      >
        <DropDownMenu
          listItems={events}
          setListItem={setEvent}
          listItemSelected={event}
        />
      </Box>
      <TableContainer style = {{height: 450}}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Company Name</TableCell>
              <TableCell align="right">Taxi Number</TableCell>
              <TableCell align="right">First And Last Name</TableCell>
              <TableCell align="right">Event</TableCell>
              <TableCell align="right">Time of Event</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {parkingEvents.map((row, i) => (
              <TableRow
                key={i}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.companyName}
                </TableCell>
                <TableCell align="right">{row.taxiNumber}</TableCell>
                <TableCell align="right">{row.firstAndLastName}</TableCell>
                <TableCell align="right">{row.event}</TableCell>
                <TableCell align="right">{convertToLocal(row.timestamp)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}

const mapStateToProps = (state) => {
  return{
    flightDate: state.filterFieldsBarReducer.flightDate,
  }
}

export default connect(mapStateToProps)(ParkingReportTable)