import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Image from 'assets/img/plane_1_1920.jpg';
import Icon from 'assets/img/transparent.png';
import React, { useState } from 'react';
import Copyright from './Copyright';
import ReactGA from 'react-ga';
import { auth } from "api/firebase";
import { sendPasswordResetEmail } from "firebase/auth";

const useStyles = makeStyles(theme => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: `url(${Image})`,
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'dark' ? theme.palette.grey[900] : theme.palette.grey[50],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(4, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1)
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  loadingContainer:{
    justifyContent: "center",
    display: "flex",
    margin: 20
  }
}));

const ForgotPassword = (props) => {
  const classes = useStyles();
  const [email, setEmail] = useState("")
  const [loading, setLoading] = useState(false);

  const sendPasswordReset = async() => {
    setLoading(true)
    try{
      await sendPasswordResetEmail(auth, email)
      ReactGA.event({
        category: 'User',
        action: 'Password Reset'
      });
      setLoading(false)
      alert("Please check email to reset password.");
    }catch(e){
      console.log(e);
      setLoading(false)
      ReactGA.exception({
        description: e.message,
        fatal: true
      });
      alert(e.message);
    }
  }

  const handleChange = event => {
    const { value } = event.target;
    setEmail(value)
  };

  return (
    <div className={classes.paper}>
      <Avatar className={classes.avatar} src={Icon}/>
      <Typography component="h1" variant="h5">Forgot Password</Typography>
      <form className={classes.form} onSubmit={sendPasswordReset} noValidate>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          onChange={handleChange}
          autoFocus
        />
        {!loading ? 
          <Button
            fullWidth
            variant="outlined"
            color="primary"
            className={classes.submit}
            onClick={sendPasswordReset}
          >
            Reset Password
          </Button>
        : <div className={classes.loadingContainer}> <CircularProgress/> </div> }
        <Grid container>
          <Grid item xs>
            <Link href="#signin" variant="body2" onClick={() => props.changeAuth("SignIn")}>Sign In?</Link>
          </Grid>
        </Grid>
        <Box mt={5}> <Copyright /> </Box>
      </form>
    </div>
  );
}

export default ForgotPassword;