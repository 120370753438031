import { makeStyles } from '@material-ui/core/styles';
import Grid from '@mui/material/Grid';
import ParkingReportRevenueGraph from 'components/ParkingReportRevenueGraph/ParkingReportRevenueGraph';
import ParkingReportTable from 'components/ParkingReportTable/ParkingReportTable';
import PaymentLogsTable from 'components/PaymentLogsTable/PaymentLogsTable';
import ParkingRerpotsEventsBarGraph from 'components/ParkingRerpotsEventsBarGraph/ParkingRerpotsEventsBarGraph';
import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import { connect } from 'react-redux';

const useStyles = makeStyles({
    container:{
        margin: 15
    },
    gridContainer:{
        marginBottom: 20
    },
    gridItems:{
        paddingLeft: 20,
        paddingRight: 20,
        paddingTop: 20,
        display: "flex",
        alignItems: "flex-end",
        justifyContent: "center"
    },
    airport:{
        fontSize: 13
    }
});

const ParkingReportsTab = (props) => {
    const classes = useStyles();
    const { airport } = props;

    useEffect(() => {
        ReactGA.pageview('/parking-reports');
    }, [])

    return (
        <div className={classes.container}>
            {airport?.hidePayment !== true && (
                <>
                    <Grid container spacing={2} className={classes.gridContainer}>
                        <Grid item xs = {12}>
                            <PaymentLogsTable/>
                        </Grid>
                    </Grid>

                    <Grid container spacing={2} className={classes.gridContainer}>
                        <Grid item xs = {12}>
                            <ParkingReportRevenueGraph/>
                        </Grid>
                    </Grid>
                </>
            )}

            <Grid container spacing={2} className={classes.gridContainer}>
                <Grid item xs = {12}>
                    <ParkingReportTable/>
                </Grid>
            </Grid>

            <Grid container spacing={2} className={classes.gridContainer}>
                <Grid item xs = {12}>
                    <ParkingRerpotsEventsBarGraph/>
                </Grid>
            </Grid>
        </div>
    );
}

const mapStateToProps = (state) =>{
    return{
      airport: state.airportReducer.airport
    }
}
  
export default connect(mapStateToProps)(ParkingReportsTab)