export const updateAirport = (airport) => {
    return {
        type: "updateAirport",
        airport: airport
    }
}

export const updateAirports = (airports) => {
    return {
        type: "updateAirports",
        airports: airports
    }
}