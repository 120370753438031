import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import MuiAlert from '@mui/material/Alert';
import LinearProgress from '@mui/material/LinearProgress';
import Snackbar from '@mui/material/Snackbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { getAircraftLocations } from "api/endpoints/adsb";
import parameters from 'api/parameters';
import axios from "axios";
import GateChangeSuggestionSnackBar from "components/GateChangeSuggestionSnackBar/GateChangeSuggestionSnackBar";
import {
    runwayZoneEight, runwayZoneEleven, runwayZoneFifteen, runwayZoneFive, runwayZoneFour, runwayZoneFourteen, runwayZoneNine, runwayZoneOne, runwayZoneSeven, runwayZoneSix, runwayZoneSixteen, runwayZoneTen, runwayZoneThirteen, runwayZoneThree, runwayZoneTwelve, runwayZoneTwo
} from 'components/SurfaceManagementMap/runwayZones.js';
import airlineICAOCodes from "constants/airlineICAOCodes.json";
import airportStats from "constants/airportStats.json";
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { connect } from 'react-redux';
import { darkPurple, gold } from 'styles/colors';
import { isInZone } from 'utils/zoning';

let misassignedGateNames = airportStats.filter((airportStat) => airportStat.code === "DSM")[0].misassignedGateNames;

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const useStyles = makeStyles({
    table: {
        minWidth: 650
    },
    sticky: {
        position: "sticky",
        left: 0,
        background: "white",
        zIndex: 1
    },
    paper: {
        height: 600,
        overflow: "auto"
    },
    tableCell:{
        borderRight: "1px solid #e0e0e0"
    },
    gateStatusButton:{
        display: "flex",
        alignSelf: "center"
    },
    tableCellHeader:{
        width: 500
    },
    legendContainer:{
        display: "flex",
        alignItems: "center",
        marginRight: 10
    },
    legendText:{
        margin: 0,
        marginLeft: 3
    },
});

const getCellColor = (flightStatus) => {
    switch(flightStatus) {
        case "Arrived":
            return "green"
        case "Departed":
            return "orange"
        case "In Air":
            return "#88c4fa"
        case "Scheduled":
            return gold
        case "Delayed":
            return "#bf0000"
        case "Cancelled":
            return darkPurple
        default:
            return "white"
    }
}

const dateOptions = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
};

const GateTable = (props) => {
    const tableRef = useRef()
    const { 
        airport, 
        isOnlyArrivals, 
        isOnlyDepartures, 
        isMisassignedGateNames,
        isGateChangeSuggestionsOnly
    } = props;
    const classes = useStyles();
    const axiosSource = axios.CancelToken.source();
    const [aircraftDetails, setAircraftDetails] = useState({});
    const [isGateSuggestionSnackBarOpen, setIsGateSuggestionSnackBarOpen] = useState(false);
    const [aircraftOnRunwaySnackBarOpen, setAircraftOnRunwaySnackBarOpen] = useState(false);
    const [showOnceAfterStatusFilter, setShowOnceAfterStatusFilter] = useState(false)
    const [gateChangeSuggestion, setGateSuggestion] = useState({})

    const handleCloseAlertSnackBar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setAircraftOnRunwaySnackBarOpen(false);
    };

    const handleCloseOfGateSuggestionSnackBar = () => {
        setIsGateSuggestionSnackBarOpen(false)
    };

    const isAircraftOnRunway = async() => {
        try{
            const response = await getAircraftLocations()
            const aircraftLocation = response.data.data;
            const lat = Number(aircraftLocation[0].latDecoded);
            const long = Number(aircraftLocation[0].longDecoded);
            if(
                isInZone(lat, long, runwayZoneOne) ||
                isInZone(lat, long, runwayZoneTwo) ||
                isInZone(lat, long, runwayZoneThree) ||
                isInZone(lat, long, runwayZoneFour) ||
                isInZone(lat, long, runwayZoneFive) ||
                isInZone(lat, long, runwayZoneSix) ||
                isInZone(lat, long, runwayZoneSeven) ||
                isInZone(lat, long, runwayZoneEight) ||
                isInZone(lat, long, runwayZoneNine) ||
                isInZone(lat, long, runwayZoneTen) ||
                isInZone(lat, long, runwayZoneEleven) ||
                isInZone(lat, long, runwayZoneTwelve) ||
                isInZone(lat, long, runwayZoneThirteen) ||
                isInZone(lat, long, runwayZoneFourteen) ||
                isInZone(lat, long, runwayZoneFifteen) ||
                isInZone(lat, long, runwayZoneSixteen) 
            ){
                setAircraftDetails(aircraftLocation[0]);
                setAircraftOnRunwaySnackBarOpen(true);
            }
        }catch(e){
            console.log(e)
        }
    }

    const gates = useMemo(() => {
        let gates = airportStats.filter((airportStat) => airportStat.code === airport.code)[0].gates;
        let unassignedGate = [{name: "No Gate Assigned"}]
        return unassignedGate.concat(gates)
    }, [airport])

    useEffect(() => {
        isAircraftOnRunway();
        let isMounted = true;
    
        const refreshFlightStats = setInterval(() => {
            if(isMounted){
                isAircraftOnRunway()
            }
        }, 20000);
    
        return () => {
            clearInterval(refreshFlightStats);
            isMounted = false;
        }
    }, [])

    const { data: gatesWithFlights, isFetching, refetch} = useQuery(
        ['gatesWithFlights'],
        async () => {
            const flightStatsQuery = await parameters.post("/getFlightStats", {
                timestamp: new Date().getTime()/1000,
                airport: airport.code
            }, {cancelToken: axiosSource.token});
            let flightStats = flightStatsQuery.data.data;
            
            //determines which flights are the next day
            let pastNoon = new Date().getHours() > 12;
            let earlyMorning = new Date().getHours() >= 0 && new Date().getHours() < 4;
            let newFlightStats = []
            flightStats.map((flight) => {
                if(pastNoon && Number(flight.localTime.slice(0, 2)) <= 5){
                    flight.localTime = flight.localTime.slice(0, 2) + "." + flight.localTime.slice(2)
                    newFlightStats.push(flight)
                }else if(earlyMorning && Number(flight.localTime.slice(0, 2)) > 5){
                    //do nothing
                }else{
                    newFlightStats.push(flight)
                }
            })
            flightStats = newFlightStats

            if(isOnlyArrivals){
                flightStats = flightStats.filter((flight) => {
                    return flight.arrDep === "A"
                });
            }else if(isOnlyDepartures){
                flightStats = flightStats.filter((flight) => {
                    return flight.arrDep === "D"
                });
            }

            if(isMisassignedGateNames){
                for (let gate in misassignedGateNames){
                    misassignedGateNames[gate]["flights"] = []
                    for (let flight in flightStats){
                        if (flightStats[flight].gate === misassignedGateNames[gate].name){
                            misassignedGateNames[gate]["flights"].push(flightStats[flight])
                        }else if(flightStats[flight].gate.length < 1 && misassignedGateNames[gate].name === "No Gate Assigned"){
                            misassignedGateNames[gate]["flights"].push(flightStats[flight])
                        }
                    }
                }
                return misassignedGateNames
            }else{
                for (let gate in gates){
                    gates[gate]["flights"] = []
                    for (let flight in flightStats){
                        if (flightStats[flight].gate === gates[gate].name){
                            gates[gate]["flights"].push(flightStats[flight])
                        }else if(flightStats[flight].gate.length < 1 && gates[gate].name === "No Gate Assigned"){
                            gates[gate]["flights"].push(flightStats[flight])
                        }
                    }
                }
                return gates
            }
        },
        {
          refetchInterval: 20000,
          refetchIntervalInBackground: true,
          cacheTime: 0
        },
    )

    const cancelPreviousCall = async() => {
        try{
            if(isFetching && gatesWithFlights?.lenth){
                await axiosSource.cancel()
            }
            await refetch()
        }catch(e){
            console.log(e)
        }
    }

    useEffect(() => { 
        setShowOnceAfterStatusFilter(true)
        cancelPreviousCall()
    }, [isOnlyArrivals, isOnlyDepartures, isMisassignedGateNames, isGateChangeSuggestionsOnly])

    useEffect(() => {
        if(!isFetching && showOnceAfterStatusFilter){
            setShowOnceAfterStatusFilter(false)
        }
    }, [isFetching])

    useEffect(() => {
        return () => {
            axiosSource.cancel()
        };
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const columnTimes = useMemo(() => {
        let times = [...Array(24).keys()];
        let newTimes = []
        for(let time in times){
            time.toString().length === 1 ? newTimes.push(`0${time.toString()}`) : newTimes.push(time.toString())
        }
        const nextDayTimes = ["00.", "01.", "02.", "03.", "04.", "05."]
        return newTimes.concat(nextDayTimes)
    }, [])

    const showGateChangeSuggestion = (gateName, gateLocalTimeBlock, flightNumber) => {
        let savedGate = gates.filter((gate) => gate.name === gateName)[0];
        let gateSuggestion = {}

        if(savedGate.name === "No Gate Assigned"){
            let gateWithMatchingAirlines = gates.filter((gate) => {
                return(
                    gate.prefentialAirline === airlineICAOCodes[savedGate.flights[0].airline]
                    && !gate.flights.filter((flight) => flight.localTime.slice(0, 2) === gateLocalTimeBlock.slice(0, 2)).length
                )
            })
            if(gateWithMatchingAirlines.length){
                gateSuggestion = gateWithMatchingAirlines[0]
            }
        }else{
            //get gates with the same preferred airline
            for (let preferredAirlineGate in savedGate.airlinePreferenceGates){
                //get gate with flights that match internal airportStats
                let filteredGateWithFlights = gatesWithFlights.filter((gateWithFlight) => {
                    return(
                        gateWithFlight.name === savedGate.airlinePreferenceGates[preferredAirlineGate].name 
                        //prevent gate changes within the same hour
                        && !gateWithFlight.flights.filter((flight) => flight.localTime.slice(0, 2) === gateLocalTimeBlock.slice(0, 2)).length
                    )
                });

                if(filteredGateWithFlights.length){
                    gateSuggestion = savedGate.airlinePreferenceGates[preferredAirlineGate]
                    break
                }
            }
        }
        
        setGateSuggestion({...gateSuggestion, originalGate: gateName, flightNumber: flightNumber})
        setIsGateSuggestionSnackBarOpen(true)
    }

    const checkIfFlightTimeMatchesColumnTime = (key, columnTime, gate) => {
        let gateFlights = gate.flights;
        let flightMatches = [];

        //show which flights match gate times
        for(let gateFlight in gateFlights){
            if(gateFlights[gateFlight].localTime.includes(".")){
                if (gateFlights[gateFlight].localTime.slice(0, 3) === columnTime){
                    flightMatches.push(gateFlights[gateFlight])
                }
            }else{
                if (gateFlights[gateFlight].localTime.slice(0, 2) === columnTime){
                    flightMatches.push(gateFlights[gateFlight])
                }
            }
        }

        if(isGateChangeSuggestionsOnly && flightMatches.length > 1){
            return (
                <Tooltip 
                    arrow
                    key={key} 
                    title={
                        <>
                            {flightMatches.map((flight, i) => {
                                return (
                                    <React.Fragment key={i}>
                                        <Typography color="inherit">{airlineICAOCodes[flight.airline]}{flight.flightNumber}</Typography>
                                        {`${flight.localTime.replace(".", "")}, ${flight.status}`}
                                    </React.Fragment>
                                )
                            })}
                        </>
                    }
                >
                    <TableCell 
                        style={{background: getCellColor(flightMatches[0].status), cursor: "pointer"}} 
                        onClick={() => showGateChangeSuggestion(gate.name, flightMatches[0].localTime, `${airlineICAOCodes[flightMatches[0].airline]}${flightMatches[0].flightNumber}`)}
                        className={classes.tableCell} 
                    >
                        <p style = {{color: "white"}}>{flightMatches.length > 0 && (`${airlineICAOCodes[flightMatches[0].airline]}${flightMatches[0].flightNumber}`)}</p>
                    </TableCell>
                </Tooltip>
            )
        }else if(!isGateChangeSuggestionsOnly && flightMatches.length){
            return (
                <Tooltip 
                    arrow
                    key={key} 
                    title={
                        <>
                            {flightMatches.map((flight, i) => {
                                return (
                                    <React.Fragment key={i}>
                                        <Typography color="inherit">{airlineICAOCodes[flight.airline]}{flight.flightNumber}</Typography>
                                        {`${flight.localTime.replace(".", "")}, ${flight.status}`}
                                    </React.Fragment>
                                )
                            })}
                        </>
                    }
                >
                    <TableCell 
                        style={{background: getCellColor(flightMatches[0].status), cursor: "pointer"}} 
                        onClick={() => showGateChangeSuggestion(gate.name, flightMatches[0].localTime, `${airlineICAOCodes[flightMatches[0].airline]}${flightMatches[0].flightNumber}`)}
                        className={classes.tableCell} 
                    >
                        <p style = {{color: "white"}}>{flightMatches.length > 0 && (`${airlineICAOCodes[flightMatches[0].airline]}${flightMatches[0].flightNumber}`)}</p>
                    </TableCell>
                </Tooltip>
            )
        }else{
            return <TableCell key={key} style = {{background: "white"}} className={classes.tableCell}/>
        }
    }

    return (
        <TableContainer>
            {gatesWithFlights?.length && showOnceAfterStatusFilter && (<LinearProgress sx={{width: tableRef?.current?.clientWidth}}/>)}
            <Snackbar open={aircraftOnRunwaySnackBarOpen} autoHideDuration={30000} onClose={handleCloseAlertSnackBar}>
                <Alert onClose={handleCloseAlertSnackBar} severity="error" sx={{ width: '100%' }}>
                    {aircraftDetails?.aircraftIdentification} aircraft on runway
                </Alert>
            </Snackbar>
            <GateChangeSuggestionSnackBar
                isGateSuggestionSnackBarOpen = {isGateSuggestionSnackBarOpen}
                gateChangeSuggestion = {gateChangeSuggestion} 
                handleCloseOfGateSuggestionSnackBar = {handleCloseOfGateSuggestionSnackBar}
            />
            <Table
                ref = {tableRef}
                className={classes.table}
                aria-label="simple table"
                sx={{
                    tableLayout: "fixed",
                    '& .MuiTableCell-root': { 
                        display: "flex",
                        justifyContent: "center"
                    }
                }}
            >
                <TableHead>
                    <TableRow>
                        <TableCell  align="center" className={classes.sticky}>
                            All Gates
                        </TableCell>
                        <TableCell colSpan={25} align="center">{new Date().toLocaleString("en-US", dateOptions)}</TableCell>
                        <TableCell colSpan={5} align="center">{new Date(new Date().getTime() + (24 * 60 * 60 * 1000)).toLocaleString("en-US", dateOptions)}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell className={classes.sticky}></TableCell>
                        {columnTimes.map((time, i) => <TableCell key = {i} align="center">{time.replace(".", "")}</TableCell>)}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {gatesWithFlights?.map((gate) => (
                        <TableRow key={gate.name}>
                            <TableCell className={classes.sticky} component="th" scope="row">
                                {gate.name} - {gate.prefentialAirline}
                            </TableCell>
                            {columnTimes.map((time, i) => gate.flights.length ? checkIfFlightTimeMatchesColumnTime(i, time, gate) :
                                <TableCell key = {i + gate.name} style = {{background: "white"}} className={classes.tableCell} align="right"></TableCell>)}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            {!gatesWithFlights?.length && (<LinearProgress sx={{width: tableRef?.current?.clientWidth}}/>)}
        </TableContainer>
    );
}

const mapStateToProps = (state) => {
    return{
        airport: state.airportReducer.airport,
        isOnlyArrivals: state.gateManagementReducer.isOnlyArrivals,
        isOnlyDepartures: state.gateManagementReducer.isOnlyDepartures,
        isMisassignedGateNames: state.gateManagementReducer.isMisassignedGateNames,
        isGateChangeSuggestionsOnly: state.gateManagementReducer.isGateChangeSuggestionsOnly,
    }
}
  
export default connect(mapStateToProps)(GateTable);