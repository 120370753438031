export const isInZone = (latitude, longitude, polygon) => {
    if (typeof latitude !== 'number' || typeof longitude !== 'number') {
      console.log('Invalid latitude or longitude. Numbers are expected')
    } else if (!polygon || !Array.isArray(polygon)) {
      console.log('Invalid polygon. Array with locations expected')
    } else if (polygon.length === 0) {
      console.log('Invalid polygon. Non-empty Array expected')
    }
  
    let inside = false
    for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
      const xi = polygon[i]["lat"]; const yi = polygon[i]["lng"]
      const xj = polygon[j]["lat"]; const yj = polygon[j]["lng"]
  
      const intersect = ((yi > longitude) !== (yj > longitude)) &&
              (latitude < (xj - xi) * (longitude - yi) / (yj - yi) + xi)
      if (intersect) inside = !inside
    }
  
    return inside
};