export const updateFlightDate = (flightDate) => {
    return {
        type: "updateFlightDate",
        flightDate: flightDate
    }
}

export const updateAirline = (airline) => {
    return {
        type: "updateAirline",
        airline: airline
    }
}

export const updateFlightNumber = (flightNumber) => {
    return{
        type: "updateFlightNumber",
        flightNumber: flightNumber
    }
}

export const updateAirlines = (airlines) => {
    return{
        type: "updateAirlines",
        airlines: airlines
    }
}

export const updateCargoCarrier = (cargoCarrier) => {
    return{
        type: "updateCargoCarrier",
        cargoCarrier: cargoCarrier
    }
}