export const runwayZoneOne = [
    {
        lat: 41.523538, lng: -93.677290
    },
    {
        lat: 41.523207, lng: -93.676964
    },
    {
        lat: 41.537786, lng: -93.650418
    },
    {
        lat: 41.538117, lng: -93.650737
    }
]

export const runwayZoneTwo = [
    {
        lat: 41.523207, lng: -93.676964
    },
    {
        lat: 41.522805, lng: -93.676577
    },
    {
        lat: 41.522973, lng: -93.676267
    },
    {
        lat: 41.523122, lng: -93.676429
    },
    {
        lat: 41.523225, lng: -93.676471
    },
    {
        lat: 41.523366, lng: -93.676469
    },
    {
        lat: 41.523459, lng: -93.676423
    },
    {
        lat: 41.523557, lng: -93.676327
    }
]

export const runwayZoneThree = [
    {
        lat: 41.522805, lng: -93.676577
    },
    {
        lat: 41.522408, lng: -93.676182
    },
    {
        lat: 41.532940, lng: -93.657017
    },
    {
        lat: 41.533077, lng: -93.656568
    },
    {
        lat: 41.533359, lng: -93.656981
    },
    {
        lat: 41.533060, lng: -93.657299
    },
    {
        lat: 41.523248, lng: -93.675103
    },
    {
        lat: 41.522916, lng: -93.675883
    },
    {
        lat: 41.522900, lng: -93.676043
    },
    {
        lat: 41.522919, lng: -93.676179
    },
    {
        lat: 41.522973, lng: -93.676267
    }
]

export const runwayZoneFour = [
    {
        lat: 41.522408, lng: -93.676182
    },
    {
        lat: 41.522281, lng: -93.675096
    },
    {
        lat: 41.522720, lng: -93.674271
    },
    {
        lat: 41.522858, lng: -93.674244
    },
    {
        lat: 41.523052, lng: -93.674411
    },
    {
        lat: 41.523167, lng: -93.674480
    },
    {
        lat: 41.523275, lng: -93.674470
    },
    {
        lat: 41.523387, lng: -93.674391
    }
]

export const runwayZoneFive = [
    {
        lat: 41.525664, lng: -93.670698
    },
    {
        lat: 41.526095, lng: -93.670091
    },
    {
        lat: 41.526231, lng: -93.670026
    },
    {
        lat: 41.526346, lng: -93.670061
    },
    {
        lat: 41.526400, lng: -93.670115
    },
    {
        lat: 41.526672, lng: -93.670526
    },
    {
        lat: 41.526683, lng: -93.670585
    },
    {
        lat: 41.526676, lng: -93.670653
    },
    {
        lat: 41.527218, lng: -93.669659
    },
    {
        lat: 41.527067, lng: -93.669825
    },
    {
        lat: 41.526944, lng: -93.669866
    },
    {
        lat: 41.526819, lng: -93.669850
    },
    {
        lat: 41.526698, lng: -93.669760
    },
    {
        lat: 41.526559, lng: -93.669555
    },
    {
        lat: 41.526530, lng: -93.669442
    },
    {
        lat: 41.526556, lng: -93.669251
    },
    {
        lat: 41.526747, lng: -93.668906
    },
    {
        lat: 41.526893, lng: -93.668822
    },
    {
        lat: 41.526998, lng: -93.668833
    },
    {
        lat: 41.527102, lng: -93.668917
    },
    {
        lat: 41.527243, lng: -93.669147
    },
    {
        lat: 41.527285, lng: -93.669303
    },
    {
        lat: 41.527285, lng: -93.669457
    },
    {
        lat: 41.527218, lng: -93.669659 
    },
    {
        lat: 41.527830, lng: -93.668548
    },
    {
        lat: 41.527743, lng: -93.668629
    },
    {
        lat: 41.527611, lng: -93.668692
    },
    {
        lat: 41.527517, lng: -93.668693
    },
    {
        lat: 41.527419, lng: -93.668651
    },
    {
        lat: 41.527306, lng: -93.668543
    },
    {
        lat: 41.527219, lng: -93.668405
    },
    {
        lat: 41.527186, lng: -93.668226
    },
    {
        lat: 41.527200, lng: -93.668080
    },
    {
        lat: 41.527559, lng: -93.667256
    }
]

export const runwayZoneSix = [
    {
        lat: 41.529656, lng: -93.663468
    },
    {
        lat: 41.530015, lng: -93.662955
    },
    {
        lat: 41.530190, lng: -93.662872
    },
    {
        lat: 41.530299, lng: -93.662901
    },
    {
        lat: 41.530488, lng: -93.663098
    },
    {
        lat: 41.530560, lng: -93.663273
    },
    {
        lat: 41.530568, lng: -93.663461
    },
    {
        lat: 41.530529, lng: -93.663640
    },
    {
        lat: 41.531100, lng: -93.662596
    },
    {
        lat: 41.530952, lng: -93.662724
    },
    {
        lat: 41.530789, lng: -93.662734
    },
    {
        lat: 41.530685, lng: -93.662688
    },
    {
        lat: 41.530526, lng: -93.662549
    },
    {
        lat: 41.530489, lng: -93.662502
    },
    {
        lat: 41.530445, lng: -93.662342
    },
    {
        lat: 41.530455, lng: -93.662146
    },
    {
        lat: 41.530703, lng: -93.661569
    }
]


export const runwayZoneSeven = [
    {
        lat: 41.532283, lng: -93.660433
    },
    {
        lat: 41.532104, lng: -93.660139
    },
    {
        lat: 41.532011, lng: -93.660039
    },
    {
        lat: 41.531725, lng: -93.659892
    },
    {
        lat: 41.531442, lng: -93.660198
    },
    {
        lat: 41.532081, lng: -93.659036
    },
    {
        lat: 41.531950, lng: -93.659477
    },
    {
        lat: 41.532141, lng: -93.659785
    },
    {
        lat: 41.532241, lng: -93.659882
    },
    {
        lat: 41.532509, lng: -93.660030
    }
]

export const runwayZoneEight = [
    {
        lat: 41.528205, lng: -93.653470
    },
    {
        lat: 41.527846, lng: -93.653912
    },
    {
        lat: 41.527883, lng: -93.653769
    },
    {
        lat: 41.527888, lng: -93.651709
    },
    {
        lat: 41.527927, lng: -93.651508
    },
    {
        lat: 41.528039, lng: -93.651306
    },
    {
        lat: 41.528813, lng: -93.650361
    },
    {
        lat: 41.529085, lng: -93.650742
    },
    {
        lat: 41.529024, lng: -93.650695
    },
    {
        lat: 41.528747, lng: -93.650879
    },
    {
        lat: 41.528195, lng: -93.651536
    },
    {
        lat: 41.528142, lng: -93.651651
    },
    {
        lat: 41.528121, lng: -93.651756
    },
    {
        lat: 41.528118, lng: -93.653245
    },
    {
        lat: 41.528169, lng: -93.653413
    },
    {
        lat: 41.528212, lng: -93.653472
    }
]

export const runwayZoneNine = [
    {
        lat: 41.535125, lng: -93.658742
    },
    {
        lat: 41.534835, lng: -93.658160
    },
    {
        lat: 41.535073, lng: -93.657911
    },
    {
        lat: 41.535184, lng: -93.657713
    },
    {
        lat: 41.538713, lng: -93.651314
    },
    {
        lat: 41.538919, lng: -93.651528
    },
    {
        lat: 41.535236, lng: -93.658397
    }
]

export const runwayZoneTen = [
    {
        lat: 41.537883, lng: -93.653432
    },
    {
        lat: 41.538020, lng: -93.653376
    },
    {
        lat: 41.538587, lng: -93.653370
    },
    {
        lat: 41.538666, lng: -93.653330
    },
    {
        lat: 41.539019, lng: -93.652695
    },
    {
        lat: 41.539040, lng: -93.652622
    },
    {
        lat: 41.538920, lng: -93.651538
    }
]

export const runwayZoneEleven = [
    {
        lat: 41.537338, lng: -93.659097
    },
    {
        lat: 41.537412, lng: -93.659911
    },
    {
        lat: 41.537048, lng: -93.659378
    },
    {
        lat: 41.537130, lng: -93.659268
    },
    {
        lat: 41.537141, lng: -93.659097
    },
    {
        lat: 41.537102, lng: -93.654005
    },
    {
        lat: 41.537359, lng: -93.653535
    }
]

export const runwayZoneTwelve = [
    {
        lat: 41.537106, lng: -93.654001
    },
    {
        lat: 41.537073, lng: -93.653931
    },
    {
        lat: 41.536850, lng: -93.653696
    },
    {
        lat: 41.536723, lng: -93.653654
    },
    {
        lat: 41.536574, lng: -93.653678
    },
    {
        lat: 41.536451, lng: -93.653791
    },
    {
        lat: 41.537014, lng: -93.652760
    },
    {
        lat: 41.536974, lng: -93.652947
    },
    {
        lat: 41.536998, lng: -93.653165
    },
    {
        lat: 41.537062, lng: -93.653306
    },
    {
        lat: 41.537250, lng: -93.653483
    },
    {
        lat: 41.537300, lng: -93.653517
    },
    {
        lat: 41.537360, lng: -93.653536
    }
]

export const runwayZoneThirteen = [
    {
        lat: 41.537764, lng: -93.651389
    },
    {
        lat: 41.537937, lng: -93.651237
    },
    {
        lat: 41.538106, lng: -93.651232
    },
    {
        lat: 41.538215, lng: -93.651296
    },
    {
        lat: 41.538378, lng: -93.651458
    },
    {
        lat: 41.538429, lng: -93.651557
    },
    {
        lat: 41.538444, lng: -93.651671
    },
    {
        lat: 41.538379, lng: -93.651912
    },
    {
        lat: 41.538702, lng: -93.651312
    },
    {
        lat: 41.538117, lng: -93.650749
    }
]

export const runwayZoneFourteen = [
    {
        lat: 41.537395, lng: -93.651123
    },
    {
        lat: 41.537782, lng: -93.650424
    },
    {
        lat: 41.537392, lng: -93.650045
    },
    {
        lat: 41.537341, lng: -93.649966
    },
    {
        lat: 41.537302, lng: -93.649746
    },
    {
        lat: 41.537304, lng: -93.649631
    },
    {
        lat: 41.536783, lng: -93.650591
    },
    {
        lat: 41.536914, lng: -93.650413
    },
    {
        lat: 41.537013, lng: -93.650355
    },
    {
        lat: 41.537110, lng: -93.650369
    },
    {
        lat: 41.537164, lng: -93.650404
    },
    {
        lat: 41.537372, lng: -93.650608
    },
    {
        lat: 41.537423, lng: -93.650742
    },
    {
        lat: 41.537441, lng: -93.650918
    }
]


export const runwayZoneFifteen = [
    {
        lat: 41.537302, lng: -93.649369
    },
    {
        lat: 41.536961, lng: -93.649402
    },
    {
        lat: 41.536929, lng: -93.649271
    },
    {
        lat: 41.536856, lng: -93.649173
    },
    {
        lat: 41.536766, lng: -93.649128
    },
    {
        lat: 41.536156, lng: -93.649034
    },
    {
        lat: 41.536160, lng: -93.648750
    },
    {
        lat: 41.537028, lng: -93.648758
    },
    {
        lat: 41.537062, lng: -93.648810
    },
    {
        lat: 41.537131, lng: -93.648876
    },
    {
        lat: 41.537249, lng: -93.649101
    }
]

export const runwayZoneSixteen = [ //runway 13 31
    {
        lat: 41.528818, lng: -93.650353
    },
    {
        lat: 41.529123, lng: -93.649980
    },
    {
        lat: 41.545767, lng: -93.674282
    },
    {
        lat: 41.545461, lng: -93.674653
    }
]