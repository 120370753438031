import { makeStyles } from '@material-ui/core/styles';
import Paper from '@mui/material/Paper';
import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import { transparentLightBlue, gold, darkPurple } from 'styles/colors';
import CircleIcon from '@mui/icons-material/Circle';

const useStyles = makeStyles({
    paper:{
        marginBottom: 15,
        padding: 10,
        display: "flex",
        overflow: "auto"
    },
    statusContainer:{
        display: "flex",
        alignItems: "center",
        padding: 10
    }
});

const SurfaceManagementHeader = (props) => {
    const classes = useStyles();
    const { aircraftDetails } = props;

    return (
        <Paper className={classes.paper}>
            <div className={classes.statusContainer}>
                <CircleIcon fontSize='medium' style={{ color: "red" }} />
                <Typography variant="p">
                    {aircraftDetails.timeEntered ? 
                        `${aircraftDetails?.aircraftIdentification} Aircraft detected on runway at ${aircraftDetails.timeEntered}` :
                        "Waiting for aircraft to enter runway..."
                    }
                </Typography>
            </div>
        </Paper>
    );
}

export default SurfaceManagementHeader;