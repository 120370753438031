import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import parameters from 'api/parameters';
import weatherConditions from "constants/weatherConditions.json";
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { convertToLocal } from 'utils/timeConversions';

const useStyles = makeStyles({
  media: {
    height: 160,
    width: "100%"
  },
  text:{
    lineHeight: 1.6,
    fontSize: 12
  },
  loadingContainer:{
    justifyContent: "center",
    display: "flex",
    margin: 20,
    alignItems: "center"
  }
});

const WeatherCard = (props) => {
  const classes = useStyles();
  const [weather, setWeather] = useState(null)
  const { timestamp, airport } = props;

  useEffect(() => {
    let mounted = true;
    
    const getWeatherReport = async() => {
      const weatherReport = await parameters.get("/getWeatherReport", { params: {
        timestamp: timestamp,
        airport: airport.code
      }});
      if (!mounted) return
      setWeather(weatherReport.data.data)
    }

    getWeatherReport()

    return () => mounted = false
  }, [timestamp, airport])

  const getSkyConditionUrl = (skyCondition) => {
    const timeOfDay = weather.isDay ? " day" : " night";
    if(skyCondition === "Overcast" || skyCondition === "Partly cloudy"){
      return weatherConditions[skyCondition + timeOfDay]
    }
    else if(typeof weatherConditions[skyCondition] === "undefined"){
      return "overcast_day.png"
    }
    return weatherConditions[skyCondition]
  }

  if(!weather){
    return <div className={classes.loadingContainer}> <CircularProgress/> </div>
  }else if(Object.keys(weather).length === 0){
    return(
      <Card className={classes.card}>
        <CardContent>
          <Typography className={classes.text} variant="body2">
            No Weather Info Available
          </Typography>
        </CardContent>
      </Card>
    )
  }else if(weather){
    return (
      <Card className={classes.card}>
        <CardMedia
          className = {classes.media}
          image = {require("../../assets/img/weather/" + getSkyConditionUrl(weather.skyCondition))}
          title = {weather.skyCondition}
        />
        <CardContent>
          <Typography gutterBottom variant="h6">
            {weather.skyCondition}
          </Typography>
          <Typography className={classes.text} variant="body2">
            {convertToLocal(weather.timestamp)}
          </Typography>
          <Typography className={classes.text} variant="body2">
            Wind: {weather.windMph}mph 
          </Typography>
          <Typography className={classes.text} variant="body2">
            Wind Direction: {weather.windDirection}
          </Typography>
          <Typography className={classes.text} variant="body2">
            Precipitation: {weather.precipitation}in
          </Typography>
          <Typography className={classes.text} variant="body2">
            Humidity: {weather.humidity}%
          </Typography>
          <Typography className={classes.text} variant="body2">
            Cloud: {weather.cloud}%
          </Typography>
          <Typography className={classes.text} variant="body2">
            Wind Gust: {weather.gustMph}mph
          </Typography>
          <Typography className={classes.text} variant="body2">
            Pressure: {weather.pressure}inHg 
          </Typography>
        </CardContent>
      </Card>
    );
  }else{
    return <></>
  }
}

const mapStateToProps = (state) =>{
  return{
    airport: state.airportReducer.airport
  }
}

export default connect(mapStateToProps, null)(WeatherCard)