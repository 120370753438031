import { makeStyles } from '@material-ui/core/styles';
import Paper from '@mui/material/Paper';
import React from 'react';
import Typography from '@mui/material/Typography';
import { gold, darkPurple } from 'styles/colors';
import CircleIcon from '@mui/icons-material/Circle';

const useStyles = makeStyles({
    paper:{
        marginBottom: 10,
        padding: 10,
        display: "flex",
        overflow: "auto"
    },
    statusContainer:{
        display: "flex",
        alignItems: "center",
        marginRight: 10
    }
});

const GateManagementLegend = () => {
    const classes = useStyles();

    return (
        <Paper className={classes.paper}>
            <div className={classes.statusContainer}>
                <CircleIcon fontSize='medium' style={{ color: "green" }}/>
                <Typography variant="p">Arrived</Typography>
            </div>
            <div className={classes.statusContainer}>
                <CircleIcon fontSize='medium' style={{ color: "orange" }} />
                <Typography variant="p">Departed</Typography>
            </div>
            <div className={classes.statusContainer}>
                <CircleIcon fontSize='medium' style={{ color: "#88c4fa" }}/>
                <Typography variant="p">In Air</Typography>
            </div>
            <div className={classes.statusContainer}>
                <CircleIcon fontSize='medium' style={{ color: gold }} />
                <Typography variant="p">Scheduled</Typography>
            </div>
            <div className={classes.statusContainer}>
                <CircleIcon fontSize='medium' style={{ color: "#bf0000" }} />
                <Typography variant="p">Delayed</Typography>
            </div>
            <div className={classes.statusContainer}>
                <CircleIcon fontSize='medium' style={{ color: darkPurple }} />
                <Typography variant="p">Cancelled</Typography>
            </div>
        </Paper>
    );
}

export default GateManagementLegend;